import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import { getAllParentList } from '../../../Services/AdminApis';
 
const columns = [
    { field: 'id', headerName: 'No.', width: 70 },
    { field: 'fatherName', headerName: 'Father Name', width: 200 },
    { field: 'fatherEmail', headerName: 'Father Email', width: 200 },
    { field: 'fatherMobile', headerName: 'Father Mobile', width: 150 },
    { field: 'motherName', headerName: 'Mother Name', width: 200 },
    { field: 'motherEmail', headerName: 'Mother Email', width: 200 },
    { field: 'motherMobile', headerName: 'Mother Mobile', width: 150 },
    { field: 'guardianName', headerName: 'Guardian Name', width: 200 },
    { field: 'guardianRelation', headerName: 'Relation', width: 150 },
    { field: 'guardianMobile', headerName: 'Guardian Mobile', width: 150 },
    { field: 'guardianAddress', headerName: 'Guardian Address', width: 300 },
    {
        field: 'students',
        headerName: 'Students',
        width: 300,
        renderCell: (params) =>
            params.value.map(
                (student, index) =>
                    `${index + 1}. ${student.name.firstName} ${student.name.lastName} (Class ${student.class.className} - ${student.class.classSection})`
            ).join('\n'),
    },
    {
        field: 'image',
        headerName: 'Father Avatar',
        width: 100,
        renderCell: (params) => <Avatar src={params.value} alt="avatar" />,
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 100,
        renderCell: () => (
            <IconButton color="primary">
                <EditIcon />
            </IconButton>
        ),
    },
];

export default function OnboardedParentList() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data from the API
    useEffect(() => {
        const fetchParents = async () => {
            try {
                const response = await getAllParentList();
                const parentData = response.data.parent;
                const formattedRows = parentData.map((parent, index) => ({
                    id: index + 1,
                    fatherName: `${parent.father.firstName} ${parent.father.lastName}`,
                    fatherEmail: parent.father.email,
                    fatherMobile: parent.father.mobile,
                    motherName: `${parent.mother.firstName} ${parent.mother.lastName}`,
                    motherEmail: parent.mother.email,
                    motherMobile: parent.mother.mobile,
                    guardianName: `${parent.guardian.firstName} ${parent.guardian.lastName}`,
                    guardianRelation: parent.guardian.relations,
                    guardianMobile: parent.guardian.mobile,
                    guardianAddress: parent.guardian.address,
                    students: parent.students.map((student) => ({
                        name: student.name,
                        class: student.class,
                    })),
                    image: parent.father.avatar,
                }));

                setRows(formattedRows);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching parents:', error);
                setLoading(false);
            }
        };

        fetchParents();
    }, []);

    return (
        <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
            <Headingtitle title="Guardians/Parents" />
            <DataGrid
                style={{ backgroundColor: 'white' }}
                rows={rows}
                columns={columns}
                pageSize={10}
                slots={{ toolbar: GridToolbar }}
                checkboxSelection
                loading={loading}
            />
        </div>
    );
}
