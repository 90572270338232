import React, { useState, useEffect, useMemo } from "react";
import Headingtitle from "../../Common/Headingtitle";
import "./scrollbar.css";
import assignmentsubmit from "../../../Assests/Logos/assig_submit.png";
import { getAllSubjectList, mySubmittedAssignment } from "../../../Services/StudentApis";
import { useTransition } from "react";

const StudentSubmitAssignment = () => {
    const [isPending, startTransaction] = useTransition();
    const [selectedSubject, setSelectedSubject] = useState("All");
    const [subjectArray, setSubjectArray] = useState([]);
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        startTransaction(() => {
            // Fetch subjects
            getAllSubjectList()
                .then((response) => {
                    setSubjectArray(response?.data?.subjects || []);
                })
                .catch((error) => console.error("Error fetching subjects:", error));

            // Fetch assignments
            mySubmittedAssignment()
                .then((response) => {
                    setAssignments(response?.data?.assignments || []);
                })
                .catch((error) => console.error("Error fetching assignments:", error));
        });
    }, []);

    // Filter assignments based on selected subject
    const filteredAssignments = useMemo(() => {
        return selectedSubject === "All"
            ? assignments
            : assignments.filter((assignment) => assignment.subject === selectedSubject);
    }, [selectedSubject, assignments]);

    return (
        <section>
            <Headingtitle title="Assignment" />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
                <section className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins p-3">
                    {/* Subject Filter Buttons */}
                    <div className="w-[90%] mx-auto h-[60px] rounded-lg flex items-center justify-evenly px-10 py-3">
                        <button
                            onClick={() => setSelectedSubject("All")}
                            className={`px-4 py-2  text-[1rem] font-sans leading-[20px] rounded-lg  
                                ${selectedSubject === "All" ? "bg-blue-900 text-white shadow-lg" : "text-black bg-border-100"}
                            `}
                        >
                            All Subjects
                        </button>
                        {subjectArray?.length > 0 ? (
                            subjectArray.map((sub) => (
                                <button
                                    key={sub._id}
                                    className={`px-4 py-2  text-[1rem] font-sans leading-[20px] rounded-lg 
                                        ${selectedSubject === sub.subjectName ? "bg-blue-900 text-white shadow-lg" : "text-black bg-border-100"}
                                    `}
                                    onClick={() => setSelectedSubject(sub.subjectName)}
                                >
                                    {sub.subjectName}
                                </button>
                            ))
                        ) : (
                            <p>No subjects available</p>
                        )}
                    </div>

                    {/* Assignments Display */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 w-full sm:w-[90%] mx-auto custom-scroll overflow-y-scroll max-h-[70vh]">
                        {filteredAssignments.length > 0 ? (
                            filteredAssignments.map((assignment) => (
                                <div
                                    key={assignment._id}
                                    style={{ boxShadow: "2px 16px 19px 0px #00000017" }}
                                    className="col-span-1 flex border items-center rounded-lg p-4 h-auto shadow-md bg-white"
                                >
                                    <img src={assignmentsubmit} className="w-[50px] h-[50px] mr-4" alt="Assignment" />
                                    <div className="flex flex-col h-full space-y-1 w-full">
                                        {/* Header Section */}
                                        <div className="w-full flex justify-between">
                                            <div>
                                                <p className="font-semibold font-poppins">{assignment.title}</p>
                                                <p className="leading-[17px] text-[#000000]" style={{ fontWeight: "400" }}>
                                                    {assignment.instructions}
                                                </p>
                                                <p className="leading-[17px] font-semibold text-[#000000]">{assignment.subject}</p>
                                            </div>

                                            {/* Submission Status */}
                                            <p
                                                className={`text-white text-[0.75rem] rounded-xl px-2 h-fit ${assignment.submission?.status === "Submitted" ? "bg-green-500" : "bg-red-500"
                                                    }`}
                                            >
                                                {assignment.submission?.status || "Pending"}
                                            </p>
                                        </div>

                                        {/* Due Date and Time */}
                                        <p className="text-left  text-[0.77rem] ">
                                            Due Date:
                                            <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">

                                                {new Date(assignment.dueDate).toLocaleDateString()} at {assignment.dueTime}
                                            </span>

                                        </p>

                                        {/* Submission Details */}
                                        {assignment.submission && (
                                            <div className="text-left font-normal text-[14px] leading-[17.5px] space-y-1">
                                                <p className="text-left  text-[0.77rem]  text-gray-500">

                                                    Submitted Date:
                                                    <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">

                                                        {new Date(assignment.submission.submittedDate).toLocaleString()}
                                                    </span>
                                                </p>

                                                <div className="flex justify-between w-full">

                                                    <p className="text-left w-[60%]   text-[0.77rem]  text-gray-500">

                                                        Remarks:
                                                        <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">

                                                            {assignment.submission.remarks}
                                                        </span>
                                                    </p>
                                                    {assignment.submission.submittedFiles?.length > 0 && (
                                                        <a
                                                            href={assignment.submission.submittedFiles[0]}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-500 text-[0.77rem] underline"
                                                        >
                                                            View Submitted File
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))

                        ) : (
                            <p className="text-gray-500 text-[1.5rem]  w-full text-center">No assignments available for this subject.</p>
                        )}
                    </div>
                </section>
            </div>
        </section>
    );
};

export default StudentSubmitAssignment;
