import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoginLogo from "../Assests/Logos/world.png";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import { login } from '../Redux/slices/authSlice';
import { getAllClassList } from "../Services/TeachersApis";
import loginArt from "../Assests/AcademicPic/LoginArt.png"
import { getAcademicsYears } from "../Services/TeachersApis";


const Login = () => {
    const dispatch = useDispatch();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState("Student");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { loading, isAuthenticated, user } = useSelector((state) => state.auth);

    React.useEffect(() => {
        if (isAuthenticated && user) {
            if (user.role === 'Student') navigate('/student/dashboard');
            else if (user.role === 'Teacher') navigate('/teacher/dashboard');
            else if (user.role === 'Parent') navigate('/parent/dashboard');
        }
    }, [isAuthenticated, user, navigate]);

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Invalid email format")
            .required("Email is required"),
        password: yup
            .string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
        studentId: yup
            .string()
            .when("userRole", {
                is: "Student",
                then: (schema) => schema.required("Student ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
        teacherId: yup
            .string()
            .when("userRole", {
                is: "Teacher",
                then: (schema) => schema.required("Teacher ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
        parentId: yup
            .string()
            .when("userRole", {
                is: "Parent",
                then: (schema) => schema.required("Parent ID is required"),
                otherwise: (schema) => schema.notRequired()
            }),
    });


    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const fetchAcademicYear = async () => {
        try {
            const response = await getAcademicsYears();
            localStorage.setItem("academicYear", JSON.stringify(response?.data) || "");
        } catch (error) {
            throw error
        }
    }
    useEffect(() => {
        const savedRole = localStorage.getItem("userRole");
        if (savedRole) {
            setUserRole(savedRole);
        }
    }, []);

    const fetchClassId = async (userRole) => {
        try {
            if (userRole === "Teacher") {
                const response = await getAllClassList();

                // Check if response is valid and contains the data
                if (response?.data?.classesDetails) {
                    localStorage.setItem(
                        "classId",
                        JSON.stringify(response?.data?.classesDetails._id)
                    );
                    localStorage.setItem("schoolId", JSON.stringify(response?.data?.classesDetails?.school));
                    console.log("Class details saved to localStorage.");
                } else {
                    console.warn("No class details found in the response.");
                }
            }
        } catch (error) {
            console.error("Error fetching class list:", error);
        }
    };

    const onSubmit = async (data) => {
        console.log("Submitting form data:", data);

        try {
            // Dispatch the login action and wait for completion
            await dispatch(login({ userRole, data }));

            // After successful login, fetch class details if applicable
            await fetchClassId(userRole);
            await fetchAcademicYear();

            console.log("Fetch Class ID process completed.");
        } catch (error) {
            console.error("Error during login or fetching class ID:", error);
        }
    };




    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };

    const handleRoleSelection = (role) => {
        setUserRole(role);
        localStorage.setItem("userRole", role);
    };

    return (
        <section className=" w-[100vw] h-[100vh] flex justify-evenly items-center">
            <div className="w-[40vw]  mx-auto h-[90vh]  px-10 py-5 text-start text-[0.81rem] ">
                <div className=" w-[85%] ">
                    <h1 className=" text-[2.2rem] font-semibold ">Welcome Back</h1>
                    <p className="text-[#313957]  font-semibold text-[1.3rem] leading-[2.5rem]">
                        Today is a new day. It's your day. You shape it.
                        Sign in to start managing your projects.
                    </p>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col max-w-[100%] mx-auto p-2 mt-5 rounded-md"
                >
                    <div className="mb-4">
                        <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="email">
                            Email
                        </label>
                        <input
                            id="email"
                            type="email"
                            placeholder="Email Or Mobile No."
                            className={`w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border ${errors.email ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                            {...register("email")}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="password">
                            Password
                        </label>
                        <div className="relative bg-green-400 rounded-md">
                            <input
                                id="password"
                                type={isPasswordVisible ? "text" : "password"}
                                placeholder="Password"
                                autoComplete="current-password"
                                className={`w-full p-2 placeholder:text-[0.8rem]  placeholder:font-semibold border ${errors.password ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                {...register("password")}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className="absolute top-1/2 bg-border-100 rounded-r-md h-full
                                 right-0 transform -translate-y-1/2  cursor-pointer p-2"
                            >
                                {isPasswordVisible ? (
                                    <svg className="h-full w-6" /* (SVG for eye icon) */ />
                                ) : (
                                    <svg className="h-full w-6" /* (SVG for eye-off icon) */ />
                                )}
                            </span>
                        </div>
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
                    </div>

                    {userRole === "Student" &&
                        <div className="mb-4">
                            <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="studentId">
                                Student ID
                            </label>
                            <input
                                id="studentId"
                                type="text"
                                placeholder="Student ID"
                                className={`w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border ${errors.studentId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                {...register("studentId")}
                            />
                            {errors.studentId && <p className="text-red-500 text-sm mt-1">{errors.studentId.message}</p>}
                        </div>
                    }


                    {
                        userRole === "Teacher" &&
                        <div className="mb-4">
                            <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="teacherId">
                                Teacher ID
                            </label>
                            <input
                                id="teacherId"
                                type="text"
                                placeholder="Teacher ID"
                                className={`w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border ${errors.teacherId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                {...register("teacherId")}
                            />
                            {errors.teacherId && <p className="text-red-500 text-sm mt-1">{errors.teacherId.message}</p>}
                        </div>
                    }

                    {
                        userRole === "Parent" &&
                        <div className="mb-4">
                            <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="parentId">
                                Parent ID
                            </label>
                            <input
                                id="parentId"
                                type="text"
                                placeholder="Parent ID"
                                className={`w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border ${errors.parentId ? "border-red-500" : "border-border-50"} rounded-md focus:outline-none`}
                                {...register("parentId")}
                            />
                            {errors.parentId && <p className="text-red-500 text-sm mt-1">{errors.parentId.message}</p>}
                        </div>
                    }
                    <div className="w-full text-end">
                        <p className="text-text_blue-50 font-semibold">Forget password?</p>
                    </div>

                    <button
                        type="submit"
                        className="bg-[#22577A] font-semibold text-[0.9rem] text-[#FFFFFF] p-2 rounded-md mt-2 hover:bg-[#22577A] transition"
                        disabled={isLoading}
                    >
                        {isLoading ? "Logging in..." : `Login as ${userRole}`}
                    </button>

                    {error && <p className="text-red-500 mt-2">{error}</p>}

                    <hr className="h-[3px] bg-border-50 border-0 my-3" />
                    <p className="text-center text-[#00000080] font-semibold my-3">Login as</p>
                    <div className="grid grid-cols-2 gap-5 mt-5">
                        {["Teacher", "Parent", "Student"].map((role) => (
                            userRole !== role && (
                                <div key={role} className={`bg-[${role === "Teacher" ? "#1BCFB4" : role === "Parent" ? "#198AE2" : "#8089C7"}] bg-[#1BCFB4] py-2`}>
                                    <button
                                        type="button"
                                        onClick={() => handleRoleSelection(role)}
                                        className="text-center text-[#FFFFFF] font-semibold w-full"
                                    >
                                        {role}
                                    </button>
                                </div>
                            )
                        ))}
                    </div>
                </form>
            </div>
            <div className="w-[50vw] flex justify-center ">
                <img src={loginArt} className="w-[80%] h-[90vh]" />
            </div>
        </section>
    );
};

export default Login;
