import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";

export default function SchoolList({ schoolData }) {
    // Define columns for the DataGrid
    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "schoolName", headerName: "School Name", width: 200 },
        { field: "schoolCode", headerName: "School Code", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "mobile", headerName: "Mobile", width: 150 },
        { field: "alternateMobile", headerName: "Alternate Mobile", width: 180 },
        { field: "address", headerName: "Address", width: 300 },
        { field: "numberOfStudents", headerName: "Students", width: 120 },
        { field: "numberOfTeachers", headerName: "Teachers", width: 120 },
        { field: "numberOfClasses", headerName: "Classes", width: 120 },
        {
            field: "active",
            headerName: "Active",
            width: 100,
            renderCell: (params) => (params.value ? "Yes" : "No"),
        },
        {
            field: "registrationDate",
            headerName: "Registration Date",
            width: 180,
            renderCell: (params) =>
                new Date(params.value).toLocaleDateString("en-US"),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(params.row)}>
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    // Prepare rows from schoolData
    const rows = schoolData.map((school, index) => ({
        id: school._id,
        schoolName: school.schoolName,
        schoolCode: school.schoolCode,
        email: school.email,
        mobile: school.mobile,
        alternateMobile: school.alternateMobile,
        address: school.address,
        numberOfStudents: school.schoolDetails.numberOfStudents,
        numberOfTeachers: school.schoolDetails.numberOfTeachers,
        numberOfClasses: school.schoolDetails.numberOfClasses,
        active: school.active,
        registrationDate: school.registrationDate,
    }));

    // Edit button handler
    const handleEdit = (row) => {
        console.log("Edit school:", row);
        // Add your edit logic here
    };

    // Delete button handler
    const handleDelete = (id) => {
        console.log("Delete school ID:", id);
        // Add your delete logic here
    };

    return (
        <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                components={{ Toolbar: GridToolbar }}
                sx={{
                    boxShadow: 3,
                    border: 1,
                    borderColor: "#ccc",
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#197231",
                        color: "#000",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                    },
                    "& .MuiDataGrid-columnHeaders:hover": {
                        backgroundColor: "#1565c0",
                    },
                    "& .MuiDataGrid-row": {
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "0.9rem",
                        color: "#333",
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                        backgroundColor: "#f9f9f9",
                    },
                    "& .MuiDataGrid-row:nth-of-type(even)": {
                        backgroundColor: "#fff",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "#f5f5f5",
                        borderTop: "1px solid #ccc",
                    },
                }}
            />
        </div>
    );
}
