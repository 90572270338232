import React from "react";
import Headingtitle from "./Headingtitle";
import { AiOutlineCheck } from "react-icons/ai"; // Import icons
import { FaCheckDouble } from "react-icons/fa6";
const NotificationDetailComp = ({ notificationData }) => {
  return (
    <div>
      <div className="font-poppins">
        <Headingtitle title="Push Notification Detail" />
        <main className="bg-white relative w-full rounded-md p-5 min-h-[80vh]">
          <div className="font-poppins flex justify-center items-center bg-gray-50">
            <div className="bg-white w-full rounded-lg shadow-lg p-6 border">
              <div className="mb-4">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">
                  {notificationData.title}
                </h1>
                <p className="text-sm text-gray-500">
                  {new Date(notificationData.createdAt).toLocaleString()}
                </p>
              </div>
              <div className="mb-4">
                <p className="text-lg text-gray-700">{notificationData.messageBody}</p>
              </div>
              {notificationData.icon && (
                <div className="mb-4">
                  <img
                    src={notificationData.icon}
                    alt="Notification Icon"
                    className="w-16 h-16 object-cover rounded"
                  />
                </div>
              )}
              <div className="flex items-center space-x-2">
                <p className="text-sm text-black">Status:</p>
                {notificationData.status ? (
                  <div className="flex items-center text-green-500">
                    <FaCheckDouble className="w-4 h-4 " /> {/* Double tick for "Read" */}
                    <span className="ml-1">Read</span>
                  </div>
                ) : (
                  <div className="flex items-center text-gray-400">
                    <AiOutlineCheck className="w-4 h-4" /> {/* Single tick for "Unread" */}
                    <span className="ml-1">Unread</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotificationDetailComp;
