import React, { useEffect, useState } from 'react';
import { myAllStudentList } from '../../Services/ParentApi';
import { MenuItem, FormControl, Select } from '@mui/material';
import { useSelector } from 'react-redux';

const MyclassIdComp = ({ onStudentSelect }) => {
  const [students, setStudents] = useState([]); // To store all student data
  const [selectedStudent, setSelectedStudent] = useState(''); // Selected student's ID
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth); // Fetch token from Redux

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await myAllStudentList(token); // Fetch student list
        if (response?.status === 200) {
          setStudents(response.data.students); // Set the students array
        }
      } catch (err) {
        setError('Failed to load student list.');
      }
    };

    fetchStudents();
  }, [token]);

  const handleChange = (event) => {
    const selectedId = event.target.value; // Get the selected student ID
    setSelectedStudent(selectedId);

    // Find the selected student details
    const selectedStudentDetails = students.find(
      (student) => student._id === selectedId
    );

    if (onStudentSelect && selectedStudentDetails) {
      // Pass classId, className, and studentId to parent
      onStudentSelect(
        {classId: selectedStudentDetails.class?._id || '',} // Class ID
    );
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <FormControl fullWidth>
      <Select
        labelId="student-select-label"
        value={selectedStudent}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Choose a classId
        </MenuItem>
        {students.map((student) => (
          <MenuItem key={student._id} value={student._id}>
            {student.class?.className} {student.class?.classSection} - {student.name?.firstName} {student.name?.lastName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MyclassIdComp;
