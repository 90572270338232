import React, { useState, useEffect, useMemo } from "react";
import Headingtitle from "../../Common/Headingtitle";
import "./scrollbar.css";
import assignmentlogo from "../../../Assests/Logos/assignmentlogo.png";
import { getAllSubjectList, myDueAssignment, submitDueAssignment } from "../../../Services/StudentApis";
import { useTransition } from "react";
import { Form } from "react-router-dom";
import toast from "react-hot-toast";


const StudentAllAssignment = () => {
    const [isPending, startTransaction] = useTransition();
    const [selectedSubject, setSelectedSubject] = useState("All");
    const [subjectArray, setSubjectArray] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(null);

    const [modelFormData, setModelFormData] = useState({
        remarks: "",
        image: ""
    });

    const fetchAllAssignment = () => {
        startTransaction(() => {
            // Fetch subjects
            getAllSubjectList()
                .then((response) => {
                    setSubjectArray(response?.data?.subjects || []);
                })
                .catch((error) => console.error("Error fetching subjects:", error));

            // Fetch assignments
            myDueAssignment()
                .then((response) => {
                    setAssignments(response?.data?.assignments || []);
                })
                .catch((error) => console.error("Error fetching assignments:", error));
        });
    }
    useEffect(() => {

        fetchAllAssignment();
    }, []);

    // Filter assignments based on selected subject
    const filteredAssignments = useMemo(() => {
        return selectedSubject === "All"
            ? assignments
            : assignments.filter((assignment) => assignment.subject === selectedSubject);
    }, [selectedSubject, assignments]);



    const openModel = (data) => {
        setIsOpenModal(data);

    }
    const handleClose = () => {
        setIsOpenModal(null);
    }

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModelFormData({ ...modelFormData, [name]: value });
    };

    // Handle image upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setModelFormData({ ...modelFormData, image: file });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("remarks", modelFormData.remarks);
        formData.append("file", modelFormData.image);

        try {
            const response = await submitDueAssignment(isOpenModal._id, formData)
            if (response.status === 200) {
                toast.success("Successfully Submitted your Assignment")
                fetchAllAssignment();
            }
        } catch (error) {
            toast.success(error?.response?.data?.message)

        }
        console.log("Form Data Submitted:", modelFormData);
        // Add your form submission logic here (e.g., API call)
        setIsOpenModal(null); // Close modal after submission
    };
    return (
        <section>
            <Headingtitle title="Assignment" />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
                <section className="min-w-full bg-white rounded-lg min-h-[80vh] font-poppins p-3">
                    {/* Subject Filter Buttons */}
                    <div className="w-[90%] mx-auto h-[60px] rounded-lg flex items-center justify-evenly px-10 py-3">
                        <button
                            onClick={() => setSelectedSubject("All")}
                            className={`px-4 py-2  text-[1rem] font-sans leading-[20px] rounded-lg  
                                ${selectedSubject === "All" ? "bg-blue-900 text-white shadow-lg" : "text-black bg-border-100"}
                            `}
                        >
                            All Subjects
                        </button>
                        {subjectArray?.length > 0 ? (
                            subjectArray.map((sub) => (
                                <button
                                    key={sub._id}
                                    className={`px-4 py-2  text-[1rem] font-sans leading-[20px] rounded-lg 
                                        ${selectedSubject === sub.subjectName ? "bg-blue-900 text-white shadow-lg" : "text-black bg-border-100"}
                                    `}
                                    onClick={() => setSelectedSubject(sub.subjectName)}
                                >
                                    {sub.subjectName}
                                </button>
                            ))
                        ) : (
                            <p>No subjects available</p>
                        )}
                    </div>

                    {/* Assignments Display */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-5 w-full sm:w-[90%] mx-auto custom-scroll overflow-y-scroll max-h-[70vh]">
                        {filteredAssignments.length > 0 ? (
                            filteredAssignments.map((assignment) => (
                                <div
                                    key={assignment._id}
                                    className="col-span-1 flex border items-center rounded-lg px-4 py-2 min-h-[150px] h-auto shadow-md bg-white"
                                >
                                    <img
                                        src={assignmentlogo}
                                        className="w-[50px] h-[50px] mr-4"
                                        alt="Assignment"
                                    />
                                    <div className="flex flex-col h-full space-y-1 w-full">
                                        {/* Title and Subject */}
                                        <div className="w-full flex justify-between">
                                            <div>
                                                <p className="font-semibold font-poppins">{assignment.title}</p>
                                                <p className="leading-[17px] text-[#000000]">{assignment.subject}</p>
                                            </div>
                                            <p className="text-gray-500 text-sm">
                                                {new Date(assignment.dueDate).toLocaleDateString()} {/* Format date */}
                                            </p>
                                        </div>

                                        {/* Instructions */}
                                        <p className="text-left  text-[0.77rem]  text-gray-500">
                                            Instructions:
                                            <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">
                                                {assignment.instructions}
                                            </span>
                                        </p>

                                        {/* Due Date and Time */}
                                        <p className="text-left  text-[0.77rem]  text-gray-500">
                                            Due Time:
                                            <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">
                                                {assignment.dueTime}
                                            </span>
                                        </p>

                                      

                                        {/* Resubmission Allowed */}
                                        <div className="flex flex-row justify-between">
                                            <p className="text-left  text-[0.77rem]  text-gray-500">
                                                Resubmission:
                                                <span className=" leading-[17px] text-[#000000] font-semibold font-poppins">
                                                    {assignment.resubmissionAllowed ? "Yes" : "No"}
                                                </span>
                                            </p>

                                            {/* Attachment */}
                                            {assignment.attachment && assignment.attachment.length > 0 && (
                                                <a
                                                    href={assignment.attachment[0]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 underline text-[0.77rem]"
                                                >
                                                    View Attachment
                                                </a>
                                            )}
                                        </div>
                                        <button
                                            onClick={() => openModel(assignment)}
                                            className="bg-blue-500 rounded-md w-full py-2 text-[0.9rem] font-semibold text-white">Submit your Assigment </button>
                                    </div>
                                </div>

                            ))
                        ) : (
                            <p className="text-gray-500 text-[1.5rem]  w-full text-center">No assignments available for this subject.</p>
                        )}
                    </div>
                </section>
            </div>
            {isOpenModal !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-md">
                        <h2 className="text-xl font-semibold mb-4">Modal Form</h2>
                        <form onSubmit={handleSubmit}>
                            {/* Remarks Field */}
                            <div className="mb-4">
                                <label
                                    htmlFor="remarks"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Remarks
                                </label>
                                <textarea
                                    id="remarks"
                                    name="remarks"
                                    value={modelFormData.remarks}
                                    onChange={handleInputChange}
                                    className="w-full h-24 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                                    placeholder="Enter your remarks"
                                />
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="image"
                                    className="block text-gray-700 font-medium mb-2"
                                >
                                    Upload Image
                                </label>
                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={handleImageChange}
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                                />
                            </div>
                            <button
                                type="submit"
                                className={`bg-green-500 text-white px-4 py-2 rounded-md ${!modelFormData.remarks || !modelFormData.image ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'
                                    }`}
                                disabled={!modelFormData.remarks || !modelFormData.image}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => setIsOpenModal(null)}
                                className="bg-red-500 text-white px-4 py-2 rounded-md ml-2 hover:bg-red-600"
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </section>
    );
};

export default StudentAllAssignment;
