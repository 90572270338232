import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { AllClassCategoryList, myUpcomingHoliday } from "../../../Services/StudentApis";
import { useSearchParams } from "react-router-dom";

const StudentHolidayList = () => {
    const [holidayList, setHolidayList] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(""); // Tracks selected category
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true); // Initialize as true to show loader initially

    // Fetch all class categories
    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await AllClassCategoryList();
                setAllCategory(response?.data?.classCategories || []);
            } catch (error) {
                console.error("Error fetching class categories:", error);
            }
        };
        fetchCategory();
    }, []);

    // Fetch holidays based on selected category or other search params
    useEffect(() => {
        const fetchHolidayResponse = async () => {
            setLoading(true); // Set loading to true while fetching
            try {
                const response = await myUpcomingHoliday(searchParams);
                setHolidayList(response?.data?.event || []);
            } catch (error) {
                setHolidayList([]);
                console.error("Error fetching holidays:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };
        fetchHolidayResponse();
    }, [searchParams]);

    // Handle category selection
    const handleCategoryChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedCategory(selectedValue);
        setSearchParams({ studentClass: selectedValue });
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Holidays" />

            {/* Dropdown for Class Category */}

            {/* Holiday Table */}
            <main className="bg-white shadow-md rounded-md p-5">
                <p className="font-semibold pb-2 text-[1rem]">Holiday List</p>

                <div className="mb-4">
                    <select
                        className="border rounded-md px-3 py-2 text-gray-700"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        <option value="">All Classes</option>
                        {allCategory.map((category) => (
                            <option key={category._id} value={category.className}>
                                {category.className}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    {loading ? (
                        <p className="text-gray-500">Loading...</p>
                    ) : holidayList.length > 0 ? (
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                <tr>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Holiday Name</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Applicable Classes</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {holidayList.map((record, index) => (
                                    <tr
                                        key={record._id}
                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                            } hover:bg-indigo-100 transition-all`}
                                    >
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">
                                            {new Date(record.date).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.name}</td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">
                                            {record.applicableClasses.includes("All")
                                                ? "All Classes"
                                                : record.applicableClasses.join(", ")}
                                        </td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-gray-500 mt-6">No Holidays Found</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default StudentHolidayList;
