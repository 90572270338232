import React, { Fragment, useState, useEffect } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { baseUrl } from "../../../utils/baseUrl";
import { CreateSection } from "../../../Services/superAdminApis";

const AdminCreateSection = () => {
    const [search, setSearch] = useState("");
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch sections on mount
    useEffect(() => {
        const fetchSections = async () => {
            try {
                const response = await fetch(`${baseUrl}/section/all-list`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setSections(data.sections);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSections();
    }, []);

    // Filter sections based on search input
    const filteredSections = sections.filter((sectionItem) =>
        sectionItem.classSection.toLowerCase().includes(search.toLowerCase())
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // Add a new section
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const response = await CreateSection(data);
            if (response?.status === 201 || 200) {
                toast.success("Successfully added class section!");
                reset();
                // Fetch updated sections after adding
                const updatedResponse = await fetch(`${baseUrl}/section/all-list`);
                const updatedData = await updatedResponse.json();
                setSections(updatedData.sections);
            }
        } catch (error) {
            toast.error("Internal Server Error");
        }
    };

    // Delete section
    const handleDeleteClick = (id) => {
        setSections(sections.filter((sectionItem) => sectionItem.id !== id));
    };

    return (
        <Fragment>
            <div className="font-poppins">
                {/* Heading Section */}
                <Headingtitle title="Academics" />

                {/* Main Content Section */}
                <main className="px-6 py-2">
                    <section className="w-full flex gap-5">
                        {/* Section Category Form */}
                        <div className="w-1/2 bg-white text-text_blue-500 rounded shadow-md p-4 h-fit">
                            <p className="text-[1.1rem] font-semibold mb-4">Section Category</p>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                                {/* Section Name Input */}
                                <div className="h-[80px]">
                                    <TextField
                                        label="Section Name"
                                        variant="outlined"
                                        placeholder="Enter the Section name"
                                        fullWidth
                                        {...register("classSection", { required: "Section name is required" })}
                                        error={!!errors.classSection}
                                        helperText={errors.classSection ? errors.classSection.message : ""}
                                        sx={{
                                            height: "55px",
                                            "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                                borderRadius: "8px",
                                                "& fieldset": { borderColor: "#EBEDF2", borderWidth: "2px" },
                                                "&:hover fieldset": { borderColor: "#B0B3B8" },
                                                "&.Mui-focused fieldset": { borderColor: "#22577A", borderWidth: "2px" },
                                            },
                                            "& .MuiInputLabel-root": {
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                color: "#000000",
                                            },
                                        }}
                                    />
                                </div>
                                {/* Submit Button */}
                                <div className="flex justify-end">
                                    <button type="submit" className="text-white rounded-lg bg-[#1A2A5A] px-3 py-2">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* List of Sections */}
                        <div className="w-1/2 bg-white text-text_blue-500 rounded shadow-md p-4">
                            <div className="p-3">
                                <h2 className="text-[1.1rem] font-semibold mb-4">List of Sections</h2>

                                {/* Error Handling */}
                                {error && <p className="text-red-500">Error: {error}</p>}

                                {/* Loading Spinner */}
                                {loading ? (
                                    <div className="flex justify-center items-center h-[50vh]">
                                        <CircularProgress />
                                    </div>
                                ) : sections.length > 0 ? (
                                    <>
                                        {/* Search Filter */}
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            fullWidth
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            sx={{
                                                marginBottom: "1rem",
                                                "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                                            }}
                                        />

                                        {/* Table */}
                                        <TableContainer component={Paper} className="h-[50vh] overflow-y-scroll">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Section Category</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredSections.length > 0 ? (
                                                        filteredSections.map((sectionItem, index) => (
                                                            <TableRow key={sectionItem.id}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>Section - {sectionItem.classSection}</TableCell>
                                                                <TableCell>
                                                                    <IconButton color="secondary" onClick={() => handleDeleteClick(sectionItem.id)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">
                                                                No sections found.
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                ) : (
                                    <p className="text-gray-600">No sections available.</p>
                                )}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </Fragment>
    );
};

export default AdminCreateSection;
