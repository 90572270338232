import React, { useState } from 'react'
import { MyStudentAttendanceList ,MyStudentAttendanceSummary} from '../../../Services/ParentApi';
import AttendanceComponent from '../../Common/AttendanceComponent';
const MyStudentAttendance = () => {
    return (
        
        <AttendanceComponent
            title="My Student Attendance"
            fetchAttendanceData={MyStudentAttendanceSummary}
            showStudentSelector={true}
        />
    )
}

export default MyStudentAttendance
