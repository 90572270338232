import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const StudentAnalyticsBarChart = ({ data }) => {
    const svgRef = useRef();

    useEffect(() => {
        // Set up dimensions
        const container = svgRef.current.parentNode;
        const width = container.offsetWidth || 800;
        const height = container.offsetHeight || 400;
        const margin = { top: 20, right: 30, bottom: 40, left: 50 };

        // Clear existing SVG content
        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height);

        // Set up scales
        const xScale = d3.scaleBand()
            .domain(data.map(d => d.x)) // Array of categories
            .range([margin.left, width - margin.right])
            .padding(0.1); // Padding between bars

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.y)]) // Maximum value for Y
            .nice() // Adds some padding to the top of the domain
            .range([height - margin.bottom, margin.top]);

        // Compute the bar width
        const maxBarWidth = 30;
        const computedBarWidth = Math.min(xScale.bandwidth(), maxBarWidth);

        // Append bars
        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => xScale(d.x) + (xScale.bandwidth() - computedBarWidth) / 2) // Center bars if narrower than computed bandwidth
            .attr("y", d => yScale(d.y))
            .attr("width", computedBarWidth)
            .attr("height", d => height - margin.bottom - yScale(d.y))
            .attr("fill", "steelblue")
            .on("mouseover", (event, d) => {
                const [x, y] = d3.pointer(event);
                tooltip
                    .style("opacity", 1)
                    .style("left", `${x}px`)
                    .style("top", `${y}px`)
                    .html(`Year: ${d.x}<br>Students: ${d.y}`);
            })
            .on("mouseout", () => tooltip.style("opacity", 0));

        // Add X Axis
        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(d3.axisBottom(xScale))
            .selectAll("text")
            .style("text-anchor", "middle");

        // Add Y Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(d3.axisLeft(yScale));

        // Tooltip
        const tooltip = d3.select("body").append("div")
            .style("position", "absolute")
            .style("background", "lightgray")
            .style("padding", "4px")
            .style("border-radius", "4px")
            .style("pointer-events", "none")
            .style("opacity", 0);

        // Clean up tooltip on unmount
        return () => tooltip.remove();

    }, [data]);

    return <svg ref={svgRef} className="w-full h-full"></svg>;
};

export default StudentAnalyticsBarChart;
