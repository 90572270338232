import React, { useEffect, useState, useTransition } from 'react';
import { getAllUpcomingEvents } from '../../../Services/AdminApis';
import Headingtitle from '../../Common/Headingtitle';

const UpcomingEventsList = () => {
    const [eventList, setEventList] = useState([]);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        const fetchUpcomingEvent = async () => {
            try {
                const response = await getAllUpcomingEvents();
                startTransition(() => {
                    setEventList(response?.data?.event || []);
                });
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        fetchUpcomingEvent();
    }, []);

    return (
        <div className="font-poppins">
            <Headingtitle title="Events" />
            <main className="bg-white shadow-md rounded-md p-5  ">
                <p className="font-semibold pb-2 text-[1rem] "> Events List</p>
                <div>

                    {isPending ? (
                        <p>Loading...</p>
                    ) : (
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                            <thead className='bg-gradient-to-r from-indigo-500 to-purple-500 text-white'>
                                <tr className="">
                                    <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                        Title
                                    </th>
                                    <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                        Description
                                    </th>
                                    <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                        Target Audience
                                    </th>
                                    <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                        Target Classes
                                    </th>
                                    <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                                        Expiry Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventList.map((event, index) => (
                                    <tr
                                        key={event._id}
                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                            } hover:bg-indigo-100 transition-all`}   >
                                        <td className="border border-gray-300 px-4 py-2 text-gray-700 font-medium">
                                            {event.title}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                            {event.desc}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                            {event.targetAudience.join(", ")}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                            {event.targetClasses.join(", ")}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-gray-700">
                                            {new Date(event.expiryDate).toLocaleDateString()}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>
            </main>
        </div>
    );
};

export default UpcomingEventsList;
