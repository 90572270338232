import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import "./scrollbar.css";
import { useNavigate } from "react-router-dom";
import { myResult as fetchMyResult } from "../../../Services/StudentApis";
import ResultListComponent from "../../Common/ResultListComponent";

const StudentResultList = () => {
    const [isPending, startTransition] = React.useTransition();
    const [results, setResults] = useState([]); // Results array
    const navigate = useNavigate();

    useEffect(() => {
        startTransition(() => {
            fetchMyResult()
                .then((response) => {
                    setResults(response?.data?.results || []);
                })
                .catch((error) => {
                    console.error("Error Fetching Results:", error);
                });
        });
    }, []);

    // Function to handle storing the exam result in localStorage and navigation
    const handleExamClick = (result) => {
        localStorage.setItem("examResult", JSON.stringify(result));
        navigate(`/student/dashboard/detail/ExamResult/${result?._id}`);
    };

    return (
        <ResultListComponent isPending={isPending} results={results} handleExamClick={handleExamClick} />
    );
};

export default StudentResultList;
