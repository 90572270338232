import React, { useEffect, useState } from "react";
import Headingtitle from "./Headingtitle";
import { EventsList } from "../../Services/ParentApi";
import { useSearchParams } from 'react-router-dom';

import toast from 'react-hot-toast';
import MyStudentsClass from "../Core/ParentDashboard/MyStudentClass";


const EventAndNotice = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [events, setEvents] = useState([]);
    const className = searchParams.get('className') || "All";


    const updateQueryParams = (updatedParams) => {
        setSearchParams((prev) => {
            const newParams = { ...Object.fromEntries(prev.entries()), ...updatedParams };
            return new URLSearchParams(newParams);
        });
    };


    const fetchEventsData = async (className) => {
        try {
            if (!className) {
                return;
            }

            const response = await EventsList(className);
            if (response?.status === 200) {
                const summary = response.data?.event || [];
                setEvents(summary);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message || "Failed to fetch event data"
            );
        }
    };

    useEffect(() => {
        fetchEventsData(className);
    }, [className]);

    const handleStudentSelect = (className) => {
        updateQueryParams({ className: className });
    };

    return (
        <section>
            <Headingtitle title="Notice" />
            <div className="p-4">
                <MyStudentsClass onStudentSelect={handleStudentSelect} />
                {!className ? (
                    <p className="text-center text-gray-500">Please select a class to view Events and Notifications.</p>
                ) : (
                    <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[78vh]">
                        <section className="p-4 flex flex-col items-center custom-scroll overflow-y-scroll gap-4 bg-white text-[0.86rem] rounded-lg h-[79vh]">
                            {events.length > 0 ? (
                                events.map((item, index) => (
                                    <div
                                        key={index}
                                        className=" w-full max-w-[1090px]  h-auto flex flex-col space-y-2 font-poppins border-[1px] border-border-100 rounded-lg  p-6"
                                    >
                                        <p className="font-semibold">{item.title || "Event Title"}</p>
                                        <p className="text-[#979797] leading-[17px] w-[80%]">
                                            {item.desc ||
                                                "Event description goes here."}
                                        </p>

                                        {item.eventFee ?
                                            <p className="text-[#979797] leading-[17px] w-[80%]">
                                                {item.eventFee}
                                            </p>
                                            : ""
                                        }
                                        {item?.attachments ? (
                                            <p>
                                                <button
                                                    onClick={() => {
                                                        window.open(item.attachments, "_blank");
                                                    }}
                                                    style={{
                                                        padding: "8px 12px",
                                                        backgroundColor: "#007BFF",
                                                        color: "#fff",
                                                        border: "none",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    View Attachment
                                                </button>
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                ))
                            ) : (
                                <p className="text-center text-gray-500">
                                    No events available for the selected class.
                                </p>
                            )}
                        </section>
                    </div>
                )}
            </div>
        </section>
    );
};

export default EventAndNotice;
