export const formatDateOfBirth=(dateOfBirth)=> {
    const date = new Date(dateOfBirth);
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    const ordinal = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${ordinal(day)} ${month} ${year}`;
  }


  export const formatName = (userName) => {
    if (!userName || !userName.name) return 'Ms. Unknown';
  
    const { firstName, middleName, lastName } = userName.name;
  
    return `${firstName || ''} ${middleName || ''} ${lastName || ''}`.trim() || 'Ms. Unknown';
  };
  
  export const formatGuardianInfo = (guardian, field, fallback = "N/A") => guardian?.[field] || fallback;

  