import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getDueAssignment } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const DueAssignment = () => {
    const [allDueAssignment, setAllDueAssignment] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchDueAssignment = async () => {
            setLoading(true);
            try {
                const response = await getDueAssignment();
                setAllDueAssignment(response?.data?.assignments || []); // Ensure assignments array is fetched
            } catch (error) {
                console.error("Error fetching due assignments:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDueAssignment();
    }, []);

    // Map API data for DataGrid rows
    const rows = allDueAssignment.map((assignment) => ({
        id: assignment._id, // Unique ID for DataGrid
        title: assignment.title,
        subject: assignment.subject,
        dueDate: new Date(assignment.dueDate).toLocaleDateString(),
        dueTime: assignment.dueTime,
        instructions: assignment.instructions,
        className: assignment.className,
        section: assignment.section.join(", "),
    }));

    // Define DataGrid columns
    const columns = [
        { field: "title", headerName: "Assignment Title", flex: 1 },
        { field: "subject", headerName: "Subject", flex: 1 },
        { field: "dueDate", headerName: "Due Date", flex: 1 },
        { field: "dueTime", headerName: "Due Time", flex: 1 },
        { field: "instructions", headerName: "Instructions", flex: 2 },
        { field: "className", headerName: "Class", flex: 0.5 },
        { field: "section", headerName: "Section(s)", flex: 0.8 },
        {
            field: "edit", // Define the 'Edit' column
            headerName: "Actions", // Header name for the column
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <button
                        onClick={() => handleEdit(params.row.id)} // Handle edit button click
                        className="text-indigo-600 hover:text-indigo-900"
                    >
                        Edit
                    </button>
                );
            },
        },
    ];

    // Navigate to the Edit Assignment page
    const handleEdit = (assignmentId) => {
        navigate(`/teacher/dashboard/edit-assignment/${assignmentId}`); // Navigate to the edit route with assignmentId
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Due Assignment" />
            <main className="bg-white h-auto rounded-md p-5">
                <div style={{ height: 600, width: "100%" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        loading={loading}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        sx={{
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundImage: "linear-gradient(to right, #4f46e5, #8b5cf6)", // Corrected gradient implementation
                                color: "#000",
                                fontSize: "0.875rem",
                                fontWeight: 900,
                                textTransform: "uppercase",
                            },
                            "& .MuiDataGrid-cell": {
                                fontSize: "0.875rem",
                                color: "#374151",
                                fontFamily: "Poppins, sans-serif",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: "#f3f4f6",
                            },
                        }}
                    />
                </div>
            </main>
        </div>
    );
};

export default DueAssignment;
