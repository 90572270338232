import React, { Fragment, useState, useEffect } from 'react';
import Headingtitle from '../../Common/Headingtitle';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { baseUrl } from '../../../utils/baseUrl';
import { CreateNewSubject, deleteSubjectById } from '../../../Services/AdminApis';

const AdminCreateSubject = () => {
    const [search, setSearch] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editSubject, setEditSubject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)


    const fetchSubjectList = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const response = await fetch(`${baseUrl}/admin-school/all-subject-list`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            console.log("data", data)

            if (!response.ok) {
                throw new Error(data.message || "Something went wrong.")
            }
            setSubjects(data.subjects);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchSubjectList();
    }, []);



    // Filter subjects based on search input
    const filteredSubjects = subjects.filter((subject) =>
        subject.subjectName.toLowerCase().includes(search.toLowerCase())
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    // Add new subject
    const onSubmit = async (data) => {
        try {
            const response = await CreateNewSubject(data)
            toast.success("succesfully added Subject")
            fetchSubjectList()
            reset();
            setLoading(false);


            if (!response.ok) {
                throw new Error(response.message || "Something went wrong.");
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };



    // Edit handler
    const handleEditClick = (subject) => {
        setEditSubject(subject);
        setEditDialogOpen(true);
    };

    const handleEditSave = () => {
        setSubjects(subjects.map((s) => (s.id === editSubject.id ? editSubject : s)));
        setEditDialogOpen(false);
    };

    const handleDeleteClick = async (id) => {
        try {
            console.log("subject id",id)
            const response = await deleteSubjectById(id)
            if (response.status === 200) {
                toast.success("Subject deleted succesfully")
                fetchSubjectList();
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="font-poppins">
                {/* Heading section */}
                <Headingtitle title="Academics" />

                {/* Main content section */}
                <main className="px-6 py-2">
                    <section className="w-full flex gap-5">
                        {/* Subject Form */}
                        <div className="w-1/2 bg-white text-text_blue-500 rounded shadow-md p-4 h-fit">
                            <p className="text-[1.1rem] font-semibold mb-4">Add New Subject</p>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                                <div className="h-[80px]">
                                    <TextField
                                        label="Subject Name"
                                        variant="outlined"
                                        placeholder="Enter the subject name"
                                        fullWidth
                                        {...register('subjectName', { required: 'Subject name is required' })}
                                        error={!!errors.subjectName}
                                        helperText={errors.subjectName ? errors.subjectName.message : ''}
                                        sx={{
                                            height: "55px",
                                            "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderColor: "#EBEDF2",
                                                    borderWidth: "2px",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "#B0B3B8",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#22577A",
                                                    borderWidth: "2px",
                                                },
                                            },
                                            "& .MuiInputLabel-root": {
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                color: "#000000",
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <button type="submit" className="text-white rounded-lg bg-[#1A2A5A] px-3 py-2">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* List of Subjects */}
                        <div className="w-1/2 bg-white h-[80vh] text-text_blue-500 rounded shadow-md p-4">
                            <div className="p-3">
                                {subjects.length > 0 ? (
                                    <div className="p-4 font-poppins">
                                        <h2 className="text-[1.1rem] font-semibold mb-4">List of Subjects</h2>
                                        {/* Search Filter */}
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            fullWidth
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            sx={{
                                                marginBottom: '1rem',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />

                                        {/* Table */}
                                        <TableContainer component={Paper}
                                            className="max-h-[400px] overflow-y-scroll"
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Subject Name</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredSubjects.length > 0 ? (
                                                        filteredSubjects.map((subject, index) => (
                                                            <TableRow key={subject.id}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{subject.subjectName}</TableCell>
                                                                <TableCell>
                                                                    {/* <IconButton color="primary" onClick={() => handleEditClick(subject)}>
                                                                        <Edit />
                                                                    </IconButton> */}
                                                                    <IconButton color="secondary" onClick={() => handleDeleteClick(subject._id)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">
                                                                No subjects available.
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ) : (
                                    <p className="text-gray-600">No subjects available.</p>
                                )}
                            </div>
                        </div>
                    </section>
                </main>

                {/* Edit Dialog */}
                <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                    <DialogTitle>Edit Subject</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Subject Name"
                            variant="outlined"
                            fullWidth
                            value={editSubject?.name || ''}
                            onChange={(e) =>
                                setEditSubject((prev) => ({ ...prev, name: e.target.value }))
                            }
                            sx={{ marginTop: '1rem' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleEditSave} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
};

export default AdminCreateSubject;
