import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import Avtar from "../../../Assests/Logos/avtar.png";
import { myParentProfile } from "../../../Services/StudentApis";
import ParentProfile from "../../Common/ParentProfile";

const StudentParents = () => {
    const [parentsDetails, setParentsDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMyParentProfile = async () => {
            try {
                const response = await myParentProfile();
                const parentData = response?.data?.student?.parent || {};

                // Map parent details for display
                const formattedParents = [
                    {
                        relation: "Father",
                        name: `${parentData.father?.firstName || ""} ${parentData.father?.middleName || ""} ${parentData.father?.lastName || ""}`,
                        dob: new Date(parentData.father?.dateOfBirth).toLocaleDateString() || "N/A",
                        email: parentData.father?.email || "N/A",
                        occupation: parentData.father?.occupation || "N/A",
                        phone: parentData.father?.mobile || "N/A",
                        avatar: parentData.father?.avatar || Avtar,
                    },
                    {
                        relation: "Mother",
                        name: `${parentData.mother?.firstName || ""} ${parentData.mother?.middleName || ""} ${parentData.mother?.lastName || ""}`,
                        dob: new Date(parentData.mother?.dateOfBirth).toLocaleDateString() || "N/A",
                        email: parentData.mother?.email || "N/A",
                        occupation: parentData.mother?.occupation || "N/A",
                        phone: parentData.mother?.mobile || "N/A",
                        avatar: parentData.mother?.avatar || Avtar,
                    },
                    {
                        relation: parentData.guardian?.relations || "Guardian",
                        name: `${parentData.guardian?.firstName || ""} ${parentData.guardian?.middleName || ""} ${parentData.guardian?.lastName || ""}`,
                        dob: new Date(parentData.guardian?.dateOfBirth).toLocaleDateString() || "N/A",
                        email: parentData.guardian?.email || "N/A",
                        occupation: parentData.guardian?.occupation || "N/A",
                        phone: parentData.guardian?.mobile || "N/A",
                        avatar: parentData.guardian?.avatar || Avtar,
                    },
                ];

                console.log("formated Data", formattedParents);

                setParentsDetails(formattedParents);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch parent profiles.");
                setLoading(false);
            }
        };

        fetchMyParentProfile();
    }, []);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <section>
            <ParentProfile  parentsDetails={parentsDetails} />
        </section> 
    );
};

export default StudentParents;
