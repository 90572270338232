
import React from 'react'
import Headingtitle from './Headingtitle'

const ResultListComponent = ({isPending,results,handleExamClick}) => {
  return (
    <div>
        <section>
            <Headingtitle title="My Results" />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[78vh]">
                <section className="p-4 flex flex-col items-center custom-scroll overflow-y-scroll gap-4 bg-white text-[0.86rem] rounded-lg h-[79vh]">
                    {isPending ? (
                        <p>Loading...</p>
                    ) : results.length > 0 ? (
                        results.map((result) => (
                            <div
                                key={result._id}
                                onClick={() => handleExamClick(result)}
                                className="w-[90%] flex flex-col space-y-2 font-poppins border-[1px] border-border-100 rounded-lg h-[143px] p-6 cursor-pointer hover:shadow-lg transition-shadow"
                                role="button"
                                aria-label={`View details for exam ${result.examName}`}
                            >
                                <div className="flex w-full justify-between">
                                    <div className="w-[60%]">
                                        {/* Exam Name */}
                                        <p className=" ">
                                            Exam Name:{" "}
                                            <span className="font-semibold text-gray-900">
                                                {result.examName || "N/A"}
                                            </span>
                                        </p>
                                        {/* Student Name */}
                                        <p className="leading-[17px] w-[80%]">
                                            Student:{" "}
                                            <span className="font-semibold text-gray-900">
                                                {result.studentName || "N/A"}
                                            </span>
                                        </p>
                                    </div>
                                    {/* Class Name */}
                                    <p className="text-[0.75rem] text-gray-600">
                                        Class: {result.className || "N/A"}
                                    </p>
                                </div>
                                <div className="w-[95%] h-[2px] bg-border-100 border-border-50"></div>
                                <div className="flex w-full justify-between">
                                    {/* Total Marks */}
                                    <p>
                                        Total Marks:{" "}
                                        <span className="font-semibold text-gray-900">
                                            {result.totalMarks || "N/A"}
                                        </span>
                                    </p>
                                    {/* Obtained Marks */}
                                    <p>
                                        Obtained Marks:{" "}
                                        <span className="font-semibold text-gray-900">
                                            {result.obtainedMarks || "N/A"}
                                        </span>
                                    </p>
                                </div>
                                <div className="flex w-full justify-between">
                                    {/* Percentage */}
                                    <p>
                                        Percentage:{" "}
                                        <span className="font-semibold text-gray-900">
                                            {result.percentage ? `${result.percentage}%` : "N/A"}
                                        </span>
                                    </p>
                                    {/* Grade */}
                                    <p>
                                        Grade:{" "}
                                        <span className="font-semibold text-gray-900">
                                            {result.grade || "N/A"}
                                        </span>
                                    </p>
                                    {/* Status */}
                                    <p>
                                        Status:{" "}
                                        <span
                                            className={`font-semibold ${result.status !== "pass"
                                                ? "text-green-500"
                                                : "text-red-500"
                                                }`}
                                        >
                                            {result.status || "N/A"}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No results found.</p>
                    )}
                </section>
            </div>
        </section>
      
    </div>
  )
}

export default ResultListComponent
