import React, { useEffect, useState, useTransition } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useNavigate } from "react-router-dom";
import { ParentFeeNotification, myAllStudentList } from "../../../Services/ParentApi";
import ResultListComponent from "../../Common/ResultListComponent";
import MyStudents from "./MyStudentList";
import FeesTableComponent from "../../Common/FeesTableComponent";

const ParentChildFeeList = () => {
    const [selectedId, setSelectedId] = useState('');
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();
    const [notifications, setNotifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        startTransition(() => {
            myAllStudentList()
                .then((response) => {
                    setNotifications(response?.data || []);
                })
                .catch((error) => {
                    console.error("Error fetching notifications:", error);
                });
        });
    }, []);

    const handleViewClick = (notification) => {
        setSelectedNotification(notification);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedNotification(null);
    };

    useEffect(() => {
        startTransition(() => {
            ParentFeeNotification(selectedId)
                .then((response) => {
                    setNotifications(response?.data?.notifications || []);
                })
                .catch((error) => {
                    console.error("Error Fetching Results:", error);
                });
        });
    }, [selectedId]);

    const onStudentSelect = (studentId) => {
        setSelectedId(studentId);
    }


    // Function to handle storing the exam result in localStorage and navigation
    const handleExamClick = (feesId) => {
        localStorage.setItem("fee", JSON.stringify(feesId));
        navigate(`/parent/dashboard/detail/ExamResult/${feesId?._id}`);
    };


    return (
        <div className="font-poppins">
            <Headingtitle title="My Childrean Result" />
            {<MyStudents onStudentSelect={onStudentSelect} />}

           <div className="mt-5">
           <FeesTableComponent
                isPending={isPending}
                handleCloseModal={handleCloseModal}
                notifications={notifications}
                isModalOpen={isModalOpen}
                selectedNotification={selectedNotification}
                handleViewClick={handleViewClick}
            />
           </div>
        </div>
    );
};

export default ParentChildFeeList;
