import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { childTimeTable } from "../../../Services/ParentApi";
import MyStudents from "./MyStudentList";

const ParentChildTimeTable = () => {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [selectedDay, setSelectedDay] = useState("Monday");
    const [timeTableData, setTimeTableData] = useState({});
    const [classs, setClasss] = useState("");
    const [section, setSection] = useState("");
    const [selectedStudentId, setSelectedStudentId] = useState("");

    // Transform timetable data to group by days
    const transformTimetableData = (timetable) => {
        const groupedData = {};
        days.forEach((day) => {
            groupedData[day] = timetable.filter((item) => item.day.includes(day));
        });
        return groupedData;
    };

    useEffect(() => {
        const fetchTimeTable = async () => {
            try {
                const response = await childTimeTable(selectedStudentId);
                const timetable = response?.data?.timetable || [];
                setClasss(response?.data?.class);
                setSection(response?.data?.section);
                setTimeTableData(transformTimetableData(timetable));
            } catch (error) {
                console.error("Error fetching timetable:", error);
            }
        };
        fetchTimeTable();
    }, [selectedStudentId]);

    const onStudentSelect = (studentId) => {
        setSelectedStudentId(studentId);
    }
    
    
    return (
        <div className="font-poppins">
            <Headingtitle title="TimeTable" />

            {<MyStudents onStudentSelect={onStudentSelect} />}

            <main className="bg-white shadow-md rounded-md mt-5 p-3 text-[0.77rem]">
                <p className="font-bold text-[1.2rem] p-2">My TimeTable</p>
                <div className="text-[0.88rem] text-gray-500 p-2">
                    Class {classs} - Section {section}
                </div>
                <div className="grid w-full grid-cols-12 gap-5 mb-5">
                    <div className="col-span-10 space-x-10 mx-auto flex justify-evenly">
                        {days.map((day) => (
                            <button
                                key={day}
                                className={`p-4 font-semibold rounded  
                                ${selectedDay === day ? "bg-blue-900 text-white shadow-lg" : "text-black"}
                            `}
                                onClick={() => setSelectedDay(day)}
                            >
                                {day}
                            </button>
                        ))}
                    </div>
                </div>
                <section className="grid grid-cols-10 mx-auto grid-rows-2 gap-3 justify-center items-center">
                    {timeTableData[selectedDay]?.length > 0 ? (
                        timeTableData[selectedDay].map((item, index) => (
                            <div
                                key={index}
                                className="flex col-span-3 h-[100px] space-x-4 items-center justify-center"
                            >
                                {/* Time Section */}
                                <div className="text-right text-[0.77rem] flex flex-col justify-between items-center">
                                    <p>{item.timeSlot.start}</p>
                                    <div className="h-10 w-[1px] bg-gray-400 mx-4"></div> {/* Vertical line */}
                                    <p>{item.timeSlot.end}</p>
                                </div>

                                {/* Class Information Section */}
                                <div className="bg-gray-100 rounded-lg p-4 w-64 shadow-md flex flex-col space-y-3">
                                    <div>
                                        <p className="text-[10px] text-gray-500 ">Subject</p>
                                        <p className="text-[0.77rem] font-semibold">{item.subject}</p>
                                    </div>
                                    <div>
                                        <p className="text-[10px] text-gray-500">Teacher</p>
                                        <p className="text-[0.77rem] font-semibold">
                                            {item.teacher.name.firstName} {item.teacher.name.lastName}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="col-span-10 text-center text-gray-500">
                            No timetable available for {selectedDay}.
                        </p>
                    )}
                </section>
            </main>
        </div>
    );
};

export default ParentChildTimeTable;
