import React, { useState, useEffect } from "react";
import { GetOnboardedStudentList, getAllParentList, assignNewStudentToParent } from "../../../Services/AdminApis";
import toast from "react-hot-toast";

const AssignStudentParent = () => {
    const [students, setStudents] = useState([]);
    const [parents, setParents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState("");
    const [selectedParent, setSelectedParent] = useState("");

    useEffect(() => {
        // Fetch students and parents on component mount
        const fetchData = async () => {
            try {
                const studentData = await GetOnboardedStudentList();
                const parentData = await getAllParentList();
                setStudents(studentData?.data?.students || []);
                setParents(parentData?.data?.parent || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (!selectedStudent || !selectedParent) {
            alert("Please select both a student and a parent");
            return;
        }

        try {
            // Make the API call to assign the student to the parent
            const response = await assignNewStudentToParent(selectedStudent, selectedParent);
            if (response.success === 201 || 200) {
                toast.success("successful Assign Student to Parent ");
            }
        } catch (error) {
            console.error("Error during assignment:", error);
            toast.error(error?.response?.data?.error);
        }
    };

    return (
        <div className="p-6  mx-auto bg-white  shadow-lg rounded-lg">
            <h2 className="text-3xl font-semibold mb-6 text-center text-blue-600">Assign Student to Parent</h2>

            {/* Student Dropdown */}
            <div className="mb-5">
                <label htmlFor="student" className="block text-lg font-medium text-gray-700 mb-2">
                    Select Student
                </label>
                <select
                    id="student"
                    className="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={selectedStudent}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                >
                    <option value="">-- Select Student --</option>
                    {students?.map((student) => (
                        <option key={student._id} value={student._id}>
                            {`${student.name.firstName} ${student.name.middleName} ${student.name.lastName}`}
                        </option>
                    ))}
                </select>
            </div>

            {/* Parent Dropdown */}
            <div className="mb-5">
                <label htmlFor="parent" className="block text-lg font-medium text-gray-700 mb-2">
                    Select Parent
                </label>
                <select
                    id="parent"
                    className="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={selectedParent}
                    onChange={(e) => setSelectedParent(e.target.value)}
                >
                    <option value="">-- Select Parent --</option>
                    {parents?.map((parent, index) => (
                        <option key={index} value={parent._id}> {/* Assuming using father's ID for Parent selection */}
                            {`${parent.father.firstName} ${parent.father.lastName} (Father)`}
                        </option>
                    ))}
                </select>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
                <button
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-200"
                    onClick={handleSubmit}
                >
                    Assign
                </button>
            </div>
        </div>
    );
};

export default AssignStudentParent;
