import React, { useState, useEffect } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import Headingtitle from "../../Common/Headingtitle";
import Count_Icon from "../../../Assests/Logos/Count_Icon.png"
import { getmyProfile, getStudentAnalytics } from '../../../Services/ParentApi'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Student_Img from '../../../Assests/Logos/Student_Img.png'
import StudentAnalyticsChart from "../StudentDashboard/StudentAnalytics";
import { formatDateOfBirth, formatName } from "../../../utils/utilityFunction";

const ParentDashboard = () => {
    const [analytics, setAnalytics] = useState([])
    const [profile, setProfile] = useState({})
    const [currentParent, setCurrentParrent] = useState("Father")
    const [selectedStudent, setSelectedStudent] = useState()

    const parent = ["Father", "Mother", "Gaurdian"]
    const DashboardData = [{
        title: "Class",
        count: 12
    },

    {
        title: "Teacher",
        count: 129
    },
    {
        title: "Teachers",
        count: 13
    }, {
        title: "Event",
        count: 36
    }]

    const fetchAnalyticsData = async (selectedStudent) => {
        try {
            const response = await getStudentAnalytics(selectedStudent);
            if (response.status === 200) {
                const data = response.data.analytics || []
                setAnalytics(data)
            }
        } catch (error) {
            setAnalytics([])
        }
    }

    const fetchProfileData = async () => {
        try {
            const response = await getmyProfile();
            if (response.status === 200) {
                const data = response.data.parent || {}
                setProfile(data)
                const firstStudent = data.students[0]
                setSelectedStudent(firstStudent._id)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProfileData()
    }, [])

    useEffect(() => {
        if (selectedStudent) {
            fetchAnalyticsData(selectedStudent)
        }
    }, [selectedStudent])


    const toggleParent = (direction) => {
        const currentIndex = parent.indexOf(currentParent);
        let nextIndex;

        if (direction === "next") {
            nextIndex = (currentIndex + 1) % parent.length;
        } else if (direction === "prev") {
            nextIndex = (currentIndex - 1 + parent.length) % parent.length;
        }

        setCurrentParrent(parent[nextIndex]);
    };

    const toggleStudent = (direction) => {
        if (!profile?.students || profile.students.length === 0) return;

        const currentIndex = profile.students.findIndex(student => student._id === selectedStudent);
        let nextIndex;

        if (direction === "next") {
            nextIndex = (currentIndex + 1) % profile.students.length;
        } else if (direction === "prev") {
            nextIndex = (currentIndex - 1 + profile.students.length) % profile.students.length;
        }

        setSelectedStudent(profile.students[nextIndex]._id);
    };


    return (
        <div className="font-poppins">
            <Headingtitle title="Dashboard" />

            <main className="px-6 py-2">
                <section className="grid w-full grid-cols-12 gap-5">

                    {
                        DashboardData.map((item, index) => (
                            <div key={index}
                                style={{ boxShadow: "6px 6px 54px 0px #0000000D" }}
                                className="col-span-3 font-nunito flex flex-col justify-between h-[8rem] rounded-2xl p-4 bg-white text-text_blue-500  shadow-md">
                                <div className="flex justify-between ">
                                    <p className="text-[1.4rem] text-[#979797]  ">{item.title}</p>
                                    <div style={{ backgroundColor: 'rgba(0, 123, 201, 0.21)' }} className="p-3 rounded-xl">
                                        <img src={Count_Icon} className="w-[1.75rem] h-[2.5rem]" />
                                    </div>

                                </div>
                                <p className="text-[1.5rem] text-text_gray-125 font-semibold">{item.count}</p>
                            </div>
                        ))
                    }

                    <div className="col-span-6 h-[410px] justify-between flex flex-col font-poppins bg-white text-text_blue-500 px-5 py-3  rounded-xl shadow-md">
                        <p className="text-[1.5rem]  font-semibold h-[100px] border-b-[1px] pl-5 border-border-100">Parent Info</p>
                        <div className="flex justify-between w-full my-1 h-[250px]  mb-5 pl-5">
                            <div className="w-[50%]">
                                {currentParent === "Gaurdian" &&
                                    <div>
                                        <div className="flex gap-[4px] flex-col">
                                            <img src={profile?.guardian?.avatar || Student_Img} className="w-[100px] h-[100px]" />
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">
                                                Guardian Name
                                            </p>

                                            <p className="font-poppins text-[0.88rem] text-text_gray-100  ">{`${profile?.guardian?.firstName} ${profile?.guardian?.middleName} ${profile?.guardian?.lastName}`}</p>
                                        </div>
                                        <div>
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Relation </p>
                                            <p className="font-poppins text-[0.88rem] text-text_gray-100 ">{profile?.guardian?.relations}</p>
                                        </div>
                                    </div>
                                }


                                {
                                    currentParent === "Father" &&
                                    <div >
                                        <div className="flex gap-[4px] flex-col">
                                            <img src={profile?.father?.avatar || Student_Img} className="w-[100px] h-[100px]" />
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">
                                                Father Name
                                            </p>

                                            <p className="font-poppins text-[0.88rem] text-text_gray-100  ">{`${profile?.father?.firstName} ${profile?.father?.middleName} ${profile?.father?.lastName}`}</p>
                                        </div>
                                        <div>
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Mobile </p>
                                            <p className="font-poppins text-[0.88rem] text-text_gray-100 ">{profile?.father?.mobile}</p>
                                        </div>
                                    </div>
                                }

                                {currentParent === "Mother" &&
                                    <div>
                                        <div className="flex gap-[4px] flex-col">
                                            <img src={profile?.mother?.avatar || Student_Img} className="w-[100px] h-[100px]" />
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">
                                                Mother Name
                                            </p>

                                            <p className="font-poppins text-[0.88rem] text-text_gray-100  ">{`${profile?.mother?.firstName} ${profile?.mother?.middleName} ${profile?.mother?.lastName}`}</p>
                                        </div>
                                        <div>
                                            <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Mobile </p>
                                            <p className="font-poppins text-[0.88rem] text-text_gray-100 ">{profile?.mother?.mobile}</p>
                                        </div>
                                    </div>
                                }

                                <div className="flex justify-evenly">
                                    <ArrowBackIcon onClick={() => toggleParent("prev")} style={{ cursor: "pointer" }} />
                                    <ArrowForwardIcon onClick={() => toggleParent("next")} style={{ cursor: "pointer" }} />
                                </div>
                            </div>

                            <div className="w-[50%] flex flex-col justify-between">
                                {(profile && selectedStudent) && profile.students && profile.students.length > 0 && (
                                    <>
                                        {profile.students.map((student) => (
                                            student._id === selectedStudent && (
                                                <div key={student._id}>
                                                    <div>
                                                        <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Student Name</p>
                                                        <p className="font-poppins text-[0.88rem] text-text_gray-100">{formatName(student)}</p>
                                                    </div>
                                                    <div>
                                                        <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Class (section)</p>
                                                        <p className="font-poppins text-[0.88rem] text-text_gray-100">{student?.class?.className} - {student?.class?.classSection}</p>
                                                    </div>
                                                    <div>
                                                        <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Student ID</p>
                                                        <p className="font-poppins text-[0.88rem] text-text_gray-100">{student.studentId}</p>
                                                    </div>
                                                    <div>
                                                        <p className="font-poppins text-[1.1rem] font-semibold leading-[27px] text-left">Date Of Birth</p>
                                                        <p className="font-poppins text-[0.88rem] text-text_gray-100">{formatDateOfBirth(student.dateOfBirth)}</p>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                        <div className="flex justify-evenly mt-4">
                                            <ArrowBackIcon onClick={() => toggleStudent("prev")} style={{ cursor: "pointer" }} />
                                            <ArrowForwardIcon onClick={() => toggleStudent("next")} style={{ cursor: "pointer" }} />
                                        </div>
                                    </>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="col-span-6 bg-white h-[410px]  text-text_blue-500 rounded-xl shadow-md">
                            <StudentAnalyticsChart analyticsData={analytics} />
                        
                    </div>
                </section>
            </main>
        </div>
    );
};

export default ParentDashboard;

