import React, { useEffect, useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import Headingtitle from "../../Common/Headingtitle";
import { useSearchParams } from "react-router-dom";
import {
  getClassSubjectChapterList,
  GetClassCategoryList,
  GetClassSectionList,
} from "../../../Services/AdminApis";
import toast from "react-hot-toast";

const GetSubjectChapterList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [allSectionList, setAllSectionList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [subjectChapterData, setSubjectChapterData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [formData, setFormData] = useState({
    className: searchParams.get("className") || "",
    section: searchParams.get("sections") || "",
  });
  const schoolId = JSON.parse(localStorage.getItem("schoolId"));

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchClassList = async () => {
      try {
        setLoading(true);
        const response = await GetClassCategoryList();
        const sectionRes = await GetClassSectionList();
        setClassList(response?.data?.classCategories || []);
        setAllSectionList(sectionRes?.data?.sections || []);
      } catch (error) {
        toast.error("Error fetching class or section data.");
      } finally {
        setLoading(false);
      }
    };
    fetchClassList();
  }, []);

  useEffect(() => {
    const fetchSubjectChapterList = async () => {
      try {
        setLoading(true);
        const response = await getClassSubjectChapterList(
          schoolId,
          formData.className,
          formData.section
        );
        if (response?.status === 201 || response?.status === 200) {
          setSubjectChapterData(response?.data || []);
        } else {
          setSubjectChapterData([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Error fetching data.");
        setSubjectChapterData([]);
      } finally {
        setLoading(false);
      }
    };
    fetchSubjectChapterList();
  }, [formData]);

  return (
    <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
      <Headingtitle title="Create New Subject With Chapter" />
      <section className="min-w-full rounded-lg min-h-[80vh] font-poppins">
        <form>
          <div className="bg-white p-6 grid grid-cols-2 gap-5">
            <p className="text-[1rem] font-semibold pb-2 col-span-2">
              Create Subject
            </p>
            <div className="col-span-1">
              <label
                htmlFor="className"
                className="block text-sm font-medium text-gray-700 capitalize"
              >
                Class Name
              </label>
              <TextField
                select
                fullWidth
                name="className"
                value={formData.className}
                onChange={handleFormDataChange}
              >
                {classList.map((item) => (
                  <MenuItem key={item._id} value={item.className}>
                    {item.className}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-span-1">
              <label
                htmlFor="sections"
                className="block text-sm font-medium text-gray-700 capitalize"
              >
                Section
              </label>
              <TextField
                select
                fullWidth
                name="section"
                value={formData.section}
                onChange={handleFormDataChange}
              >
                {allSectionList.map((item) => (
                  <MenuItem key={item._id} value={item.classSection}>
                    {item.classSection}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </form>

        {/* Loading Spinner */}
        {loading ? (
          <div className="flex justify-center items-center mt-6">
            <CircularProgress />
          </div>
        ) : (
          // Table to display subject and chapter data
          <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
              <tr>
                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                  Subject Name
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                  Class Name
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold tracking-wide shadow-sm">
                  Sections
                </th>
              </tr>
            </thead>
            <tbody>
              {subjectChapterData.map((item, index) => (
                <tr
                  key={item._id}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-indigo-100 transition-all`}
                >
                  <td className="border border-gray-300 px-4 py-2 text-gray-700 font-medium">
                    {item.subjectName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-gray-700">
                    {item.className}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-gray-700">
                    {item.sections.join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default GetSubjectChapterList;
