import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getSubjectOverView } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";

const SubjectOverViewDetails = () => {
    const { subjectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [subjectChapterList, setSubjectChapterList] = useState([])
    const [selectedSubject, setSelectedSubject] = useState('');


    useEffect(() => {
        const fetchStudentAttendanceById = async () => {
            setLoading(true);
            try {
                const response = await getSubjectOverView(subjectId);

                // Check if the response is successful
                if (response.status === 200) {
                    setSubjectChapterList(response?.data?.chapters || []);
                    setSelectedSubject(response?.data)
                } else {
                    // Handle error responses with a status other than 200
                    console.log("Error:", response?.data?.message);
                    setSubjectChapterList([]);
                }
            } catch (error) {
                // General error handling
                console.error("Error fetching attendance data:", error);
                setSubjectChapterList([]);
            }
            setLoading(false);
        };

        fetchStudentAttendanceById();
    }, [subjectId]);


    return (
        <div className="font-poppins">
            <Headingtitle title="Subject" />

            {/* Month and Year Select */}
            <main className="bg-white min-h-[80vh] rounded-lg p-6 shadow-lg">
                <p className="text-[1.2rem] font-semibold pb-4 text-gray-800">Subject Overview Detail</p>
                <div>
                    {loading ? (
                        <p className="text-gray-500">Loading...</p>
                    ) : subjectChapterList && subjectChapterList.length > 0 ? (
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md">
                            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                <tr>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Chapter Number</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Title</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Description</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Objectives</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Resources</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subjectChapterList.map((chapter, index) => (
                                    <tr
                                        key={index}
                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                            } hover:bg-indigo-100 transition-all`}
                                    >
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{chapter.chapterNumber}</td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{chapter.title}</td>
                                        <td className="px-6 py-4 text-gray-600 text-[14px]">{chapter.description}</td>
                                        <td className="px-6 py-4 text-gray-600 text-[14px]">{chapter.objectives}</td>
                                        <td className="px-6 py-4 text-gray-700 text-[14px]">
                                            {chapter.resources.length > 0 ? (
                                                <ul className="list-disc pl-4 space-y-1">
                                                    {chapter.resources.map((resource, resourceIndex) => (
                                                        <li key={resourceIndex}>
                                                            <a
                                                                href={resource}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-indigo-600 hover:underline"
                                                            >
                                                                {resource}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <span className="text-gray-400">No resources available</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-gray-500">No data available</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default SubjectOverViewDetails;
