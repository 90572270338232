import React, { useEffect, useState } from "react";
import Avtar from "../../../Assests/Logos/avtar.png";
import ParentProfile from "../../Common/ParentProfile";
import { getmyProfile } from "../../../Services/ParentApi";

const ParentsProfile = () => {
    const [parentsDetails, setParentsDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMyProfile = async () => {
            try {
                const response = await getmyProfile();
                const parentData = response?.data?.parent || {};

                // Map parent details for display
                const formattedParents = [
                    {
                        relation: "Father",
                        name: `${parentData.father?.firstName || ""} ${parentData.father?.middleName || ""} ${parentData.father?.lastName || ""}`.trim(),
                        dob: parentData.father?.dateOfBirth
                            ? new Date(parentData.father.dateOfBirth).toLocaleDateString()
                            : "N/A",
                        email: parentData.father?.email || "N/A",
                        occupation: parentData.father?.occupation || "N/A",
                        phone: parentData.father?.mobile || "N/A",
                        avatar: parentData.father?.avatar || Avtar,
                    },
                    {
                        relation: "Mother",
                        name: `${parentData.mother?.firstName || ""} ${parentData.mother?.middleName || ""} ${parentData.mother?.lastName || ""}`.trim(),
                        dob: parentData.mother?.dateOfBirth
                            ? new Date(parentData.mother.dateOfBirth).toLocaleDateString()
                            : "N/A",
                        email: parentData.mother?.email || "N/A",
                        occupation: parentData.mother?.occupation || "N/A",
                        phone: parentData.mother?.mobile || "N/A",
                        avatar: parentData.mother?.avatar || Avtar,
                    },
                    {
                        relation: parentData.guardian?.relations || "Guardian",
                        name: `${parentData.guardian?.firstName || ""} ${parentData.guardian?.middleName || ""} ${parentData.guardian?.lastName || ""}`.trim(),
                        dob: parentData.guardian?.dateOfBirth
                            ? new Date(parentData.guardian.dateOfBirth).toLocaleDateString()
                            : "N/A",
                        email: parentData.guardian?.email || "N/A",
                        occupation: parentData.guardian?.occupation || "N/A",
                        phone: parentData.guardian?.mobile || "N/A",
                        avatar: parentData.guardian?.avatar || Avtar,
                        address: parentData.guardian?.address || "N/A",
                    },
                ];

                setParentsDetails(formattedParents);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch parent profiles.");
                setLoading(false);
            }
        };

        fetchMyProfile();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <section>
            <ParentProfile parentsDetails={parentsDetails} />
        </section>
    );
};

export default ParentsProfile;
