import React, { useEffect, useState } from "react";
import {
    getAllSubjectListData,
    getAllClassCategoryList,
    getAllSectionList
} from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";
import { useSearchParams, useNavigate } from "react-router-dom";

const ChapterMaterial = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const schoolId = JSON.parse(localStorage.getItem("schoolId")) || "";
    const classId = JSON.parse(localStorage.getItem("classId")) || "";

    const [selectedClassName, setSelectedClassName] = useState("");
    const [selectedSection, setSelectedSection] = useState("");
    const [subjectData, setSubjectData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Button disable state

    useEffect(() => {
        const fetchSectionAndClassCategoryData = async () => {
            try {
                const categoriesResponse = await getAllClassCategoryList();
                const subjectResponse = await getAllSectionList();

                if (categoriesResponse.status === 200 && subjectResponse.status === 200) {
                    setCategoryData(categoriesResponse?.data?.classCategories || []);
                    setSubjectData(subjectResponse?.data?.sections || []);
                } else {
                    setCategoryData([]);
                    setSubjectData([]);
                }
            } catch (error) {
                console.error(error);
                setCategoryData([]);
                setSubjectData([]);
            }
        };

        fetchSectionAndClassCategoryData();
    }, []);

    // Enable/Disable button based on selections
    useEffect(() => {
        setIsButtonDisabled(!(selectedClassName && selectedSection));
    }, [selectedClassName, selectedSection]);

    // Handle class name change
    const handleClassNameChange = (event) => {
        const className = event.target.value;
        setSelectedClassName(className);
        setSearchParams({ className, section: selectedSection });
    };

    // Handle section change
    const handleSectionChange = (event) => {
        const section = event.target.value;
        setSelectedSection(section);
        setSearchParams({ className: selectedClassName, section });
    };

    // Handle "Create Chapter" click
    const handleCreateChapter = () => {
        if (selectedClassName && selectedSection) {
            navigate(`/teacher/dashboard/material/create-Chapter/${schoolId}?className=${selectedClassName}&section=${selectedSection}`);
        }
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Attendance" />

            <main className="bg-white min-h-[80vh] flex flex-col justify-between rounded-lg p-6 shadow-lg">
                <div>
                    <p className="text-[1.2rem] font-semibold pb-4 text-gray-800">
                        Add New Chapters
                    </p>

                    {/* Filters Section */}
                    <section className="grid grid-cols-12 gap-6 p-4">
                        {/* Class Name Dropdown */}
                        <div className="col-span-6">

                            <select
                                id="className"
                                value={selectedClassName}
                                onChange={handleClassNameChange}
                                className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                            >
                                <option value="">-- Select Class --</option>
                                {categoryData.map((category) => (
                                    <option key={category._id} value={category.className}>
                                        {category.className}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Section Dropdown */}
                        <div className="col-span-6">

                            <select
                                id="section"
                                value={selectedSection}
                                onChange={handleSectionChange}
                                className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                            >
                                <option value="">-- Select Section --</option>
                                {subjectData.map((section) => (
                                    <option key={section._id} value={section._id}>
                                        {section.classSection}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>
                </div>

                <button
                    onClick={handleCreateChapter}
                    type="submit"
                    disabled={isButtonDisabled}
                    className={`${isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600"
                        } w-full py-2 rounded-md text-white`}
                >
                    Create Chapter
                </button>
            </main>
        </div>
    );
};

export default ChapterMaterial;
