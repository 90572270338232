import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { getAlltranportRoute, addStudentToATransportRoute, getAllStudentList } from "../../../Services/AdminApis";



const OnboardStudentToRoute = () => {
    const [allRoutesData, setAllRoutesData] = useState([]);
    const [allStudentListData, setAllStudentListData] = useState([]);
    const [studentRoute, setStudentRoute] = useState({
        routeId: "",
        locationName: "",
        studentName: "",
        studentId: "",
        location: {
            type: "Point",
            coordinates: ["", ""], // [longitude, latitude]
        },
        pickUpTime: "",
        dropOffTime: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchAllRoutes = async () => {
            try {
                const response2 = await getAllStudentList();
                const response = await getAlltranportRoute();
                console.log(response?.data?.routes);
                setAllRoutesData(response?.data?.routes);
                setAllStudentListData(response2?.data?.students)
            } catch (error) {
                console.error("Error While Getting Routes data");
            }
        };
        fetchAllRoutes();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("coordinates")) {
            const index = name === "coordinates[0]" ? 0 : 1;
            setStudentRoute((prev) => ({
                ...prev,
                location: {
                    ...prev.location,
                    coordinates: [
                        index === 0 ? value : prev.location.coordinates[0],
                        index === 1 ? value : prev.location.coordinates[1],
                    ],
                },
            }));
        } else {
            setStudentRoute((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const validateForm = () => {
        const tempErrors = {};
        Object.keys(studentRoute).forEach((key) => {
            if (key === "location") {
                const { coordinates } = studentRoute.location;
                if (!coordinates[0]) tempErrors["coordinates[0]"] = "Longitude is required";
                if (!coordinates[1]) tempErrors["coordinates[1]"] = "Latitude is required";
            } else if (!studentRoute[key]) {
                tempErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required`;
            }
        });
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form before submitting
        if (validateForm()) {
            try {
                // Call the API to add the student to the transport route
                const response = await addStudentToATransportRoute(studentRoute);

                // Check if the API response indicates success (status 200 or 201)
                if (response?.status === 200 || response?.status === 201) {
                    // Reset the form fields after successful submission
                    resetForm();
                    // Show success toast
                    toast.success("Successfully added student to transport route");
                } else {
                    // Show error toast if something went wrong
                    toast.error("Something went wrong");
                    console.error(response?.message);
                }
            } catch (error) {
                // Show error toast and log the error if the API request fails
                toast.error(error?.response?.data?.message || error.message);
                console.error(error.message);
            }
        }
    };

    const resetForm = () => {
        setStudentRoute({
            routeId: "",
            locationName: "",
            studentName: "",
            studentId: "",
            location: {
                type: "Point",
                coordinates: ["", ""], // Empty coordinates
            },
            pickUpTime: "",
            dropOffTime: "",
        });
    };


    return (

        <div className="bg-gray-100 p-5 rounded-lg font-poppins">
            <h1 className="text-xl font-bold mb-5">Onboard Student to Route</h1>
            <form onSubmit={handleSubmit} className="grid grid-cols-6 bg-white gap-4 p-5 rounded-md">
                {Object.keys(studentRoute).map((key) => {
                    if (key === "location") {
                        return (
                            <React.Fragment key={key}>
                                <div className="flex flex-col col-span-3">
                                    <input
                                        id="coordinates[0]"
                                        name="coordinates[0]"
                                        type="text"
                                        value={studentRoute.location.coordinates[0]}
                                        onChange={handleInputChange}
                                        placeholder="Enter Longitude"
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors["coordinates[0]"] ? "border-red-500" : "border-[#EBEDF2]"}`
                                        }
                                    />
                                    {errors["coordinates[0]"] && (
                                        <span className="text-red-500 text-sm">{errors["coordinates[0]"]}</span>
                                    )}
                                </div>
                                <div className="flex flex-col col-span-3">
                                    <input
                                        id="coordinates[1]"
                                        name="coordinates[1]"
                                        type="text"
                                        value={studentRoute.location.coordinates[1]}
                                        onChange={handleInputChange}
                                        placeholder="Enter Latitude"
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors["coordinates[1]"] ? "border-red-500" : "border-[#EBEDF2]"}`
                                        }
                                    />
                                    {errors["coordinates[1]"] && (
                                        <span className="text-red-500 text-sm">{errors["coordinates[1]"]}</span>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    } else if (key === "routeId") {
                        return (
                            <div key={key} className="flex flex-col col-span-12">
                                <select
                                    id="routeId"
                                    name="routeId"
                                    value={studentRoute.routeId}
                                    onChange={handleInputChange}
                                    className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors.routeId ? "border-red-500" : "border-[#EBEDF2]"}`}
                                >
                                    <option value="">Select Route</option>
                                    {allRoutesData.map((route) => (
                                        <option key={route._id} value={route._id}>
                                            {`${route.vehicle.routeNumber} - ${route.vehicle.driverName} (${route.vehicle.vehicleNumber})`}
                                        </option>
                                    ))}
                                </select>
                                {errors[key] && (
                                    <span className="text-red-500 text-sm">{errors[key]}</span>
                                )}
                            </div>
                        );
                    } else if (key === "studentId") {
                        return (
                            <div key={key} className="flex flex-col col-span-6">
                                <select
                                    id="studentId"
                                    name="studentId"
                                    value={studentRoute.studentId}
                                    onChange={handleInputChange}
                                    className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors.studentId ? "border-red-500" : "border-[#EBEDF2]"}`}
                                >
                                    <option value="">Select Student</option>
                                    {allStudentListData.map((student) => (
                                        <option key={student._id} value={student._id}>
                                            {`${student.name.firstName} ${student.name.lastName}`}
                                        </option>
                                    ))}
                                </select>
                                {errors[key] && (
                                    <span className="text-red-500 text-sm">{errors[key]}</span>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className="flex flex-col col-span-12">
                                <div>
                                    <label>{`${key.replace(/([A-Z])/g, " $1")}`}</label>
                                    <input
                                        id={key}
                                        name={key}
                                        type={key.includes("Time") ? "time" : "text"}
                                        value={studentRoute[key]} // Set the correct value for the input field
                                        onChange={handleInputChange}
                                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                                        className={`w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none ${errors[key] ? "border-red-500" : "border-[#EBEDF2]"}`}
                                    />
                                    {errors[key] && (
                                        <span className="text-red-500 text-sm">{errors[key]}</span>
                                    )}
                                </div>
                            </div>
                        );
                    }
                })}


                <div className="col-span-12 flex justify-end">
                    <button
                        type="submit"
                        className="bg-[#22577A] w-full text-white py-2 px-4 rounded-lg hover:bg-[#1b4460]"
                    >
                        Onboard Student
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OnboardStudentToRoute;
