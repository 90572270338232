import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { StudentlatestNotice } from "../../../Services/StudentApis";

const StudentNoticeList = () => {
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await StudentlatestNotice();
        if (response.status === 200) {
          const formattedNotices = response.data.notices.map((notice) => ({
            id: notice._id,
            title: notice.title,
            content: notice.content,
            expiryDate: new Date(notice.expiryDate).toLocaleDateString(),
            createdAt: new Date(notice.createdAt).toLocaleDateString(),
            targetAudience: notice.targetAudience,
            status: notice.isDeleted ? "Deleted" : "Active",
          }));
          setNotices(formattedNotices);
        } else {
          console.error("Error fetching notices:", response?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
      setLoading(false);
    };

    fetchNotices();
  }, []);

  return (
    <div className="mt-5 p-4 bg-white rounded-lg shadow-md">
      <Headingtitle title="Latest Notices" />
      {loading ? (
        <p className="text-center mt-10 text-gray-600">Loading...</p>
      ) : notices.length === 0 ? (
        <p className="text-center mt-10 text-gray-600">No notices available.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            {/* Table Header */}
            <thead>
              <tr className="text-white roundet bg-gradient-to-r from-blue-500 to-blue-700">
                <th className="p-3 text-left text-sm font-semibold">#</th>
                <th className="p-3 text-left text-sm font-semibold">Title</th>
                <th className="p-3 text-left text-sm font-semibold">Content</th>
                <th className="p-3 text-left text-sm font-semibold">Expiry Date</th>
                <th className="p-3 text-left text-sm font-semibold">Created At</th>
                <th className="p-3 text-left text-sm font-semibold">Target Audience</th>
                <th className="p-3 text-left text-sm font-semibold">Status</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {notices.map((notice, index) => (
                <tr
                  key={notice.id}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="p-3 text-sm text-gray-800">{index + 1}</td>
                  <td className="p-3 text-sm text-gray-800">{notice.title}</td>
                  <td className="p-3 text-sm text-gray-800 truncate max-w-xs">
                    {notice.content}
                  </td>
                  <td className="p-3 text-sm text-gray-800">{notice.expiryDate}</td>
                  <td className="p-3 text-sm text-gray-800">{notice.createdAt}</td>
                  <td className="p-3 text-sm text-gray-800">
                    {notice.targetAudience || "All"}
                  </td>
                  <td
                    className={`p-3 text-sm font-semibold ${
                      notice.status === "Active"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {notice.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default StudentNoticeList;
