import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTransition } from "react";
import ExamDetails from "../../Common/ExamDetails";
import { getChildrenResultOverview } from "../../../Services/ParentApi";

const ParentExamResult = () => {
    const [examResult, setExamResult] = useState(null);
    const [isPending, startTransition] = useTransition();
    const { resultId } = useParams();

    useEffect(() => {
        // Fetch the exam result based on the provided API response structure
        console.log(" nnb hjgffui",resultId);
        startTransition(() => {
            getChildrenResultOverview(resultId)
                .then((response) => {
                    setExamResult(response?.data?.result || null);
                })
                .catch((error) => {
                    console.error("Error fetching exam result:", error);
                });
        });
    }, [resultId]);

    // Grade calculation helper function
    const getGrade = (marks, total) => {
        const percentage = (marks / total) * 100;
        if (percentage >= 90) return "A++";
        if (percentage >= 80) return "A+";
        if (percentage >= 70) return "A";
        if (percentage >= 60) return "B";
        if (percentage >= 50) return "C";
        return "Fail";
    };

    return (
        <ExamDetails isPending={isPending} examResult={examResult}/>
       );
};

export default ParentExamResult;
