import React from 'react'
import { formatDateOfBirth, formatName, formatGuardianInfo } from '../../utils/utilityFunction'
import { FaArrowDown, FaTimes } from "react-icons/fa";

const StudentTableCard = ({ data }) => {
    return (
        <>
            {data && data.map((item, index) => (
                <div className="mb-4">
                    <div className="flex flex-col border rounded-lg shadow-md w-full p-4 justify-between items-center">
                        <div className="flex w-full items-start gap-1 flex-col space-x-4 h-[130px]">
                            {/* Student Info */}
                            <div className="flex justify-between items-center w-full px-1">
                                <div className="flex gap-3 items-center">
                                    <div className="text-lg relative font-bold top-1 left-1 items-center">{index + 1}</div>
                                    <img
                                        src="https://via.placeholder.com/50"
                                        alt="Profile"
                                        className="w-[60px] h-[60px] rounded-full"
                                    />
                                    <p className="font-poppins text-[18px] font-semibold leading-[22.5px] text-left">
                                        {formatName(item.studentId)}
                                    </p>
                                </div>
                            </div>

                            {/* Divider */}
                            <div className="h-[2px] mx-10 w-[90%] px-10 bg-gray-400 grid place-content-center"></div>

                            <div className="flex w-full justify-between px-10">
                                <div className="flex space-x-10 text-[0.8rem] text-gray-500">
                                    <div>
                                        <p className="font-semibold text-black">
                                            {`${item?.studentId?.class?.className || 'N/A'} - ${item?.studentId?.class?.classSection || 'N/A'}`}
                                        </p>
                                        <p>Class</p>
                                    </div>
                                    <div className="h-full border-l-2 border-border-50 " />
                                    <div>
                                        <p className="font-semibold text-black">{item.examName}</p>
                                        <p>Exam Name</p>
                                    </div>
                                    <div className="h-full border-l-2 border-border-50 " />
                                    <div>
                                        <p className="font-semibold text-black"> {formatDateOfBirth(item?.studentId?.dateOfBirth) || "27th July 2006"}</p>
                                        <p>Birth Date</p>
                                    </div>
                                    <div className="h-full border-l-2 border-border-50 " />
                                    <div>
                                        <p className="font-semibold text-black">{item?.studentId?.rollNumber || "N/A"}</p>
                                        <p>Roll Number</p>
                                    </div>
                                    <div className="h-full border-l-2 border-border-50 " />
                                    <div>
                                        <p className="font-semibold text-black">
                                            {item.academicYear}
                                        </p>
                                        <p>Academic Year</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold text-black">
                                            {item?.studentId?.gender}
                                        </p>
                                        <p>Gender</p>
                                    </div>
                                </div>
                                {/* <div
                            className="p-4 h-fit w-fit bg-border-100 shadow-lg rounded-full cursor-pointer"
                            onClick={() => toggleCardVisibility(index)}
                        >
                            <FaArrowDown />
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default StudentTableCard
