import React from "react";
import { FaClipboard } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const OnboardClipboardModel = ({ codeId, password, email, onClose }) => {
    // Function to copy text to the clipboard
    const copyToClipboard = (text, label) => {
        navigator.clipboard.writeText(text);
        alert(`${label} copied to clipboard: ${text}`);
    };

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            {/* Modal Container */}
            <div className="bg-white rounded-lg w-[400px] p-6 shadow-lg relative">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                >
                    <MdClose size={24} />
                </button>

                {/* Warning Message */}
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 rounded-md">
                    <p className="text-sm font-semibold">
                        ⚠️ Important: The email, password, and code are generated only once.
                        Please keep them safe and do not share them with others.
                    </p>
                </div>

                {/* Modal Content */}
                <div className="text-center space-y-4">
                    <h2 className="text-xl font-bold text-gray-800">Onboarding Details</h2>

                    {/* Display Email with Copy Button */}
                    <div className="flex items-center justify-between p-3 border rounded-md text-gray-700">
                        <span>Email: {email || "N/A"}</span>
                        <button
                            onClick={() => copyToClipboard(email, "Email")}
                            className="text-text_blue-500 hover:text-text_blue-600"
                        >
                            <FaClipboard size={18} />
                        </button>
                    </div>

                    {/* Display Password with Copy Button */}
                    <div className="flex items-center justify-between p-3 border rounded-md text-gray-700">
                        <span>Password: {password || "N/A"}</span>
                        <button
                            onClick={() => copyToClipboard(password, "Password")}
                            className="text-text_blue-500 hover:text-text_blue-600"
                        >
                            <FaClipboard size={18} />
                        </button>
                    </div>

                    {/* Display Code ID with Copy Button */}
                    <div className="flex items-center justify-between p-3 border rounded-md text-gray-700">
                        <span>Code ID: {codeId || "N/A"}</span>
                        <button
                            onClick={() => copyToClipboard(codeId, "Code ID")}
                            className="text-text_blue-500 hover:text-text_blue-600"
                        >
                            <FaClipboard size={18} />
                        </button>
                    </div>
                </div>

                {/* Footer Section */}
                <div className="mt-6 text-center">
                    <button
                        onClick={onClose}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-200"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OnboardClipboardModel;
