import React from 'react'

const FeesTableComponent = ({isPending,notifications,isModalOpen ,selectedNotification,handleCloseModal,handleViewClick}) => {
  return (
    <div>
         <div className="font-poppins">
            <h2 className="text-2xl font-bold mb-4">Fee Notifications</h2>

            {isPending ? (
                <p>Loading notifications...</p>
            ) : notifications.length > 0 ? (
                <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                    <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                        <tr>
                            <th className="px-6 py-4 text-left text-[16px] font-bold">Notification Type</th>
                            <th className="px-6 py-4 text-left text-[16px] font-bold">Message</th>
                            <th className="px-6 py-4 text-left text-[16px] font-bold">Fee Due Date</th>
                            <th className="px-6 py-4 text-left text-[16px] font-bold">Status</th>
                            <th className="px-6 py-4 text-left text-[16px] font-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notifications.map((notification, index) => (
                            <tr
                                key={notification._id}
                                className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-indigo-100 transition-all`}
                            >
                                <td className="px-6 py-4 text-gray-700 text-[15px]">{notification.type}</td>
                                <td className="px-6 py-4 text-gray-700 text-[15px]">{notification.message}</td>
                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                    {new Date(notification.feeRecordId.dueDate).toLocaleDateString()}
                                </td>
                                <td className="px-6 py-4 text-gray-700 text-[15px]">{notification.status}</td>
                                <td className="px-6 py-4 text-gray-700 text-[15px]">
                                    <button
                                        onClick={() => handleViewClick(notification)}
                                        className="text-blue-500 hover:text-blue-700"
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-gray-500 mt-6">No Notifications Found</p>
            )}

            {/* Modal */}
            {isModalOpen && selectedNotification && (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75 z-50">
        <div className="bg-white rounded-lg  relative max-h-[80vh] w-11/12 sm:w-96 p-6 shadow-lg overflow-y-auto"
        style={{msOverflowStyle:"none",
            scrollbarWidth:"none"
        }}
        
        >
            {/* Close Button */}
            <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 font-semibold hover:text-gray-800"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

            {/* Modal Title */}
            <h3 className="text-2xl font-semibold text-center text-gray-800 mb-6">
                Notification Details
            </h3>

            {/* Modal Content */}
            <div className="space-y-4">
                <div>
                    <p className="text-gray-700 font-medium">Type:</p>
                    <p className="font-semibold">{selectedNotification.type}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Message:</p>
                    <p className="font-semibold">{selectedNotification.message}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Fee Due Date:</p>
                    <p className="font-semibold">{new Date(selectedNotification.feeRecordId.dueDate).toLocaleDateString()}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Status:</p>
                    <p className="font-semibold">{selectedNotification.status}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Remarks:</p>
                    <p className="font-semibold">{selectedNotification.feeRecordId.remarks}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Total Fee:</p>
                    <p className="font-semibold">₹{selectedNotification.feeRecordId.totalFee}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Paid Amount:</p>
                    <p className="font-semibold">₹{selectedNotification.feeRecordId.paidAmount}</p>
                </div>

                <div>
                    <p className="text-gray-700 font-medium">Due Amount:</p>
                    <p className="font-semibold">₹{selectedNotification.feeRecordId.dueAmount}</p>
                </div>
            </div>

            {/* Modal Footer (optional, can add a close button) */}
            <div className="mt-6 text-center flex w-full justify-center items-center">
                <button
                    onClick={handleCloseModal}
                    className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
)}

        </div>

      
    </div>
  )
}

export default FeesTableComponent
