import React, { useState } from 'react';
import { FaArrowDown, FaTimes } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import CreateResult from '../Core/AdminDashboard/CreateResult';
import { formatDateOfBirth, formatName,formatGuardianInfo } from '../../utils/utilityFunction'

const StudentCard = ({ data, index }) => {
    const location = useLocation();
    const currentRoute = location.pathname.split("/")[2];
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(null);
    const [parent,setParent]=useState(data?.parent || {})

    // Function to toggle visibility
    const toggleCardVisibility = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    // Function to toggle the modal
    const openModal = (Data) => {
        setIsModalOpen(Data)
    };

    const closeModal = () => {
        setIsModalOpen(null)
    }
    return (
        <div className="mb-4">
            <div className="flex flex-col border rounded-lg shadow-md w-full p-4 justify-between items-center">
                <div className="flex w-full items-start gap-1 flex-col space-x-4 h-[130px]">
                    {/* Student Info */}
                    <div className="flex justify-between items-center w-full px-1">
                        <div className="flex gap-3 items-center">
                            <div className="text-lg relative font-bold top-1 left-1 items-center">{index + 1}</div>
                            <img
                                src="https://via.placeholder.com/50"
                                alt="Profile"
                                className="w-[60px] h-[60px] rounded-full"
                            />
                            <p className="font-poppins text-[18px] font-semibold leading-[22.5px] text-left">
                                {formatName(data)}
                            </p>
                        </div>
                        <div className="text-[0.88rem]">
                            <button onClick={() => openModal(data)} className="bg-blue-500 text-white py-2 px-4 rounded-md">
                                Create Result
                            </button>
                            <div>
                                <span className="text-text_Black-75 mx-3 font-semibold">Status:</span>
                                <span className="text-green-400 font-semibold">Active</span>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="h-[2px] mx-10 w-[90%] px-10 bg-gray-400 grid place-content-center"></div>

                    {/* Additional Info */}
                    <div className="flex w-full justify-between px-10">
                        <div className="flex space-x-10 text-[0.8rem] text-gray-500">
                            <div>
                                <p className="font-semibold text-black">
                                    {`${data?.class?.className || 'N/A'} - ${data?.class?.classSection || 'N/A'}`}
                                </p>
                                <p>Class</p>
                            </div>
                            <div className="h-full border-l-2 border-border-50 " />
                            <div>
                                <p className="font-semibold text-black">85% (32 leaves)</p>
                                <p>Attendance</p>
                            </div>
                            <div className="h-full border-l-2 border-border-50 " />
                            <div>
                                <p className="font-semibold text-black"> {formatDateOfBirth(data.dateOfBirth) || "27th July 2006"}</p>
                                <p>Birth Date</p>
                            </div>
                            <div className="h-full border-l-2 border-border-50 " />
                            <div>
                                <p className="font-semibold text-black">{data?.rollNumber || "N/A"}</p>
                                <p>Roll Number</p>
                            </div>
                            <div className="h-full border-l-2 border-border-50 " />
                            <div>
                                <p className="font-semibold text-black">
                                    {formatName(data?.class?.classTeacher)}
                                </p>
                                <p>Class Teacher</p>
                            </div>
                        </div>
                        <div
                            className="p-4 h-fit w-fit bg-border-100 shadow-lg rounded-full cursor-pointer"
                            onClick={() => toggleCardVisibility(index)}
                        >
                            <FaArrowDown />
                        </div>
                    </div>
                </div>

                {/* Expanded Card Section */}
                {expandedIndex === index && (
                    <div className="grid grid-cols-5 justify-center gap-6 items-center text-[10px]">
                        <div className="bg-white shadow-md rounded-lg p-4 col-span-2 h-[170px] space-y-2 flex flex-col justify-between">
                            <h2 className="font-semibold text-center text-[0.77rem]">Guardian Information</h2>
                            {[
                                { label: 'Relation', value: data?.parent?.guardian?.relations },
                                { label: 'Name', value: `${formatGuardianInfo(data?.parent?.guardian, 'firstName')} ${formatGuardianInfo(data?.parent?.guardian, 'middleName')} ${formatGuardianInfo(data?.parent?.guardian, 'lastName')}`.trim() },
                                { label: 'Contact', value: formatGuardianInfo(data?.parent?.guardian, 'mobile') },
                                { label: 'Address', value: formatGuardianInfo(data?.parent?.guardian, 'address') },
                            ].map(({ label, value }, idx) => (
                                <p key={idx}><strong>{label}:</strong> {value}</p>
                            ))}

                        </div>

                        <div className="bg-white shadow-md rounded-lg p-4 col-span-2 h-[170px] space-y-2 flex flex-col justify-between">
                            <h2 className="font-semibold text-center text-[0.77rem]">Parents Information</h2>
                            <p><strong>Father Name:</strong>{`${data.parent.father.firstName} ${data.parent.father.middleName} ${data.parent.father.lastName}`}</p>
                            <p><strong>Mother Name:</strong>{`${data.parent.mother.firstName} ${data.parent.mother.middleName} ${data.parent.mother.lastName}`}</p>
                            <p><strong>Contact Details:</strong>{`${data.parent.father.mobile}`}</p>
                            <p><strong>Email Id:</strong> {`${data.parent.father.email}`}</p>
                        </div>

                        <div className="bg-white rounded-lg p-4 w-[170px] h-[170px] space-y-2 flex flex-col justify-between">
                            <p className="font-semibold text-center">Emergency Number</p>
                            <p>{formatGuardianInfo(data?.parent?.guardian, 'mobile')}</p>
                            <button className="bg-red-500 text-white py-2 px-4 rounded-lg shadow-md">
                                Call now
                            </button>
                        </div>
                    </div>
                )}

                {/* Modal */}
                {isModalOpen !== null && (
                    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex">
                        {/* Sliding modal content */}
                        <div
                            className={`w-[100vw]  h-full bg-white shadow-lg transform transition-transform duration-500 ${isModalOpen ? "translate-x-0" : "translate-x-full"
                                }`}
                        >
                            {/* Modal Header */}
                            <div className="flex justify-between items-center p-4 border-b">
                                <h2 className="text-lg font-bold">Create Result</h2>
                                <button
                                    className="text-red-500 hover:text-red-700"
                                    onClick={closeModal}
                                >
                                    <FaTimes size={20} />
                                </button>
                            </div>

                            {/* Modal Content */}
                            <div className="p-4">
                                <CreateResult setIsModalOpen={setIsModalOpen} studentinfo={isModalOpen} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};



const ResultStudentCardList = ({ subjectList }) => {
    const defaultData = [
        {
            name: { firstName: 'Loki', middleName: 'D.', lastName: 'Doe' },
            _id: 'default1',
            class: {
                className: '8',
                classSection: 'A',
                classTeacher: 'Ms. Unknown',
            },
        },
    ];

    const dataToRender = subjectList.length > 0 ? subjectList : defaultData;

    return (
        <div>
            <h2 className="font-semibold text-[1.1rem] mb-4">Create Student Result</h2>
            {dataToRender.map((data, index) => (
                <StudentCard index={index} key={data._id} data={data} />
            ))}
        </div>
    );
};

export default ResultStudentCardList;
