
import React, { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import Headingtitle from "../../Common/Headingtitle";
import Count_Icon from "../../../Assests/Logos/Count_Icon.png"
import { getSuperAdminStats } from '../../../Services/superAdminApis'

const SuperAdminDashboardComp = () => {
    const [allStats, setAllStats] = useState({})

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getSuperAdminStats();
                if (response.status === 200) {
                    setAllStats(response?.data?.data || {});
                    console.log("response?.data?.data", response?.data?.data);
                }

            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    const DashboardData = [{
        title: "Total Schools",
        count: allStats?.totalSchool || 0
    },

    {
        title: "Active Schools",
        count: allStats?.activeSchool || 0
    },
    {
        title: "InActive Schools",
        count: allStats?.inactiveSchool || 0
    }, {
        title: "This Month",
        count: allStats?.thisMonthAddedSchool || 0
    }]
    return (
        <div className="font-poppins">
            <Headingtitle title="Dashboard" />

            <main className="px-6 py-2">
                <section className="grid w-full grid-cols-12 gap-5">

                    {
                        DashboardData.map((item, index) => (
                            <div key={index}
                                style={{ boxShadow: "6px 6px 54px 0px #0000000D" }}
                                className="col-span-3 font-nunito flex flex-col justify-between h-[8rem] rounded-2xl p-4 bg-white text-text_blue-500  shadow-md">
                                <div className="flex justify-between ">
                                    <p className="text-[1.4rem] text-[#979797]  ">{item.title}</p>
                                    <div style={{ backgroundColor: 'rgba(0, 123, 201, 0.21)' }} className="p-3 rounded-xl">
                                        <img src={Count_Icon} className="w-[1.75rem] h-[2.5rem]" />
                                    </div>

                                </div>
                                <p className="text-[1.5rem] text-text_gray-125 font-semibold">{item.count}</p>
                            </div>
                        ))
                    }

                </section>
            </main>
        </div>
    );
};

export default SuperAdminDashboardComp;

