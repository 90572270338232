import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Headingtitle from '../../Common/Headingtitle';
import { getAllteacherLeavesapplication } from '../../../Services/AdminApis';
import LeaveApplication from '../../../Layout/Models/LeaveApplication'; // Import your modal component

const TeacherleaveList = () => {
    const [selectedLeaveData, setSelectedLeaveData] = useState(null);
    const [leaveApplications, setLeaveApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility

    // Define row styles for different statuses
    const rowStyles = {
        pending: {
            backgroundColor: 'rgb(255, 0, 0)',
            color: 'white',
        },
        approved: {
            backgroundColor: 'rgb(0, 128, 0)',
            color: 'white',
        },
    };

    // Fetch leave applications data
    useEffect(() => {
        const fetchTeacherApplications = async () => {
            try {
                const response = await getAllteacherLeavesapplication();
                const leaveData = response?.data;

                const formattedRows = leaveData.map((leave, index) => ({
                    id: leave._id,
                    teacherName: `${leave.teacherId?.name?.firstName || ''} ${leave.teacherId?.name?.middleName || ''} ${leave.teacherId?.name?.lastName || ''}`.trim() || 'N/A',
                    leaveType: leave.leaveType || 'N/A',
                    leaveReason: leave.reason || 'N/A',
                    startDate: leave.startDate ? new Date(leave.startDate).toLocaleDateString() : 'N/A',
                    endDate: leave.endDate ? new Date(leave.endDate).toLocaleDateString() : 'N/A',
                    status: leave.status || 'Pending',
                    school: leave.school || 'N/A',
                    reviewedBy: leave.reviewedBy || 'Not Reviewed',
                    reviewedDate: leave.reviewedDate ? new Date(leave.reviewedDate).toLocaleDateString() : 'Not Reviewed',
                    appliedDate: leave.appliedDate ? new Date(leave.appliedDate).toLocaleDateString() : 'N/A',
                    createdAt: leave.createdAt ? new Date(leave.createdAt).toLocaleDateString() : 'N/A',
                    updatedAt: leave.updatedAt ? new Date(leave.updatedAt).toLocaleDateString() : 'N/A',
                }));


                setLeaveApplications(formattedRows);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching teacher leave applications:', error);
                setLoading(false);
            }
        };

        fetchTeacherApplications();
    }, [openModal]);

    // Define columns for the DataGrid
    const columns = [
        { field: 'id', headerName: 'No.', width: 70 },
        {
            field: 'teacherName',
            headerName: 'Teacher Name',
            width: 200,
        },
        { field: 'leaveType', headerName: 'Leave Type', width: 150 },
        { field: 'leaveReason', headerName: 'Reason', width: 250 },
        { field: 'startDate', headerName: 'Start Date', width: 150 },
        { field: 'endDate', headerName: 'End Date', width: 150 },
        { field: 'status', headerName: 'Status', width: 130 },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => {
                        setSelectedLeaveData(params.row); // Set selected leave data
                        setOpenModal(true); // Open modal
                    }}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];
    return (
        <>
            <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
                <Headingtitle title="Teacher Leave Applications" />
                <DataGrid
                    style={{ backgroundColor: 'white' }}
                    rows={leaveApplications}
                    columns={columns}
                    loading={loading}
                    pageSize={10}
                    slots={{ toolbar: GridToolbar }}
                    checkboxSelection
                    getRowClassName={(params) =>
                        params.row.status === 'Pending'
                            ? 'row-pending'
                            : params.row.status === 'Approved'
                                ? 'row-approved'
                                : ''
                    }
                    getRowHeight={() => 50} // Optional: Adjust row height
                    getRowStyle={(params) => {
                        if (params.row.status === 'Pending') {
                            return rowStyles.pending;
                        }
                        if (params.row.status === 'Approved') {
                            return rowStyles.approved;
                        }
                        return {};
                    }}
                />
            </div>

            {/* Pass the selected data and open/close modal */}
            {selectedLeaveData != null && openModal && (
                <LeaveApplication
                    selectedData={selectedLeaveData}
                    open={openModal}
                    handleClose={() => setOpenModal(false)} // Close the modal
                />
            )}
        </>
    );
};

export default TeacherleaveList;
