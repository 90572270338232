import React from 'react'
import Headingtitle from './Headingtitle'

const ExamDetails = ({isPending,examResult}) => {
  return (
    <div>
       <section>
            <Headingtitle title="Exam Result" />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[78vh]">
                <section className="p-4 flex flex-col items-center custom-scroll overflow-y-scroll gap-4 bg-white text-[0.86rem] rounded-lg h-[79vh]">
                    {isPending ? (
                        <p>Loading...</p>
                    ) : examResult ? (
                        <div className="w-[90%] flex flex-col space-y-4 font-poppins border-[1px] border-border-100 rounded-lg p-6">
                            {/* Exam Overview */}
                            <div className="flex w-full justify-between">
                                <div>
                                    <p>
                                        <span className="font-semibold">Exam Name:</span>{" "}
                                        {examResult.examName}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Academic Year:</span>{" "}
                                        {examResult.academicYear}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Class:</span>{" "}
                                        {examResult.className}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Class Teacher:</span>{" "}
                                        {`${examResult.classTeacher.firstName} ${examResult.classTeacher.lastName}`}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span className="font-semibold">Percentage:</span>{" "}
                                        {examResult.percentage}%
                                    </p>
                                    <p>
                                        <span className="font-semibold">Grade:</span>{" "}
                                        {examResult.grade}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Status:</span>{" "}
                                        <span
                                            className={`font-semibold ${
                                                examResult.status === "Pass"
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                            }`}
                                        >
                                            {examResult.status}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {/* Subject-wise Results */}
                            <div className="w-full">
                                <table className="w-full text-left border-separate border-spacing-2">
                                    <thead>
                                        <tr className="text-[#000000]">
                                            <th className="font-semibold">Subject</th>
                                            <th className="font-semibold">Obtained Marks</th>
                                            <th className="font-semibold">Total Marks</th>
                                            <th className="font-semibold">Passing Marks</th>
                                            <th className="font-semibold">Grade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examResult.subjectResults.map((subject, index) => (
                                            <tr key={index} className="text-black">
                                                <td>{subject.subjectName}</td>
                                                <td>{subject.obtainedMarks}</td>
                                                <td>{subject.totalMarks}</td>
                                                <td>{subject.passingMarks}</td>
                                                <td
                                                    className={`font-semibold ${
                                                        subject.obtainedMarks < subject.passingMarks
                                                            ? "text-red-500"
                                                            : ""
                                                    }`}
                                                >
                                                    {subject.grade}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Obtained Marks Summary */}
                            <div className="flex justify-end mt-4 mr-10 w-[90%]">
                                <div className="border rounded-lg p-4 shadow-md">
                                    <p className="text-gray-600">
                                        Obtained Marks:{" "}
                                        <span className="font-semibold text-black">
                                            {examResult.obtainedMarks} / {examResult.totalMarks}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No exam result found.</p>
                    )}
                </section>
            </div>
        </section>
 
    </div>
  )
}

export default ExamDetails
