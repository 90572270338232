import React, { useState } from 'react';
import MyStudents from '../../Component/Core/ParentDashboard/MyStudentList';
import Headingtitle from '../../Component/Common/Headingtitle';
import MyclassIdComp from '../../Component/Common/MyClassIdComponet';
import { ApplychildforLeave } from '../../Services/ParentApi';
import toast from 'react-hot-toast';
const ParentLeaveModel = ({ onClose }) => {
  const [formData, setFormData] = useState({
    studentId: '',
    classId: '',
    leaveType: '', // '1st Half', '2nd Half', 'Full Day', 'Other'
    startDate: '',
    endDate: '',
    reason: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    try {
      const response = await ApplychildforLeave(formData);
      if (response?.status === 200 ||201) {
        toast.success("Successfully Applied Leave application")
        onClose()
      } 
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    // Add API call or action here
    onClose(); // Close modal after submission
  };

  return (
    <div>
      <Headingtitle title="Leave Applications" />
      <MyStudents
        onStudentSelect={(data) =>
          setFormData((prev) => ({ ...prev, studentId: data }))
        }
      />
      <MyclassIdComp
        onStudentSelect={(data) =>
          setFormData((prev) => ({ ...prev, classId: data?.classId }))
        }
      />
      <form onSubmit={handleSubmit} className="mt-4 space-y-4">
        {/* Leave Type */}
        <div>
          <label htmlFor="leaveType" className="block font-medium">
            Leave Type
          </label>
          <select
            id="leaveType"
            name="leaveType"
            value={formData.leaveType}
            onChange={handleChange}
            className="block w-full p-2 border rounded"
            required
          >
            <option value="" disabled>
              Select Leave Type
            </option>
            <option value="1st Half">1st Half</option>
            <option value="2nd Half">2nd Half</option>
            <option value="Full Day">Full Day</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Start Date */}
        <div>
          <label htmlFor="startDate" className="block font-medium">
            Start Date
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="block w-full p-2 border rounded"
            required
          />
        </div>

        {/* End Date */}
        <div>
          <label htmlFor="endDate" className="block font-medium">
            End Date
          </label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="block w-full p-2 border rounded"
          />
        </div>

        {/* Reason */}
        <div>
          <label htmlFor="reason" className="block font-medium">
            Reason
          </label>
          <textarea
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            className="block w-full p-2 border rounded"
            rows="3"
            required
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Submit Leave Application
        </button>
      </form>
    </div>
  );
};

export default ParentLeaveModel;
