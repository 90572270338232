import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Headingtitle from './Headingtitle';
import MyStudents from '../Core/ParentDashboard/MyStudentList';

const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const StudentAttendanceListComp = ({
    fetchAttendance,
    headingTitle,
    isParentSection
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [attendanceList, setAttendanceList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const year = parseInt(searchParams.get('year')) || new Date().getFullYear();
    const month = parseInt(searchParams.get('month')) || new Date().getMonth() + 1;
    const studentId = searchParams.get('studentId');

    const updateQueryParams = (updatedParams) => {
        setSearchParams((prev) => {
            const newParams = { ...Object.fromEntries(prev.entries()), ...updatedParams };
            return new URLSearchParams(newParams);
        });
    };

    const handleDateChange = (date) => {
        const newYear = date.getFullYear();
        const newMonth = date.getMonth() + 1;
        updateQueryParams({ year: newYear, month: newMonth });
    };
    const handlePrevMonth = () => {
        const prevDate = new Date(year, month - 2);
        handleDateChange(prevDate);
    };

    const handleNextMonth = () => {
        const nextDate = new Date(year, month);
        handleDateChange(nextDate);
    };

    const fetchAttendanceData = async (year, month) => {
        try {
            setIsLoading(true);

            if (isParentSection && !studentId) {
                return; 
            }
    
            const response = isParentSection 
                ? await fetchAttendance(studentId, month, year) 
                : await fetchAttendance(month, year);
    
            setAttendanceList(response?.data?.attendanceRecords || []);
        } catch (error) {
            console.error('Error fetching attendance:', error);
            setAttendanceList([]);
        } finally {
            setIsLoading(false);
        }
    };
    

    // Sync query params with selectedDate and fetch data
    useEffect(() => {
        const year = searchParams.get('year') || new Date().getFullYear();
        const month = searchParams.get('month') || new Date().getMonth() + 1;
        setSelectedDate(new Date(year, month - 1));
        fetchAttendanceData(year, month);
    }, [searchParams]);

    const handleStudentSelect = (id) => {
        updateQueryParams({ studentId: id });
    };

    return (
        <div className="w-full p-6">
            <Headingtitle title={headingTitle} />
            {isParentSection && <MyStudents onStudentSelect={handleStudentSelect} />}

            <section className="flex flex-row-reverse justify-evenly items-start bg-white p-6 shadow-sm rounded-md">

                <div className="flex justify-center items-center mb-6">
                    <div className="flex items-center w-[355px] space-x-2 border-[1px] border-gray-300 rounded-lg px-3 py-2 shadow-md">
                        <button
                            onClick={handlePrevMonth}
                            className="bg-blue-500 text-white p-2 rounded-md"
                        >
                            <FaArrowLeft />
                        </button>
                        <div className="text-[0.88rem] font-semibold w-[280px] text-center">
                            {selectedDate.toLocaleString('default', { month: 'long' })}{' '}
                            {selectedDate.getFullYear()}
                        </div>
                        <button
                            onClick={handleNextMonth}
                            className="bg-blue-500 text-white p-2 rounded-md"
                        >
                            <FaArrowRight />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <div className="w-60 h-60 flex flex-col items-center">
                        <div className="flex justify-between w-full">
                            {Days.map((day, index) => (
                                <p
                                    key={index}
                                    className="text-sm font-medium text-gray-500 text-center w-full"
                                >
                                    {day.toUpperCase()}
                                </p>
                            ))}
                        </div>

                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                                updateQueryParams(date);
                            }}
                            inline
                            className="mt-2"
                            renderCustomHeader={() => null}
                            showPopperArrow={false}
                        />
                    </div>
                </div>
            </section>

            {/* Attendance Table */}
            <div className="w-full mt-8">
                <h2 className="text-lg font-medium text-gray-700 mb-4 text-center">
                    Attendance Summary for{' '}
                    {selectedDate.toLocaleString('default', { month: 'long' })}{' '}
                    {selectedDate.getFullYear()}
                </h2>

                {isLoading ? (
                    <p className="text-center text-gray-500">Loading...</p>
                ) : attendanceList ? (
                    <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-md overflow-hidden">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">
                                    Date
                                </th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceList.map((entry, index) => (
                                <tr
                                    key={index}
                                    className={`${entry.status === 'Absent'
                                        ? 'bg-red-50'
                                        : 'bg-green-50 hover:bg-green-100'
                                        }`}
                                >
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {new Date(entry.date).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {entry.status}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500 font-medium">
                        No attendance data available.
                    </p>
                )}
            </div>
        </div>
    );
};

export default StudentAttendanceListComp;
