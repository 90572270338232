import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, MenuItem } from "@mui/material";
import Headingtitle from "../../Common/Headingtitle";
import {
    GetClassCategoryList,
    GetClassSectionList,
    GetAllSubject,
    CreateNewSubjectWithChapter
} from "../../../Services/AdminApis";
import toast from "react-hot-toast";

const OnboardSubjectChapter = () => {
    const [classList, setClassList] = useState([]);
    const [allSectionList, setAllSectionList] = useState([]);
    const [subjectNameList, setSubjectNameList] = useState([]);
    const [formData, setFormData] = useState({
        subjectName: "",
        className: "",
        sections: [],
        isElective: false,
    });

    const [section, setSection] = useState([
        {
            chapterNumber: null,
            title: "",
            description: "",
            objectives: "",
            resources: [],
        },
    ]);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await GetClassCategoryList();
                const sectionRes = await GetClassSectionList();

                setClassList(response?.data?.classCategories || []);
                const allSections = [{ classSection: "All" }, ...sectionRes?.data?.sections || []];
                setAllSectionList(allSections);
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const SubjectNameRes = await GetAllSubject();
                setSubjectNameList(SubjectNameRes?.data?.subjects || []);
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);


    const handleSectionChange = (event, value) => {
        const selectedSections = value.map((item) => item.classSection);
        setFormData((prev) => ({ ...prev, sections: selectedSections }));
    };

    const handleFormDataChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleChapterChange = (index, e) => {
        const { name, value } = e.target;
        const updatedSections = [...section];
        updatedSections[index] = {
            ...updatedSections[index],
            [name]:  value,
        };
        setSection(updatedSections);
    };

    const AddChapterSection = () => {
        setSection((prev) => [
            ...prev,
            {
                chapterNumber: null,
                title: "",
                description: "",
                objectives: "",
                resources: [],
            },
        ]);
    };

    const removeChapterSection = (index) => {
        setSection((prev) => prev.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const isFormDataValid =
            formData.subjectName &&
            formData.className &&
            formData.sections.length > 0;
        const areChaptersValid = section.every(
            (chapter) =>
                chapter.chapterNumber &&
                chapter.title &&
                chapter.description &&
                chapter.objectives
        );
        setFormValid(isFormDataValid && areChaptersValid && section.length > 0);
    }, [formData, section]);

    const handleSubmit = async () => {
        const payload = {
            ...formData,
            chapters: section.map((sec) => ({
                chapterNumber: sec.chapterNumber,
                title: sec.title,
                description: sec.description,
                objectives: sec.objectives,
                resources: sec.resources,
            })),
        };

        try {
            const response = await CreateNewSubjectWithChapter(payload);
            if (response?.status === 201 || 200) {
                toast.success("SuccessFully Created Chapter and Subject")
                // Reset formData and section to their initial states
                setFormData({
                    subjectName: "",
                    className: "",
                    sections: [],
                    isElective: false,
                });

                setSection([
                    {
                        chapterNumber: null,
                        title: "",
                        description: "",
                        objectives: "",
                        resources: [],
                    },
                ]);
            }
        } catch (error) {
            toast.error(error?.response?.data.message);
        }
        console.log(payload);
        // Make API call or perform other actions
    };

    return (
        <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">
            <Headingtitle title="Create New Subject With Chapter" />
            <section className="min-w-full rounded-lg min-h-[80vh] font-poppins">
                <form>
                    {/* Dynamic inputs for formData */}
                    <div className="bg-white p-6 grid grid-cols-2 gap-5">
                        <p className="text-[1rem] font-semibold pb-2 col-span-2">
                            Create Subject
                        </p>
                        <div className="col-span-1">
                            <label
                                htmlFor="className"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Class Name
                            </label>
                            <TextField
                                select
                                fullWidth
                                name="className"
                                value={formData.className}
                                onChange={handleFormDataChange}
                            >
                                {classList.map((item) => (
                                    <MenuItem
                                        key={item._id}
                                        value={item.className}
                                    >
                                        {item.className}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-span-1">
                            <label
                                htmlFor="sections"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Sections
                            </label>
                            <Autocomplete
                                multiple
                                options={allSectionList}
                                getOptionLabel={(option) =>
                                    option.classSection
                                }
                                onChange={handleSectionChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Sections"
                                    />
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <label
                                htmlFor="subjectName"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            > Subject Name
                            </label>
                            <TextField
                                select
                                fullWidth
                                name="subjectName"
                                value={formData.subjectName}
                                onChange={handleFormDataChange}
                            >
                                {subjectNameList.map((item) => (
                                    <MenuItem
                                        key={item._id}
                                        value={item.subjectName}
                                    >
                                        {item.subjectName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>


                        <div className="col-span-1">
                            <label
                                htmlFor="isElective"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Is Elective
                            </label>
                            <input
                                type="checkbox"
                                name="isElective"
                                placeholder="IsElective"
                                checked={formData.isElective}
                                onChange={handleFormDataChange}
                                className="mt-2"
                            />
                        </div>
                    </div>
                    <div className="bg-white p-6">
                        <p className="text-[1rem] font-semibold pb-2">
                            Create Chapters
                        </p>
                        <section>
                            {section.map((sec, index) => (
                                <div
                                    key={index}
                                    className="border p-4 rounded-lg"
                                >
                                    <div className="flex justify-between items-center">
                                        <p className="font-semibold mb-2">
                                            Chapter {index + 1}
                                        </p>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                removeChapterSection(index)
                                            }
                                            className="text-red-500 hover:underline text-sm"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    {Object.keys(sec).map((key) => (
                                        <div key={key} className="mb-3">
                                            <label
                                                htmlFor={`${key}-${index}`}
                                                className="block text-sm font-medium text-gray-700 capitalize"
                                            >
                                                {key}
                                            </label>
                                            <input
                                                type="text"
                                                name={key}
                                                value={sec[key] || ""} // Ensure default value is an empty string if undefined
                                                onChange={(e) => handleChapterChange(index, e)}
                                                className="w-full border border-gray-300 rounded-md px-3 py-2"
                                            />
                                        </div>
                                    ))}

                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={AddChapterSection}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            >
                                Add Chapter
                            </button>
                        </section>
                    </div>

                    {/* Submit Button */}
                    <div className="bg-white p-6 w-full">
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className={`py-2 w-full rounded-md ${formValid
                                ? "bg-green-500 text-white"
                                : "bg-gray-400 text-gray-700 cursor-not-allowed"
                                }`}
                            disabled={!formValid}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default OnboardSubjectChapter;
