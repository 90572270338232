import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { getAcademicsYears, CreateStudentResult, getAllSubjectListData } from "../../../Services/TeachersApis";
import toast from "react-hot-toast";
const CreateResult = ({ setIsModalOpen, studentinfo }) => {
    const [yearList, setYearList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [studentData, setStudentData] = useState({
        studentName: "",
        studentClass: "",
        academicYear: "",
        examName: "",
        totalMarks: 0, 
        passingMarks: 0,
        obtainedMarks: 0,
        percentage: 0,
        grade: "",
        status: "",
        subjectResults: [], // Store the subjects here
    });

    const [subject, setSubject] = useState({
        subjectName: "",
        totalMarks: "",
        obtainedMarks: "",
        passingMarks: "",
        grade: "",
    });

    useEffect(() => {
        const fetchYearList = async () => {
            try {
                const academicResponse = await getAcademicsYears();
                const subjectResponse = await getAllSubjectListData()
                // console.log("responsei for year List", response?.data)
                if (academicResponse?.status === 200) {
                    setYearList(academicResponse?.data || []);
                }
                if (subjectResponse?.status === 200) {
                    setSubjectList(subjectResponse?.data?.subjects || []);
                }
            }
            catch (error) {
                console.log("error", error);
            }

        }
        fetchYearList();
    }, [])

    useEffect(() => {
        if (studentinfo) {
            setStudentData({
                studentName: `${studentinfo?.name?.firstName} ${studentinfo?.name?.lastName}`,
                studentClass: studentinfo?.class?.className || "", // Example: studentinfo.class
                academicYear: studentinfo?.academicYear || "", // Example: studentinfo.academicYear
                examName: "", // You can set this as a default or leave it blank
                totalMarks: 0,
                passingMarks: 0,
                obtainedMarks: 0,
                percentage: 0,
                grade: "",
                status: "",
                subjectResults: [], // Store subjects here
            });
        }
    }, [studentinfo]); // Dependency on studentinfo, so it runs when it changes




    // Handle student data changes
    const handleStudentChange = (e) => {
        const { name, value } = e.target;
        setStudentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle changes to individual subjects
    const handleSubjectChange = (index, e) => {
        const { name, value } = e.target;

        setStudentData((prevData) => ({
            ...prevData,
            subjectResults: prevData.subjectResults.map((sub, i) =>
                i === index ? { ...sub, [name]: value } : sub
            ),
        }));
    };

    // Add a new subject
    const handleAddSubject = () => {
        setStudentData((prevData) => ({
            ...prevData,
            subjectResults: [...prevData.subjectResults, subject],
        }));

        // Reset the subject form
        setSubject({
            subjectName: "",
            totalMarks: "",
            obtainedMarks: "",
            passingMarks: "",
            grade: "",
        });
    };

    // Remove a subject
    const handleRemoveSubject = (index) => {
        setStudentData((prevData) => ({
            ...prevData,
            subjectResults: prevData.subjectResults.filter((_, i) => i !== index),
        }));
    };

    const checkFields = () => {
        let isEmpty = false; // Flag to track if any field is empty

        // Convert `studentData` object to an array of key-value pairs and loop through them
        Object.entries(studentData).forEach(([key, value]) => {
            // Check if value is empty or invalid
            if (
                (typeof value === "string" && value.trim() === "") || // Empty strings
                (typeof value === "number" && value === 0) || // Default number values
                (Array.isArray(value) && value.length === 0) // Empty arrays
            ) {
                console.log(`${key} is empty`); // Log which field is empty
                isEmpty = true; // Mark as empty
            }
        });

        return isEmpty; // Return the result
    };
    // Validate before form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Example usage:
        if (checkFields()) {
            toast.error("Some fields are empty. Please fill them in");
        }

        if (studentData.subjectResults.length < 1) {
            toast.success("At least one subject is required.");
            return;
        }

        const payload = {
            studentId: studentinfo._id,
            studentName: studentData.studentName,
            studentClass: studentData.studentClass,
            examName: studentData.examName,
            totalMarks: studentData.totalMarks,
            passingMarks: studentData.passingMarks,
            obtainedMarks: studentData.obtainedMarks,
            percentage: studentData.percentage,
            academicYear: studentData.academicYear,
            grade: studentData.grade,
            status: studentData.status,
            subjectResults: studentData.subjectResults.map((sub) => ({
                subjectName: sub.subjectName,
                totalMarks: sub.totalMarks,
                obtainedMarks: sub.obtainedMarks,
                passingMarks: sub.passingMarks,
                grade: sub.grade,
            })),
        };
        console.log(payload)

        try {
            const response = await CreateStudentResult(payload);
            if (response?.status === 200 || 201) {
                toast.success("Successfully Created Result ");
                resetForm();
                setIsModalOpen(null);
            }
            else {
                console.errro("error", response?.message);
            }
        }
        catch (error) {
            toast.error("Something went wrong!")


        }


        // Process the data (e.g., send to an API)
        console.log("Form submitted with data:", studentData);
    };

    const resetForm = () => {
        setStudentData({
            studentName: "",
            studentClass: "",
            academicYear: "",
            examName: "",
            totalMarks: 0,
            passingMarks: 0,
            obtainedMarks: 0,
            percentage: 0,
            grade: "",
            status: "",
            subjectResults: [],
        })
        setSubject({
            subjectName: "",
            totalMarks: "",
            obtainedMarks: "",
            passingMarks: "",
            grade: "",
        })
    }
    return (
        <div className="font-poppins">
            <Headingtitle title="Create Result" />
            <main className="p-5 overflow-y-scroll max-h-[80vh]">
                <p className="font-semibold pb-2 text-[1rem]">Exam Result</p>

                <div className="grid w-full bg-white p-4 shadow-lg rounded-md grid-cols-2 gap-5 text-[0.77rem]">
                    {Object.keys(studentData).map((key, index) => {
                        if (key === "subjectResults") return null; // Skip the subjectResults field here

                        const fieldType = key === "percentage" || key.includes("Marks") ? "number" : key === "grade" || key === "status" ? "select" : "text";

                        return (
                            <div key={index} className="flex flex-col">
                                <label className="font-semibold capitalize mb-1" htmlFor={key}>
                                    {key.replace(/([A-Z])/g, " $1")}
                                </label>
                                {fieldType === "select" ? (
                                    <select
                                        id={key}
                                        name={key}
                                        value={studentData[key]}
                                        onChange={handleStudentChange}
                                        className="border rounded-md p-2 text-sm"
                                    >
                                        {key === "grade" ? (
                                            <>
                                                <option value="">Select Grade</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="F">F</option>
                                            </>
                                        ) : (
                                            <>
                                                <option value="">Select Status</option>
                                                <option value="Pass">Passed</option>
                                                <option value="Failed">Failed</option>
                                                <option value="Pass With Grace">Pass With Grace</option>
                                            </>
                                        )}
                                    </select>
                                ) :

                                    (key === "academicYear" ?
                                        (

                                            <div>

                                                <select
                                                    onChange={handleStudentChange}
                                                    className="border rounded-md p-2 text-sm"
                                                    id="academicYear" name="academicYear">
                                                    <option value="">--Select Academic Year--</option>
                                                    {yearList?.map((y) => (
                                                        <option key={y._id} value={y.year}>
                                                            {y.year}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        ) :
                                        (
                                            <input
                                                type={fieldType}
                                                id={key}
                                                name={key}
                                                value={studentData[key]}
                                                onChange={handleStudentChange}
                                                className="border rounded-md p-2 text-sm"
                                                placeholder={`Enter ${key}`}
                                            />
                                        )
                                    )

                                }
                            </div>
                        );
                    })}
                </div>

                <div className="my-5 bg-white shadow-md p-4 rounded-md ">
                    <p className="font-semibold pb-2 text-[1rem]">Subjects</p>
                    {studentData.subjectResults?.map((subject, index) => (
                        <div key={index} className="border p-4 rounded-md mb-4">
                            <div className="grid grid-cols-2 gap-4">
                                {/* <div>
                                    <label className="font-semibold mb-1 block">Subject Name</label>
                                    <input
                                        type="text"
                                        name="subjectName"
                                        value={subject.subjectName}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="border rounded-md p-2 w-full"
                                        placeholder="Enter Subject Name"
                                    />
                                </div> */}
                                <div >
                                    <label className="font-semibold mb-1 block">Subject Name</label>
                                    <select
                                        name={"subjectName"}
                                        value={subject.subjectName}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="w-full border border-gray-300 rounded-md px-3 py-[12px]"
                                    >
                                        <option value="">Select Subject</option>
                                        {subjectList.map((item, index) => (
                                            <option key={item._id} value={item.subjectName}>{item.subjectName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="font-semibold mb-1 block">Total Marks</label>
                                    <input
                                        type="number"
                                        name="totalMarks"
                                        value={subject.totalMarks}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="border rounded-md p-2 w-full"
                                        placeholder="Enter Total Marks"
                                    />
                                </div>
                                <div>
                                    <label className="font-semibold mb-1 block">Obtained Marks</label>
                                    <input
                                        type="number"
                                        name="obtainedMarks"
                                        value={subject.obtainedMarks}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="border rounded-md p-2 w-full"
                                        placeholder="Enter Obtained Marks"
                                    />
                                </div>
                                <div>
                                    <label className="font-semibold mb-1 block">Passing Marks</label>
                                    <input
                                        type="number"
                                        name="passingMarks"
                                        value={subject.passingMarks}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="border rounded-md p-2 w-full"
                                        placeholder="Enter Passing Marks"
                                    />
                                </div>
                                <div>
                                    <label className="font-semibold mb-1 block">Grade</label>
                                    <select
                                        name="grade"
                                        value={subject.grade}
                                        onChange={(e) => handleSubjectChange(index, e)}
                                        className="border rounded-md p-2 w-full"
                                    >
                                        <option value="">Select Grade</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="F">F</option>
                                    </select>
                                </div>
                            </div>

                            {/* Remove Subject Button */}
                            {studentData.subjectResults.length > 1 && (
                                <button
                                    className="text-red-500 hover:text-red-700 mt-3"
                                    onClick={() => handleRemoveSubject(index)}
                                >
                                    Remove Subject
                                </button>
                            )}
                        </div>
                    ))}

                    {/* Add Subject Button */}
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                        onClick={handleAddSubject}
                    >
                        Add Subject
                    </button>
                </div>

                {/* Submit Button */}
                <div className="mt-5 w-full">
                    <button
                        className="bg-green-500 text-white px-4 py-2 w-full rounded-md hover:bg-green-600"
                        onClick={handleSubmit}
                    >
                        Submit Result
                    </button>
                </div>
            </main>
        </div>

    );
};

export default CreateResult;
