import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import { GetOnboardedTeacherList } from '../../../Services/AdminApis';

const columns = [
  { field: 'id', headerName: 'No.', width: 70 },
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'gender', headerName: 'Gender', width: 100 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'mobile', headerName: 'Mobile', width: 150 },
  {
    field: 'image',
    headerName: 'Image',
    width: 100,
    renderCell: (params) => (
      <Avatar src={params.value} alt="avatar" />
    ),
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    renderCell: () => (
      <IconButton color="primary">
        <EditIcon />
      </IconButton>
    ),
  },
];



export default function GuradiansList() {

  // Fetch data from the API
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await GetOnboardedTeacherList();
        const teacherData = response.data.teachers;

        // Transform API data into the format required by DataGrid rows
        const formattedRows = teacherData.map((teacher, index) => ({
          id: index + 1,
          firstName: teacher.name.firstName,
          lastName: teacher.name.lastName,
          gender: 'N/A', // Add gender if available in API.
          email: teacher.email,
          mobile: teacher.contactInfo.phone,
          image: teacher.avatar,
        }));

        setRows(formattedRows);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching teachers:', error);
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  return (
    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
      <Headingtitle title="Guradians/Parents" />
      <DataGrid
        style={{ backgroundColor: "white", }}
        rows={rows}
        columns={columns}
        pageSize={10}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
      />
    </div>
  );
}
