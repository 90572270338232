import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Container } from '@mui/material';

const BarChart = ({ data }) => {
    const svgRef = useRef();

    useEffect(() => {
        const container = svgRef.current.parentNode;
        const width = container.offsetWidth;
        const height = container.offsetHeight;
        const margin = { top: 20, right: 20, bottom: 40, left: 40 };

        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height)
            .style("overflow", "visible");

        // Clear previous content
        svg.selectAll("*").remove();

        const xScale = d3.scaleBand()
            .domain(data.map(d => d.label))
            .range([margin.left, width - margin.right])
            .padding(0.4);

        const yScale = d3.scaleLinear()
            .domain([0, 100])
            .nice()
            .range([height - margin.bottom, margin.top]);

        // Draw bars
        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => xScale(d.label))
            .attr("y", d => yScale(d.value))
            .attr("width", xScale.bandwidth())
            .attr("height", d => height - margin.bottom - yScale(d.value))
            .attr("fill", "#3399FF")
            .attr("rx", 6); // Rounded corners for bars

        // Add percentage labels on top of each bar
        svg.selectAll(".label")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("x", d => xScale(d.label) + xScale.bandwidth() / 2)
            .attr("y", d => yScale(d.value) - 5)
            .attr("text-anchor", "middle")
            .text(d => `${d.value}%`)
            .attr("fill", "#333")
            .attr("font-size", "14px")
            .style("font-weight", "bold");

        // Add X axis
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(xScale))
            .selectAll("text")
            .attr("font-size", "12px")
            .attr("fill", "#888");

        // Add Y axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => `${d}%`))
            .selectAll("text")
            .attr("font-size", "12px")
            .attr("fill", "#888");

    }, [data]);

    return <svg ref={svgRef}></svg>;
};

export default BarChart;
