import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { studentAttendanceListById } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";

const StudentAttendanceDetail = () => {
    const { studentId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const [studentAttendanceDetails, setStudentAttendanceDetails] = useState();

    // Extract month and year from query parameters or use default values
    const currentDate = new Date();
    const defaultMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    const defaultYear = currentDate.getFullYear();

    const [month, setMonth] = useState(searchParams.get("month") || defaultMonth);
    const [year, setYear] = useState(searchParams.get("year") || defaultYear);

    useEffect(() => {
        const fetchStudentAttendanceById = async () => {
            setLoading(true);
            try {
                const response = await studentAttendanceListById(studentId, month, year);

                // Check if the response is successful
                if (response.status === 200) {
                    setStudentAttendanceDetails(response?.data?.attendanceRecords || []);
                } else {
                    // Handle error responses with a status other than 200
                    console.log("Error:", response?.data?.message);
                    setStudentAttendanceDetails([]);
                }
            } catch (error) {
                // General error handling
                console.error("Error fetching attendance data:", error);
                setStudentAttendanceDetails([]);
            }
            setLoading(false);
        };

        fetchStudentAttendanceById();
    }, [studentId, month, year]);

    // Handle month change
    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setMonth(selectedMonth);
        setSearchParams({ month: selectedMonth, year }); // Update query parameters
    };

    // Handle year change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
        setSearchParams({ month, year: selectedYear }); // Update query parameters
    };

    return (
        <div className="font-poppins">
            <Headingtitle title="Attendance" />

            <main className="bg-white min-h-[80vh] rounded-lg p-6 shadow-lg">
                <p className="text-[1.2rem] font-semibold pb-4 text-gray-800">Student Attendance Details</p>
                <section className="grid grid-cols-12 gap-6 p-4">
                    {/* Month Selector */}
                    <div className="col-span-6">
                        <label htmlFor="month" className="block text-[14px] font-medium text-gray-700 pb-2">
                            Select Month:
                        </label>
                        <select
                            id="month"
                            value={month}
                            onChange={handleMonthChange}
                            className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[14px] text-gray-700 focus:ring-2 focus:ring-indigo-400 focus:outline-none shadow-sm"
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((monthNumber) => (
                                <option key={monthNumber} value={monthNumber}>
                                    {new Date(0, monthNumber - 1).toLocaleString("default", { month: "long" })}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Year Selector */}
                    <div className="col-span-6">
                        <label htmlFor="year" className="block text-[14px] font-medium text-gray-700 pb-2">
                            Select Year:
                        </label>
                        <select
                            id="year"
                            value={year}
                            onChange={handleYearChange}
                            className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[14px] text-gray-700 focus:ring-2 focus:ring-indigo-400 focus:outline-none shadow-sm"
                        >
                            {Array.from({ length: 10 }, (_, i) => (
                                <option key={i} value={defaultYear - i}>
                                    {defaultYear - i}
                                </option>
                            ))}
                        </select>
                    </div>
                </section>

                {/* Attendance Details Table */}
                <div>
                    {loading ? (
                        <p className="text-gray-500">Loading...</p>
                    ) : studentAttendanceDetails && studentAttendanceDetails.length > 0 ? (
                        <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                            <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                <tr>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Class</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Section</th>
                                    <th className="px-6 py-4 text-left text-[16px] font-bold">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentAttendanceDetails.map((record, index) => (
                                    <tr
                                        key={record._id}
                                        className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                            } hover:bg-indigo-100 transition-all`}
                                    >
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">
                                            {new Date(record.date).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">
                                            {record.class.className}
                                        </td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">
                                            {record.class.classSection}
                                        </td>
                                        <td className="px-6 py-4 text-gray-700 text-[15px]">{record.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-gray-500 mt-6">No Attendance Records Found</p>
                    )}
                </div>
            </main>

        </div>
    );
};

export default StudentAttendanceDetail;
