import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getAllSubmittedAssignment } from "../../../Services/TeachersApis";
import Headingtitle from "../../Common/Headingtitle";

const SubmittedAssignment = () => {
    const [allSubmittedAssignment, setAllSubmittedAssignment] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSubmittedAssignment = async () => {
            setLoading(true);
            try {
                const response = await getAllSubmittedAssignment();
                setAllSubmittedAssignment(response?.data?.assignments || []);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchSubmittedAssignment();
    }, []);

    // Flatten the data for DataGrid
    const rows = allSubmittedAssignment.flatMap((assignment) =>
        assignment.submissions.map((submission) => ({
            id: submission._id, // Use a unique identifier
            title: assignment.title,
            subject: assignment.subject,
            dueDate: new Date(assignment.dueDate).toLocaleDateString(),
            dueTime: assignment.dueTime,
            studentName: `${submission.student.name.firstName} ${submission.student.name.middleName || ""} ${submission.student.name.lastName}`,
            studentId: submission.student.studentId,
            submittedDate: new Date(submission.submittedDate).toLocaleDateString(),
            remarks: submission.remarks,
            status: submission.status,
            submittedFiles: submission.submittedFiles[0], // Assuming only 1 file
        }))
    );

    // Define columns for DataGrid
    const columns = [
        { field: "title", headerName: "Assignment Title", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "subject", headerName: "Subject", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "dueDate", headerName: "Due Date", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "dueTime", headerName: "Due Time", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "studentName", headerName: "Student Name", flex: 1.5, headerClassName: "bg-gray-100 font-semibold" },
        { field: "studentId", headerName: "Student ID", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "submittedDate", headerName: "Submitted Date", flex: 1, headerClassName: "bg-gray-100 font-semibold" },
        { field: "remarks", headerName: "Remarks", flex: 2, headerClassName: "bg-gray-100 font-semibold" },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            headerClassName: "bg-gray-100 font-semibold",
            renderCell: (params) => (
                <span
                    className={`px-2 py-1 rounded text-sm font-medium ${params.value === "Submitted"
                        ? "bg-green-100 text-green-800"
                        : "bg-gray-200 text-gray-800"
                        }`}
                >
                    {params.value}
                </span>
            ),
        },
        {
            field: "submittedFiles",
            headerName: "File",
            flex: 1,
            headerClassName: "bg-gray-100 font-semibold",
            renderCell: (params) => (
                <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                >
                    View File
                </a>
            ),
        },
    ];

    return (
        <div className="font-poppins ">
            <Headingtitle title="Submitted Assignments List" />
            <main className="bg-white shadow-lg rounded-lg p-6">
                <div style={{ height: 600, width: "100%" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        loading={loading}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        // disableSelectionOnClick
                        className="bg-white"
                        sx={{
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#f3f4f6",
                                color: "#374151",
                                fontSize: "0.875rem",
                                fontWeight: 600,
                                textTransform: "uppercase",
                                
                            },
                            "& .MuiDataGrid-cell": {
                                fontSize: "0.875rem",
                                color: "#374151",
                                fontFamily: "Poppins, sans-serif",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: "#f9fafb",
                            },
                        }}
                    />
                </div>
            </main>
        </div>
    );
};

export default SubmittedAssignment;
