import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Stack,
    CardContent,
    Grid,
    CircularProgress,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { getReviewteacherLeavesapplication,getAllteacherLeavesapplication } from '../../Services/AdminApis';
import toast from 'react-hot-toast';

const LeaveApplication = ({ selectedData, open, handleClose }) => {
    const [action, setAction] = useState(""); // Current action (Reject or Approve)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // Confirmation modal visibility
    const [loading, setLoading] = useState(false); // Loading during API call
    const [reviewedBy, setReviewedBy] = useState(''); // Reviewed by input field
    const user = useSelector((state) => state.auth); // Fetch user data from the Redux store

    // Handle click on Reject or Approve    
    const handleClickModel = (actionType) => {
        setAction(actionType);
        setIsConfirmationOpen(true);
    };

    // Confirm action (Approve/Reject)
    const handleConfirmAction = async () => {
        setLoading(true);
        try {
            const payload = {
                reviewedBy: reviewedBy,
                status: action === "Reject" ? "Rejected" : "Approved",
            };
            const response = await getReviewteacherLeavesapplication(selectedData.id, payload);
            if (response?.status) {
                toast.success(`Successfully ${action === "Reject" ? "Rejected" : "Approved"} `);
            }
            setLoading(false);
            setIsConfirmationOpen(false); // Close confirmation modal
            getAllteacherLeavesapplication();
            handleClose(); // Close parent modal

        } catch (error) {
            console.error('Error updating leave status:', error);
            setLoading(false);
        }
    };

    // Cancel action
    const handleCancelAction = () => {
        setIsConfirmationOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="leave-application-modal"
            aria-describedby="view-leave-application-details"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "60vw",
                    bgcolor: 'background.paper',
                    borderRadius: "10px",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                {/* Close Icon */}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1,
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h5" sx={{ mb: 3, fontWeight: 600, textAlign: 'center' }}>
                    Leave Application Details
                </Typography>

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>Teacher Name:</strong> {selectedData?.teacherName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>Leave Type:</strong> {selectedData?.leaveType}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>Start Date:</strong> {selectedData?.startDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>End Date:</strong> {selectedData?.endDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>Status:</strong> {selectedData?.status}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1">
                                <strong>Reason:</strong> {selectedData?.leaveReason}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <Stack direction="row" justifyContent="space-around" sx={{ mt: 3 }}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClickModel('Reject')}
                    >
                        Reject
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleClickModel('Approve')}
                    >
                        Approve
                    </Button>
                </Stack>

                {/* Confirmation Modal */}
                {isConfirmationOpen && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'white',
                            padding: '20px',
                            boxShadow: 24,
                            borderRadius: '8px',
                            width: '300px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Are you sure you want to {action} this leave application?
                        </Typography>
                        <input
                            type="text"
                            value={reviewedBy}
                            onChange={(e) => setReviewedBy(e.target.value)}
                            className="w-full h-[40px] my-5 border-[2px] rounded-lg shadow-sm px-3 outline-none"
                            placeholder="Enter reviewed by name"
                        />
                        <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleConfirmAction}
                                disabled={loading || !reviewedBy.trim()} // Disable if loading or reviewedBy is empty
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCancelAction}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default LeaveApplication;
