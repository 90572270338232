import React, { useState, useEffect } from 'react';
import Headingtitle from '../../Common/Headingtitle'; 
import {
    getAllClassCategoryList,
    searchStudentResultCreated
} from '../../../Services/TeachersApis';
import StudentTableCard from '../../Common/StudentTableCard';

const SearchCreatedStudentResultList = () => {
    const [classCategoryList, setClassCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [studentResultList, setStudentResultList] = useState([]);
    const [error, setError] = useState(null);
    const [examName, setExamName] = useState();

    const fetchMyClassData = async () => {
        try {
            const classCategoryResponse = await getAllClassCategoryList();

            if (classCategoryResponse.status === 200) {
                setClassCategoryList(classCategoryResponse?.data?.classCategories || []);
            }
        } catch (error) {
            setError('Failed to fetch class or section data.');
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedCategory && examName) {
                try {
                    const response = await searchStudentResultCreated(examName, selectedCategory);
                    if (response.status === 200) {
                        setStudentResultList(response.data.allResults || []);
                        console.log("response.data",response.data)
                    }
                } catch (error) {
                    setError('Error fetching subject list.');
                    console.error(error);
                }
            }
        };

        fetchData();
    }, [examName, selectedCategory]);

    useEffect(() => {
        fetchMyClassData();
    }, []);

    return (
        <div className="font-poppins">
            <Headingtitle title="Students" />
            <main className="bg-white rounded-md p-6 text-gray-800 relative min-h-[80vh] shadow-lg">
                <p className="font-semibold text-[1.25rem] pb-4 border-b border-gray-300">
                    Create Student Result
                </p>

                {error && <p className="text-red-500">{error}</p>}

                <div className="grid w-full grid-cols-12 gap-5 grid-rows-2 text-[0.85rem] mb-6">
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Class Name</option>
                        {classCategoryList.length > 0 &&
                            classCategoryList.map((data) => (
                                <option key={data._id} value={data.className}>
                                    {data.className}
                                </option>
                            ))}
                    </select>

                        <input
                            type={"text"}
                            name={"examName"}
                            value={examName}
                            onChange={(e)=>setExamName(e.target.value)}
                            placeholder={`Enter Exam Name`}
                            className="col-span-6 w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                </div>

                <div>
                    {studentResultList.length > 0 ? (
                        <StudentTableCard data={studentResultList}/>
                    ) : (
                        <p>No result available for the selected class and exam.</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default SearchCreatedStudentResultList;
