import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../Component/Common/Sidebar";
import { ParentSidebarItems } from "../data/SidebarItem";
import worldLogo from "../Assests/Logos/world.png"
import { IoMenuOutline } from "react-icons/io5";
import avtar from "../Assests/Logos/avtar.png"

const ParentDashboardPage = () => {
    const [user, setUser] = useState();
    const [logo, setLogo] = useState("");
    const [academicsYear, setAcademicsYear] = useState([]);
    useEffect(() => {

        const logo = localStorage.getItem("logo") ? localStorage.getItem("logo") : worldLogo;
        const user = JSON.parse(localStorage.getItem("user"))
        const academics = JSON.parse(localStorage.getItem("academicYear"))
        setLogo(logo);
        setUser(user);
        setAcademicsYear(academics || []);
    }, [])
    return (
        <div className="w-[100vw] h-[100vh] grid grid-cols-12">
            <aside className="col-span-12 md:col-span-3 lg:col-span-2 max-w-[330px] bg-[#FFFF] ">
                <div className="w-full h-[50px]  flex justify-center items-center my-2">
                    <Link to={"/"}>
                        <img src={user?.schoolLogo} className="w-[80px] h-[70px]" />
                    </Link>
                </div>
                <Sidebar Sidebar={ParentSidebarItems} />
            </aside>
            <main className="col-span-12 md:col-span-9 lg:col-span-10 flex flex-col">
                <nav className="h-[70px] w-full bg-[#FFFF]   flex justify-between px-10 font-poppins">
                    <div className="h-full w-[180px]  flex items-center justify-between ">
                        <IoMenuOutline

                            className="w-[30px] h-[40px]" />
                        <p className="text-text_Black-100 font-poppins text-[1.1rem]"> Smart Edu Planet</p>
                    </div>
                    <div className="w-[400px] flex flex-row justify-between items-center">
                        <p className="text-text_gray-100 text-[0.9rem] font-semibold">Session Years :{academicsYear[1]?.year}</p>
                        <div className="flex gap-3 ">
                            <img src={avtar} />
                            <div className="h-full flex flex-col  justify-start items-center">
                                <p className="text-[0.79rem] text-[#000] font-semibold">
                                    {user?.schoolName.split(" ")[0]} {user?.schoolName.split(" ")[1]}
                                </p>
                                <p className="text-[0.6rem] text-[#000]">Code: {user?.parentId}</p>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="bg-text_gray-75 w-full h-[90vh] p-5 overflow-y-scroll">
                    <Outlet />
                </section>
            </main>
        </div>

    )
}

export default ParentDashboardPage