import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import LoginLogo from "../../Assests/Logos/world.png";
import { useNavigate } from "react-router-dom";
import { SuperAdminLogin } from "../../Services/superAdminApis";
import toast from "react-hot-toast";
import { setToken, setUser } from "../../Redux/slices/authSlice";

// Validation schema with yup
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
});

const SuperAdminLoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [error, setError] = useState(null);
    const isLoading = useSelector((state) => state.auth.isLoading); // Assuming there's an isLoading state in auth slice


    const onSubmit = async (values) => {
        console.log("request data", values)
        try {
            const response = await SuperAdminLogin(values);
            console.log("Admin Login", response.data)
            dispatch(setUser(response?.data?.superAdmin))
            dispatch(setToken(response?.data?.token))
            localStorage.setItem("user", JSON.stringify(response?.data?.superAdmin))
            localStorage.setItem("token", JSON.stringify(response?.data?.token))

            toast.success("Login Successfully");
            navigate("/superAdmin/dashboard");
        } catch (error) {
            setError("Login failed");
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <section className="bg-border-50 w-[100vw] h-[100vh] flex justify-center items-center">
            <div className="w-[50vw] mx-auto h-[90vh] bg-white px-10 py-5 text-start text-[0.81rem] shadow-lg rounded-md">
                <div className="w-full flex justify-center mt-2 mb-10">
                    <img src={LoginLogo} className="w-[85px] h-[60px]" alt="Login Logo" />
                </div>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form className="flex flex-col max-w-[100%] mx-auto p-2 mt-5 rounded-md">
                            <div className="mb-4 h-[80px]">
                                <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="email">
                                    Email
                                </label>
                                <Field
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Email Or Mobile No."
                                    className="w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-md focus:outline-none"
                                />
                                <ErrorMessage name="email" component="p" className="text-red-500 text-sm mt-1" />
                            </div>

                            <div className="mb-4 h-[80px]">
                                <label className="block text-text_Black-50 font-semibold mb-1" htmlFor="password">
                                    Password
                                </label>
                                <div className="relative rounded-md">
                                    <Field
                                        id="password"
                                        name="password"
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder="Password"
                                        autoComplete="current-password"
                                        className="w-full p-2 placeholder:text-[0.8rem] placeholder:font-semibold border border-border-50 rounded-md focus:outline-none"
                                    />
                                    <span
                                        onClick={togglePasswordVisibility}
                                        className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer p-2"
                                    >
                                        {isPasswordVisible ? (
                                            <svg className="h-full w-6" /* (SVG for eye icon) */ />
                                        ) : (
                                            <svg className="h-full w-6" /* (SVG for eye-off icon) */ />
                                        )}
                                    </span>
                                </div>
                                <ErrorMessage name="password" component="p" className="text-red-500 text-sm mt-1" />
                            </div>

                            <button
                                type="submit"
                                className="bg-[#22577A] font-semibold text-[0.9rem] text-[#FFFFFF] p-2 rounded-md mt-2 hover:bg-[#22577A] transition"
                                disabled={isLoading || isSubmitting}
                            >
                                {isLoading ? "Logging in..." : "Login as SuperAdmin"}
                            </button>

                            {error && <p className="text-red-500 mt-2">{error}</p>}
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
};

export default SuperAdminLoginForm;
