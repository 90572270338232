import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useSearchParams } from "react-router-dom";
import { getSubjectListofClassById, AddClassNewChapter } from "../../../Services/TeachersApis";
import toast from "react-hot-toast";

const CreateChapter = () => {
    const [searchParams] = useSearchParams();
    const className = searchParams.get("className");
    const section = searchParams.get("section");
    const schoolId = JSON.parse(localStorage.getItem("schoolId")) || "";
    const [subjectClassList, setSubjectClassList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState("");
    const [formData, setFormData] = useState({
        chapterNumber: "",
        title: "",
        file: "",
        description: "",
    });

    useEffect(() => {
        const fetchSubjectList = async () => {
            try {
                const response = await getSubjectListofClassById(schoolId, className, section);
                setSubjectClassList(response?.data || []);
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch subjects for the selected class and section");
            }
        };
        fetchSubjectList();
    }, [schoolId, className, section]);

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: name === "file" ? files : value });
    };

    const handleCreateChapter = async () => {
        // Check for file size (max 500KB)
        if (formData.file && formData.file[0].size > 500000) {
            toast.error("File size must be less than 500KB.");
            return;
        }

        if (!selectedSubject || !formData.chapterNumber || !formData.title || !formData.file || !formData.description) {
            toast.error("Please fill all required fields!");
            return;
        }

        let formDataToSend = new FormData();
        formDataToSend.append("subject", selectedSubject);
        formDataToSend.append("chapter[chapterNumber]", formData.chapterNumber);
        formDataToSend.append("chapter[title]", formData.title);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("file", formData.file[0]); // Assuming file is a single file in an array

        const toastId = toast.loading("Loading...");
        try {
            const response = await AddClassNewChapter(formDataToSend);
            if (response.status === 200 || response.status === 201) {
                toast.dismiss(toastId);
                resetForm();
                toast.success("Successfully added chapter");
            } else {
                toast.dismiss(toastId);
                toast.error("Something went wrong while adding the chapter");
            }

            // Assuming the response contains a `success` field
            if (response?.data?.success === "ok") {
                toast.error("Operation was not successful, please check your inputs.");
            }
        } catch (error) {
            console.error(error);
            toast.dismiss(toastId);
            toast.error("An error occurred while adding the chapter");
        }
    };
    const resetForm = () => {
        setFormData({
            chapterNumber: "",
            title: "",
            file: "",
            description: "",
        })
    }

    const isFormValid =
        selectedSubject && formData.chapterNumber && formData.title && formData.file && formData.description;

    return (
        <div className="font-poppins">
            <Headingtitle title="Create Chapter" />
            <main className="bg-white min-h-[80vh] flex flex-col justify-between rounded-lg p-6 shadow-lg">
                <div>
                    <p className="text-[1.5rem] font-semibold pb-4 text-gray-800">Add New Chapter</p>
                    <section className="grid grid-cols-12 gap-6 p-4">
                        <div className="col-span-6">
                            <select
                                id="subjectName"
                                value={selectedSubject}
                                onChange={handleSubjectChange}
                                className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                            >
                                <option value="" disabled>
                                    -- Select Subject --
                                </option>
                                {subjectClassList.map((subject) => (
                                    <option key={subject._id} value={subject._id}>
                                        {subject.subjectName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {Object.keys(formData).map((key) => (
                            <div
                                key={key}
                                className={`${["chapterNumber", "title", "file"].includes(key) ? "col-span-6" : "col-span-12"}`}
                            >
                                {key === "description" ? (
                                    <textarea
                                        name={key}
                                        placeholder="Enter chapter description"
                                        value={formData[key]}
                                        onChange={handleInputChange}
                                        rows={4}
                                        className="w-full border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                                    />
                                ) : (
                                    <input
                                        type={key === "file" ? "file" : "text"}
                                        name={key}
                                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                                        value={key !== "file" ? formData[key] : undefined}
                                        accept={key === "file" ? ".pdf,.docx,.jpg,.png" : undefined}
                                        onChange={handleInputChange}
                                        className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                                    />
                                )}
                            </div>
                        ))}
                    </section>
                </div>
                <button
                    onClick={handleCreateChapter}
                    type="submit"
                    disabled={!isFormValid}
                    className={`${!isFormValid
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                        } w-full py-2 rounded-md text-white font-semibold`}
                >
                    Create Chapter
                </button>
            </main>
        </div>
    );
};

export default CreateChapter;
