import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Headingtitle from "../../Common/Headingtitle";
import { GetClassSectionList, onboardNewExamTimetable, getAllClassIdList } from "../../../Services/AdminApis";

const CreateExamTimetable = () => {
    const animatedComponents = makeAnimated();

    const [allSectionList, setAllSectionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [examTimeTable, setExamTimeTable] = useState({
        className: "",
        examName: "",
        subTitle: "",
        totalMarks: "",
        passingMarks: "",
    });

    const [errors, setErrors] = useState({});
    const [exams, setExams] = useState([
        {
            subject: "",
            section: [],
            examDate: "",
            startTime: "",
            endTime: "",
            duration: "",
            notes: "",
        },
    ]);

    useEffect(() => {
        const fetchSectionList = async () => {
            try {
                const response = await GetClassSectionList();
                const response2 = await getAllClassIdList();
                setAllSectionList(response?.data?.sections || []);
                setClassList(response2?.data?.classes || []);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSectionList();
    }, []);

    const sectionOptions = allSectionList.map((section) => ({
        value: section._id,
        label: section.classSection,
    }));

    const validate = () => {
        const newErrors = {};
        if (!examTimeTable.className) newErrors.className = "Class name is required.";
        if (!examTimeTable.examName) newErrors.examName = "Exam name is required.";
        if (!examTimeTable.subTitle) newErrors.subTitle = "Subtitle is required.";
        if (!examTimeTable.totalMarks || isNaN(examTimeTable.totalMarks)) {
            newErrors.totalMarks = "Total marks must be a valid number.";
        }
        if (!examTimeTable.passingMarks || isNaN(examTimeTable.passingMarks)) {
            newErrors.passingMarks = "Passing marks must be a valid number.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setExamTimeTable({ ...examTimeTable, [name]: value });
    };

    const handleExamChange = (index, event) => {
        const { name, value } = event.target;
        const updatedExams = [...exams];
        updatedExams[index] = {
            ...updatedExams[index],
            [name]: name === "section" ? value.map((option) => option.label) : value,
        };
        setExams(updatedExams);
    };

    const handleAddExamForm = () => {
        setExams([
            ...exams,
            {
                subject: "",
                section: [],
                examDate: "",
                startTime: "",
                endTime: "",
                duration: "",
                notes: "",
            },
        ]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const payload = { ...examTimeTable, exams };

            try {
                const response = await onboardNewExamTimetable(payload);

                if ([200, 201].includes(response.status)) {
                    // Show success toast
                    toast.success("Successfully created exam timetable");

                    // Reset main exam timetable data
                    setExamTimeTable({
                        className: "",
                        examName: "",
                        subTitle: "",
                        totalMarks: "",
                        passingMarks: "",
                    });

                    // Reset exam details data and clear sections
                    setExams([
                        {
                            subject: "",
                            section: [], // This clears the section data
                            examDate: "",
                            startTime: "",
                            endTime: "",
                            duration: "",
                            notes: "",
                        },
                    ]);

                    // Clear section options (if needed)
                    setAllSectionList([]);
                } else {
                    // Show error toast for non-success responses
                    toast.error("Failed to create exam timetable");
                }
            } catch (error) {
                // Show error toast only for caught exceptions
                console.error(error);
                toast.error(error?.response?.data?.message);
            }
        }
    };
    return (
        <div className="font-poppins">
            <Headingtitle title="Exam Time Table" />
            <main className="bg-white min-h-[80vh] rounded-md p-5">
                <p className="text-[1rem] font-semibold pb-2">Create a new exam time table</p>
                <form onSubmit={handleSubmit}>
                    <div className="grid w-full grid-cols-2 gap-5 text-[0.77rem]">
                        {Object.keys(examTimeTable).map((key, index) => (
                            <div key={index} className="flex flex-col">
                                {key === "className" ? (
                                    <select
                                        id={key}
                                        name={key}
                                        value={examTimeTable[key]}
                                        onChange={handleInputChange}
                                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none border-[#EBEDF2]"
                                    >
                                        <option value="">Select Class</option>
                                        {classList.map((classOption, idx) => (
                                            <option key={idx} value={classOption?.className}>
                                                {classOption?.className}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        id={key}
                                        name={key}
                                        type={["totalMarks", "passingMarks"].includes(key) ? "number" : "text"}
                                        value={examTimeTable[key]}
                                        onChange={handleInputChange}
                                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none border-[#EBEDF2]"
                                        placeholder={`Enter ${key}`}
                                    />
                                )}
                                {errors[key] && <span className="text-red-500 text-sm">{errors[key]}</span>}
                            </div>
                        ))}
                    </div>

                    <div className="mt-10">
                        <h2 className="text-lg font-bold mb-3">Add Exam Details</h2>
                        {exams.map((exam, index) => (
                            <div key={index} className="mt-5 border p-4 rounded-lg">
                                <h3 className="font-bold mb-2">Exam {index + 1}</h3>
                                <div className="grid w-full grid-cols-2 gap-5 text-[0.77rem]">
                                    {Object.keys(exam).map((key, idx) =>
                                        key === "section" ? (
                                            <Select
                                                key={idx}
                                                styles={{ control: (base) => ({ ...base, borderColor: "#EBEDF2", height: "55px" }) }}
                                                isMulti
                                                components={animatedComponents}
                                                options={sectionOptions}
                                                onChange={(selected) =>
                                                    handleExamChange(index, {
                                                        target: { name: key, value: selected },
                                                    })
                                                }
                                            />
                                        ) : (
                                            <input
                                                key={idx}
                                                id={`${key}-${index}`}
                                                name={key}
                                                type={
                                                    key === "examDate"
                                                        ? "date"
                                                        : ["startTime", "endTime"].includes(key)
                                                            ? "time"
                                                            : key === "duration"
                                                                ? "number"
                                                                : "text"
                                                }
                                                value={
                                                    key === "examDate"
                                                        ? exam[key]?.split("T")[0] // Show date only in input
                                                        : exam[key]
                                                }
                                                onChange={(e) => handleExamChange(index, e)}
                                                className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none border-[#EBEDF2]"
                                                placeholder={`Enter ${key}`}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={handleAddExamForm}
                            className="mt-5 bg-green-500 text-white font-bold py-2 px-5 rounded-md hover:bg-green-600"
                        >
                            Add Another Exam
                        </button>
                    </div>

                    <button
                        type="submit"
                        className="mt-5 bg-[#22577A] w-full text-white font-bold py-2 px-5 rounded-md hover:bg-[#1b4e69]"
                    >
                        Submit Timetable
                    </button>
                </form>
            </main>
        </div>
    );
};

export default CreateExamTimetable;
