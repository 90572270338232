import React, { useState, useEffect } from 'react';
import { getAllStudentList, createStudentFees } from '../../../Services/AdminApis';
import toast from 'react-hot-toast';
const StudentFee = () => {
    const [allStudentListData, setAllStudentListData] = useState([]);
    const [feeRecord, setFeeRecord] = useState({
        studentId: "",
        month: "",
        year: "",
        feeBreakdown: {
            annualFee:null,
            academicFee: null,
            examFee: null,
            transportationFee:null,
        },
        dueDate: "",
        remarks: "",
    });

    const [errors, setErrors] = useState({});
    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];


    const years = Array.from({ length: 10 }, (_, index) => 2024 - index);  // Generating the past 10 years starting from 2024
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("feeBreakdown.")) {
            const breakdownKey = name.split(".")[1];
            setFeeRecord({
                ...feeRecord,
                feeBreakdown: {
                    ...feeRecord.feeBreakdown,
                    [breakdownKey]: Number(value),
                },
            });
        } else {
            setFeeRecord({
                ...feeRecord,
                [name]: value,
            });
        }
    };


    useEffect(() => {
        const fetchAllRoutes = async () => {
            try {
                const response2 = await getAllStudentList();
                setAllStudentListData(response2?.data?.students);
            } catch (error) {
                console.error("Error While Getting Routes data");
            }
        };
        fetchAllRoutes();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const response = await createStudentFees(feeRecord);
                if (response?.status === 201 || response?.status === 200) {
                    resetForm();
                    toast.success("Successfully added student Fees");
                } else {
                    toast.error("Something went wrong");
                    console.error(response?.message);
                }
            }
            catch (error) {
                // Handle error
                toast.error("Error occurred while creating fee of the student.");
                console.error(error.message);
            }

            setErrors({});
            console.log("Submitted Fee Record:", feeRecord);
        }
    };
    const resetForm = () => {
        setFeeRecord({
            studentId: "",
            month: "",
            year: "",
            feeBreakdown: {
                annualFee: "",
                academicFee: "",
                examFee: "",
                transportationFee: "",
            },
            dueDate: "",
            remarks: "",
        })
    }

    const validate = () => {
        const newErrors = {};
        Object.keys(feeRecord).forEach((key) => {
            if (key !== 'feeBreakdown' && !feeRecord[key]) {
                newErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required.`;
            }
        });
        return newErrors;
    };

    return (
        <div className="font-poppins">
            <h1 className="text-2xl font-bold mb-5">Student Fee Record</h1>
            <form onSubmit={handleSubmit} className="bg-white p-5 grid grid-cols-12 gap-5 rounded-lg shadow-md ">
                {/* Student ID (select dropdown) */}
                <div className="flex flex-col col-span-6">
                    <label htmlFor="studentId" className="font-medium">Student ID</label>
                    <select
                        id="studentId"
                        name="studentId"
                        value={feeRecord.studentId}
                        onChange={handleInputChange}
                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                    >
                        <option value="">Select Student</option>
                        {allStudentListData.map((student) => (
                            <option key={student._id} value={student._id}>
                                {`${student.name.firstName}  (${student.name.lastName})`}
                            </option>
                        ))}

                    </select>
                    {errors.studentId && <span className="text-red-500 text-sm">{errors.studentId}</span>}
                </div>

                {/* Month (select dropdown) */}
                <div className="flex flex-col col-span-6">
                    <label htmlFor="month" className="font-medium">Month</label>
                    <select
                        id="month"
                        name="month"
                        value={feeRecord.month}
                        onChange={handleInputChange}
                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                    >
                        <option value="">Select Month</option>
                        {months.map((month, index) => (
                            <option key={index} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                    {errors.month && <span className="text-red-500 text-sm">{errors.month}</span>}
                </div>

                {/* Year (select dropdown) */}
                <div className="flex flex-col col-span-6">
                    <label htmlFor="year" className="font-medium">Year</label>
                    <select
                        id="year"
                        name="year"
                        value={feeRecord.year}
                        onChange={handleInputChange}
                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    {errors.year && <span className="text-red-500 text-sm">{errors.year}</span>}
                </div>

                {/* Fee Breakdown */}
                {["annualFee", "academicFee", "examFee", "transportationFee"].map((feeType) => (
                    <div key={feeType} className="flex flex-col col-span-6">
                        <label htmlFor={feeType} className="font-medium capitalize">{feeType.replace(/([A-Z])/g, " $1")}</label>
                        <input
                            id={feeType}
                            name={`feeBreakdown.${feeType}`}
                            type="number"
                            value={feeRecord.feeBreakdown[feeType]}
                            onChange={handleInputChange}
                            className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                            placeholder={`Enter ${feeType.replace(/([A-Z])/g, " $1")}`}
                        />
                        {errors.feeBreakdown?.[feeType] && <span className="text-red-500 text-sm">{errors.feeBreakdown[feeType]}</span>}
                    </div>
                ))}

                {/* Due Date */}
                <div className="flex flex-col col-span-6">
                    <label htmlFor="dueDate" className="font-medium">Due Date</label>
                    <input
                        id="dueDate"
                        name="dueDate"
                        type="date"
                        value={feeRecord.dueDate}
                        onChange={handleInputChange}
                        className="w-full h-[55px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                    />
                    {errors.dueDate && <span className="text-red-500 text-sm">{errors.dueDate}</span>}
                </div>

                {/* Remarks */}
                <div className="flex flex-col col-span-12">
                    <label htmlFor="remarks" className="font-medium">Remarks</label>
                    <textarea
                        id="remarks"
                        name="remarks"
                        value={feeRecord.remarks}
                        onChange={handleInputChange}
                        className="w-full h-[100px] border-[2px] rounded-lg shadow-sm px-3 outline-none"
                        placeholder="Enter remarks"
                    />
                    {errors.remarks && <span className="text-red-500 text-sm">{errors.remarks}</span>}
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full col-span-12 bg-[#22577A] text-white font-bold py-2 px-5 rounded-md hover:bg-[#1b4e69] focus:outline-none"
                >
                    Submit Fee Record
                </button>
            </form>
        </div>
    );
};

export default StudentFee;
