import React, { useEffect, useState } from "react";
import { getAllClassCategoryList, getDueAssignment, getAllSectionList,updateAssignment } from "../../../Services/TeachersApis";
import { useParams } from "react-router-dom";
import {
    Autocomplete,
    TextField, 
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import Headingtitle from "../../Common/Headingtitle";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";

const UpdateAssignment = () => {
    const { assignmentId } = useParams();
    const { token } = useSelector((state) => state.auth);

    const [sectionList, setSectionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [allDueAssignment, setAllDueAssignment] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assignmentData, setAssignmentData] = useState({
        className: "",
        sections: [],
        title: "",
        instructions: "",
        weightage: "",
        dueDate: "",
        dueTime: "",
        subject: "",
        resubmissionAllowed: false,
        file: null,
    });
    const [previewImage, setPreviewImage] = useState("");

    // Fetch assignments
    useEffect(() => {
        const fetchDueAssignment = async () => {
            setLoading(true);
            try {
                const response = await getDueAssignment();
                const sectionResponse = await getAllSectionList();
                const classResponse = await getAllClassCategoryList();

                if (sectionResponse?.status === 200) {
                    setSectionList(sectionResponse?.data?.sections || []);
                }
                if (classResponse?.status === 200) {
                    setClassList(classResponse?.data?.classCategories || []);
                }

                setAllDueAssignment(response?.data?.assignments || []);
            } catch (error) {
                console.error("Error fetching due assignments:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDueAssignment();
    }, []);

    // Pre-fill form with selected assignment data
    useEffect(() => {
        const selectedAssignment = allDueAssignment.find(
            (assignment) => assignment._id === assignmentId
        );
        if (selectedAssignment) {
            setAssignmentData({
                className: selectedAssignment.className || "",
                sections: selectedAssignment.section || [],
                title: selectedAssignment.title || "",
                instructions: selectedAssignment.instructions || "",
                weightage: selectedAssignment.weightage || "",
                dueDate: selectedAssignment.dueDate
                    ? new Date(selectedAssignment.dueDate).toISOString().split("T")[0]
                    : "",
                dueTime: selectedAssignment.dueTime || "",
                subject: selectedAssignment.subject || "",
                resubmissionAllowed: selectedAssignment.resubmissionAllowed || false,
                file: null,
            });
            setPreviewImage(selectedAssignment?.attachment
                ? selectedAssignment.attachment.map((file) => file)
                : null)
        }
    }, [assignmentId, allDueAssignment]);

    // console.log(assignmentData);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            setAssignmentData((prev) => ({ ...prev, [name]: checked }));
        } else if (name === "file") {
            const file = e.target.files[0];
            setAssignmentData((prev) => ({ ...prev, file }));
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setAssignmentData((prev) => ({ ...prev, [name]: value }));
        }
    };
    console.log(previewImage);
    const handleClassChange = (event, newValue) => {
        setAssignmentData((prev) => ({
            ...prev,
            className: newValue?.className || "",
        }));
    };

    // Handle sections dropdown change
    const handleSectionChange = (event, newValue) => {
        setAssignmentData((prev) => ({
            ...prev,
            sections: newValue.map((item) => item.classSection), // Extract classSection values
        }));
    };

    const resetForm = () => {
        setAssignmentData({
            className: "",
            sections: [],
            weightage: "",
            title: "",
            instructions: "",
            dueDate: "",
            dueTime: "",
            resubmissionAllowed: false,
            subject: "",
            file: null,
        })
    }
    // Handle form submission
    const handleSubmit =async (e) => {
        e.preventDefault();
        console.log("Updated Assignment Data:", assignmentData);
        try {
            // Add all fields to formData except sections
            const formData = new FormData();
            Object.keys(assignmentData).forEach((key) => {
                if (key !== "sections") {
                    formData.append(key, assignmentData[key]);
                }
            });

            // Append sections array separately
            assignmentData.sections.forEach((section, index) => {
                formData.append(`section[${index}]`, section);
            });
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const response=await updateAssignment(assignmentId,formData,token)

            // const response = await axios.post(
            //     `${baseUrl}/teacher/create-new-assignment-2`,
            //     formData,
            //     {
            //         headers: {
            //             'Content-Type': 'multipart/form-data',
            //             'Authorization': `Bearer ${token}`,
            //         }
            //     }
            // );

            if (response?.status === 200) {
                toast.success("Successfully updated Assignment");
                resetForm();
            } else {
                console.error(response?.data?.message);
                toast.error("SomeThing went wrong");
            }
        } catch (error) {
            console.error("Error creating assignment:", error);
            toast.error("Internal Server Error!")
        }
    };

   
    return (
        <div className="font-poppins">
            <Headingtitle title="Update Assignment" />
            <main className="bg-white h-auto rounded-md p-5 shadow-lg">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Class Name Dropdown */}
                        <div>
                            <label
                                htmlFor="className"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Class Name
                            </label>
                            <Autocomplete
                                options={classList}
                                getOptionLabel={(option) => option.className}
                                value={
                                    allDueAssignment.find(
                                        (cls) => cls.className === assignmentData.className
                                    ) || null
                                }
                                onChange={handleClassChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Class Name"
                                    />
                                )}
                            />
                        </div>

                        {/* Sections Dropdown */}
                        <div>
                            <label
                                htmlFor="sections"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Sections
                            </label>
                            <Autocomplete
                                multiple
                                options={sectionList}
                                getOptionLabel={(option) => option.classSection}
                                value={assignmentData.sections.map((section) => ({
                                    classSection: section,
                                }))}
                                onChange={handleSectionChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Sections"
                                    />
                                )}
                            />
                        </div>

                        {/* Dynamic Input Fields */}
                        {[
                            "title",
                            "instructions",
                            "weightage",
                            "dueDate",
                            "dueTime",
                            "subject",
                        ].map((key) => (
                            <div key={key}>
                                <label
                                    htmlFor={key}
                                    className="block text-sm font-medium text-gray-700 capitalize"
                                >
                                    {key.replace(/([A-Z])/g, " $1")}
                                </label>
                                <input
                                    type={
                                        key.includes("Date")
                                            ? "date"
                                            : key.includes("Time")
                                                ? "time"
                                                : "text"
                                    }
                                    name={key}
                                    value={assignmentData[key] || ""}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key}`}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2"
                                />
                            </div>
                        ))}

                        {/* Resubmission Checkbox */}
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="resubmissionAllowed"
                                        checked={assignmentData.resubmissionAllowed}
                                        onChange={handleChange}
                                    />
                                }
                                label="Allow Resubmission"
                            />
                        </div>

                        {/* Image Upload */}
                        <div>
                            <label
                                htmlFor="file"
                                className="block text-sm font-medium text-gray-700 capitalize"
                            >
                                Upload Image
                            </label>
                            <input
                                type="file"
                                name="file"
                                accept="image/*"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2"
                            />
                            {previewImage && (
                                <img
                                    src={previewImage}
                                    alt="Preview"
                                    className="mt-3 h-20 w-20 object-cover rounded-md border"
                                />
                            )}
                        </div>
                    </div>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-2"
                    >
                        Update Assignment
                    </Button>
                </form>
            </main>
        </div>
    );
};

export default UpdateAssignment;
