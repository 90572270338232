import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { useParams } from "react-router-dom";
import campus from "../../../Assests/Logos/campus.png"; // Assuming this is a placeholder image
import toast from "react-hot-toast";
import { getStudyMaterialbyIdandChapterNumber } from "../../../Services/StudentApis";

const StudyMaterial = () => {
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { subjectId, chapterNumber } = useParams();

  // Fetch study materials
  useEffect(() => {
    const fetchStudyMaterials = async () => {
      try {
        const response = await getStudyMaterialbyIdandChapterNumber(subjectId, chapterNumber);
        setStudyMaterials(response?.data?.studyMaterials || []);
      } catch (err) {
        console.error("Error fetching study materials:", err);
        setError(err?.response?.data?.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudyMaterials();
  }, [subjectId, chapterNumber]);

  if (loading) {
    return <p className="text-center mt-10">Loading...</p>;
  }

  if (error) {
    return <p className="text-center mt-10 text-red-500">{error}</p>;
  }

  if (studyMaterials.length === 0) {
    return (
      <p className="text-center mt-10 text-gray-500">
        No study materials available for this chapter.
      </p>
    );
  }

  return (
    <section>
      <Headingtitle title="Study Material" />
      <div className="overflow-x-auto p-3 text-[0.77rem] shadow-md relative bg-white min-h-[80vh]">
        <section className="min-w-full bg-white rounded-lg p-5 font-poppins">
          <div className="grid grid-cols-12 gap-4">
            {studyMaterials.map((material) => (
              <div
                key={material._id}
                className="col-span-4 p-4 flex flex-col border border-border-100 rounded-lg items-center bg-white shadow hover:shadow-md transition duration-200"
              >
                <img
                  src={campus}
                  alt="Material Thumbnail"
                  className="w-[50px] h-[50px] mb-4"
                />
                <div className="text-center h-[70%]  w-full">
                  <p className="font-semibold text-text_blue-500 text-[0.9rem] mb-2">
                    Chapter {material.chapter?.chapterNumber}: {material.chapter?.title || "Untitled"}
                  </p>
                  <p className="text-gray-600 text-[0.8rem] mb-4">
                    {material.description || "No description available"}
                  </p>
                </div>
                <a
                  href={material.docs_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mt-3 bg-text_blue-500 px-4 py-2 rounded-lg text-[0.8rem] font-semibold hover:bg-text_blue-600 transition duration-200"
                >
                  View Material
                </a>
              </div>
            ))}
          </div>
        </section>
      </div>
    </section>
  );
};

export default StudyMaterial;
