import api from "./apiUtility";


export const AdminLogin = async (payload) => {
    try {
        const response = await api.post("/admin-school/login", payload);
        return response;
    } catch (error) {
        throw error
    }
}


// export const  = async (payload) => {
//     console.log("da", payload)
//     try {
//         const response = await api.post("/super-admin/onboard-new-admin-school", payload,
//             {
//                 headers: {
//                     'Content-Type': 'multipart/form-data', // Ensure this is set
//                 }
//             }
//         );
//         return response;
//     } catch (error) {
//         throw error
//     }
// }
