import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { myleave } from "../../../Services/StudentApis";

const StudentLeave = () => {
    const [leaveList, setLeaveList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("Pending"); // default status filter

    // Function to fetch leaves based on the selected status
    const fetchData = async () => {
        setLoading(true); 
        try {
            const response = await myleave(status); // Fetch leaves based on selected status
            setLeaveList(response?.data || []);
        } catch (error) {
            setLeaveList([]);
            console.error("Error fetching leaves:", error);
        } finally {
            setLoading(false); 
        }
    };

    
    useEffect(() => {
        fetchData();
    }, [status]); 

    return (
        <div className="font-poppins">
            <Headingtitle title="My Leaves" />
            <main className="bg-white shadow-md rounded-md p-5">
                <p className="font-semibold pb-2 text-[1rem]">Leave Records</p>

                {/* Dropdown to filter leave records by status */}
                <div className="mb-4">
                    <select
                        className="border rounded-md px-3 py-2 text-gray-700"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                </div>

                <div>

                    <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Leave Type</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Reason</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Status</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Reviewed By</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Student</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <p className="text-gray-500">Loading...</p>
                            ) : leaveList && leaveList.length > 0 ? (
                                <>
                                    {leaveList.map((record, index) => (
                                        <tr
                                            key={record._id}
                                            className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-indigo-100 transition-all`}
                                        >
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {new Date(record.startDate).toLocaleDateString()} - {new Date(record.endDate).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.leaveType}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.reason}
                                            </td>
                                            <td
                                                className={`px-6 py-4 text-[15px] font-semibold ${record.status === "Approved"
                                                    ? "text-green-500"
                                                    : record.status === "Rejected"
                                                        ? "text-red-500"
                                                        : "text-yellow-500"
                                                    }`}
                                            >
                                                {record.status}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.reviewedBy ? `${record.reviewedBy.firstName} ${record.reviewedBy.lastName}` : "N/A"}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {`${record.studentId.name.firstName} ${record.studentId.name.lastName}`}
                                            </td>

                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <p className="text-gray-500 mt-6">No Leave Records Found</p>
                            )}
                        </tbody>
                    </table>

                </div>
            </main>
        </div>
    );
};

export default StudentLeave;
