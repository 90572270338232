
import React, { useState, useEffect } from "react";
import Headingtitle from "../../Common/Headingtitle";
import Count_Icon from "../../../Assests/Logos/Count_Icon.png"
import StudentAnalyticsBarChart from "../../Common/AdminSchoolAnalyticsChart";
import { BiSolidDownArrow } from "react-icons/bi";
import BarChart from "../../Common/BarChart";
import { getSchoolStats, getSchoolAnalytics } from '../../../Services/AdminApis'


const AdminDashboardComp = () => {
    const [allStats, setAllStats] = useState({})
    const [allAnalytics, setAllAnalytics] = useState([])
    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getSchoolStats();
                setAllStats(response?.data?.data || {});
                console.log("response?.data?.data", response?.data?.data);
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);


    useEffect(() => {
        const fetchSchoolAnalytics = async () => {
            try {
                const analyticsResponse = await getSchoolAnalytics();
                setAllAnalytics(analyticsResponse?.data?.data || []);
                console.log("response?.data?.data", analyticsResponse?.data?.data);
            } catch (error) {
                console.error("Error fetching class list:", error);
            }

        }
        fetchSchoolAnalytics()
    }, [])

    const DashboardData = [{
        title: "Total Teachers",
        count: allStats.totalTeachers || "00",
        // img: TotalTeachers
    },
    {
        title: "Total Students",
        count: allStats.totalStudents || "0 0",

        // img: TotalStudents
    },
    {
        title: "Total Guardians",
        count: allStats.totalGuardians || "0 0",
        // img: TotalGuardians
    }, {
        title: "Total Classes",
        count: allStats.totalClasses || "0 0",

        // img: TotalClasses
    }]
    const bardata = [
        { label: "A", value: 95.5 },
        { label: "B", value: 85.5 }
    ];


    const analyticsData = allAnalytics && allAnalytics.length >0 ? allAnalytics.map(item => ({ x: item.year, y: item.totalStudentsInYear })):[
        { x: 2021, y: 100 },
        { x: 2022, y: 150 },
        { x: 2023, y: 250 },
        { x: 2024, y: 150 },
    ];  

    const holidays = [
        { name: "Diwali", date: "30-10-24" },
        { name: "Christmas", date: "25-12-24" },

        // Add more holiday objects
    ];
 
    return (
        <div className="font-poppins">
            <Headingtitle title="Dashboard" />
            <main className="px-6 py-2">
                <section className="grid w-full grid-cols-12 gap-5 ">

                    {
                        DashboardData.map((item, index) => (
                            <div key={index}
                                style={{ boxShadow: "6px 6px 54px 0px #0000000D" }}
                                className="col-span-3 font-nunito flex flex-col justify-between h-[8rem] rounded-2xl p-4 bg-white text-text_blue-500  shadow-md">
                                <div className="flex justify-between ">
                                    <p className="text-[1.4rem] text-[#979797]  ">{item.title}</p>
                                    <div style={{ backgroundColor: 'rgba(0, 123, 201, 0.21)' }} className="p-3 rounded-xl">
                                        <img src={Count_Icon} className="w-[1.75rem] h-[2.5rem]" />
                                    </div>

                                </div>
                                <p className="text-[1.5rem] text-text_gray-125 font-semibold">{item.count}</p>
                            </div>
                        ))
                    }

                    <div className="col-span-8 bg-white p-4 rounded-lg shadow">
                        <p className="text-lg font-semibold mb-2">School Analytics</p>
                        <div className="w-full h-[300px]">
                            <StudentAnalyticsBarChart data={analyticsData} />
                        </div>
                    </div>

                    <div className="col-span-4 bg-white  px-5 py-3 shadow-md rounded-md">
                        <div className="flex justify-between   border-border-100 ">
                            <p className="text-[1.5rem] font-semibold ">Leaves </p>
                            <div className="flex items-center border-2 h-fit justify-between border-border-100 rounded-md py-3 px-3 w-[40%]">
                                <p>Today</p>
                                <BiSolidDownArrow />
                            </div>
                        </div>
                        <div className="">
                            <p>All Are Present</p>
                        </div>
                    </div>

                    <div className="col-span-12 bg-[#FFFF] text-text_blue-500 rounded-lg shadow-md font-poppins text-[0.77rem]">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Announcement</p>
                        <table className="w-full">
                            <thead className="px-2">
                                <tr className="grid grid-cols-12 px-2">
                                    <th className="col-span-1 text-left">No</th>
                                    <th className="col-span-4 text-left">Title</th>
                                    <th className="col-span-7 text-left">Description</th>
                                </tr>
                            </thead>
                            <hr />
                            <tbody>
                                {Array(3).fill().map((_, index) => (
                                    <tr key={index} className="grid grid-cols-12 p-2">
                                        <td className="col-span-1">{index + 1}</td>
                                        <td className="col-span-4">
                                            <p className="text-text_blue-500 font-semibold p-3">Mascot Music Festival</p>
                                        </td>
                                        <td className="col-span-7">
                                            Have a bunch of talented musicians at your school? You probably do, whether you know it or not. Get everyone excited by launching your own school-based music festival, named after the mascot of your high school.
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="col-span-4 bg-[#FFFF] text-text_blue-500 rounded-lg shadow-md">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Holiday</p>
                        <div className="p-3">
                            {holidays.map((holiday, index) => (
                                <div key={index} className="mb-4 text-[0.77rem] flex justify-between p-2 rounded-lg bg-text_gray-75">
                                    <p className="font-semibold">{holiday.name}</p>
                                    <p className="text-text_gray-100">{holiday.date}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-4 bg-[#FFFF] text-text_blue-500 rounded-lg shadow-md">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">Holiday</p>
                        <div className="h-[300px]"> {/* Set the height here */}
                            <BarChart data={bardata} />
                        </div>
                    </div>


                    <div className="col-span-4 bg-[#FFFF] text-text_blue-500 rounded-lg shadow-md w-full">
                        <p className="text-[1.1rem] text-text_blue-500 font-semibold p-3">TimeTable</p>
                        <div className="p-3">
                            {holidays.map((holiday, index) => (
                                <div key={index} className="mb-4 text-[0.77rem] flex justify-between p-2 rounded-lg bg-text_gray-75">
                                    <p className="font-semibold">{holiday.name}</p>
                                    <p className="text-text_gray-100">{holiday.date}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                </section>
            </main>
        </div>
    );
};

export default AdminDashboardComp;

