
import React, { Fragment, useEffect, useState } from 'react';
import Headingtitle from '../../Common/Headingtitle';
import { useForm } from 'react-hook-form';
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { GetClassCategoryList, GetClassSectionList, GetOnboardedClassList, OnBaoardNewClass, GetOnboardedTeacherList } from '../../../Services/AdminApis';
import toast from 'react-hot-toast';
const AdminClassStream = () => {
    const [search, setSearch] = useState('');




    const [classCategories, setClassCategories] = useState([]);
    const [classSections, setClassSections] = useState([]);
    const [onboardedClasses, setOnboardedClasses] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [selectedClassCategory, setSelectedClassCategory] = useState('');
    const [selectedClassSection, setSelectedClassSection] = useState("")
    const [selectedClassTeacher, setSelectedClassTeacher] = useState("")

    // Fetch data from APIs
    const fetchClassCategories = async () => {
        try {
            const categoryResponse = await GetClassCategoryList();
            setClassCategories(categoryResponse?.data?.classCategories || []);
        } catch (error) {
            console.error("Error fetching class categories:", error);
        }
    };

    const fetchClassSections = async () => {
        try {
            const sectionResponse = await GetClassSectionList();
            setClassSections(sectionResponse?.data?.sections || []);
        } catch (error) {
            console.error("Error fetching class sections:", error);
        }
    };

    const fetchTeacherList = async () => {
        try {
            const teacherListResponse = await GetOnboardedTeacherList();
            if(teacherListResponse.status===200){
                setTeacherList(teacherListResponse?.data?.teachers || []);
            }
        } catch (error) {
            console.error("Error fetching teacher list:", error);
        }
    };
    useEffect(() => {
        fetchClassCategories();
        fetchClassSections();
        fetchTeacherList();
    }, []);


    useEffect(() => {
        const onboarderCalss = async () => {
            try {
                const onboardedResponse = await GetOnboardedClassList();
                setOnboardedClasses(onboardedResponse?.data?.classes || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        onboarderCalss()
    }, [])

    const handleClassCategoryChange = (event) => {
        setSelectedClassCategory(event.target.value);
    };
    const handleClassSectionChange = (event) => {
        setSelectedClassSection(event.target.value);
    }

    const handleClassTeacherChange = (event) => {
        setSelectedClassTeacher(event.target.value);
    }


    const inputFields = [
        { name: 'classTeacher', label: 'Class Teacher', placeholder: 'Enter the teacher name', },
    ];
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editClass, setEditClass] = useState(null);

    // Filter classes based on search input
    // const filteredClasses = onboardedClasses?.filter((classItem) =>
    //     classItem?.classSection?.toLowerCase().includes(search.toLowerCase())
    // );




    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = async (data) => {
        const payload = {
            className: selectedClassCategory,
            classSection: selectedClassSection,
            classTeacher: selectedClassTeacher,
        };

        try {
            // Sending the request to onboard the new class
            const response = await OnBaoardNewClass(payload);
            // Check if response status is 200 or 201
            if (response.status === 200 || response.status === 201) {
                console.log("Class onboarded successfully:", response.data);

                // Refresh the class list after successful submission
                const onboardedResponse = await GetOnboardedClassList();
                setOnboardedClasses(onboardedResponse?.data?.classes || []);

                // Show success message
                toast.success("Class successfully added!");

                // Reset the form and selected values
                reset();
                setSelectedClassCategory('');
                setSelectedClassSection('');
                setSelectedClassTeacher('');  // Assuming you also want to reset the class teacher field
            } else {
                // Show error message if response is not successful
                toast.error(response?.data?.message || "Failed to onboard the class.");
                console.log("Error while adding class data:", response?.data?.message);
            }
        } catch (error) {
            // Handle any errors during the API call
            console.error("Error onboarding class:", error);
            toast.error(error?.response?.data?.message);
        }
    };


    const filteredClasses = onboardedClasses?.filter((classItem) =>
        (classItem?.classSection || '').toLowerCase().includes(search.toLowerCase())
    );


    // Edit handler
    const handleEditClick = (classItem) => {
        setEditClass(classItem);
        setEditDialogOpen(true);
    };

    const handleEditSave = () => {
        setOnboardedClasses(onboardedClasses?.map((c) => (c.id === editClass.id ? editClass : c)));
        setEditDialogOpen(false);
    };

    // Delete handler
    const handleDeleteClick = (id) => {
        setOnboardedClasses(onboardedClasses?.filter((classItem) => classItem.id !== id));
    };

    return (
        <div>
            <Fragment>
                <div className="font-poppins">
                    {/* Heading section */}
                    <Headingtitle title="Academics" />

                    <main className="px-6 py-2">
                        <section className="w-full space-y-2  gap-5">
                            {/* Class Category Form */}
                            <div className="w-full bg-white text-text_blue-500 rounded shadow-md p-4 h-fit">
                                <p className="text-[1.1rem] font-semibold mb-4">Class Stream with Class Teacher</p>
                                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">

                                    <div className="grid w-full grid-cols-2 grid-rows-2  gap-2 ">
                                        {/* <div className="w-full mb-4 flex-col"> */}
                                        <select
                                            id="classCategory"
                                            value={selectedClassCategory}
                                            onChange={handleClassCategoryChange}
                                            className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                                        >
                                            <option value="">Select a category</option>
                                            {classCategories?.map((category) => (
                                                <option key={category.id} value={category.className}>
                                                    {category.className}
                                                </option>
                                            ))}
                                        </select>
                                        {/* <div className="w-full mb-4"> */}
                                        <select
                                            id="classSection"
                                            value={selectedClassSection}
                                            onChange={handleClassSectionChange}
                                            className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                                        >
                                            <option value="">Select a section</option>
                                            {classSections?.map((section) => (
                                                <option key={section.id} value={section.classSection}>
                                                    {section.classSection}
                                                </option>
                                            ))}
                                        </select>
                                        <select
                                            id="classTeacher"
                                            value={selectedClassTeacher}
                                            onChange={handleClassTeacherChange}
                                            className="w-full h-[55px] border-[2px] border-[#EBEDF2] rounded-lg shadow-sm focus:ring-[#22577A] focus:border-[#22577A] hover:border-[#B0B3B8] text-[12px] font-bold text-black px-3 outline-none"
                                        >
                                            <option value="">Select a teacher</option>
                                            {teacherList?.map((section) => (
                                                <option key={section.id} value={section._id}>
                                                    {section.name.firstName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex justify-end">
                                        <button type="submit" className="text-white rounded-lg bg-[#1A2A5A] px-3 py-2">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                            {/* List of Classes */}
                            <div className="w-full bg-white text-text_blue-500 rounded shadow-md p-4">
                                <div className="p-3">
                                    {onboardedClasses.length > 0 ? (
                                        <div className="p-4 font-poppins">
                                            <h2 className="text-[1.1rem] font-semibold mb-4">List Class</h2>

                                            {/* Search Filter */}
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search Class Section"
                                                fullWidth
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                sx={{
                                                    marginBottom: '1rem',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                            />

                                            {/* Table */}
                                            <TableContainer component={Paper} className='h-[30vh] overflow-y-scroll'>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className="text-[#1A2A5A] text-[0.77rem] leading-[18px] font-semibold">
                                                            <TableCell>No</TableCell>
                                                            <TableCell>Class Name</TableCell>
                                                            <TableCell>Class Section</TableCell>
                                                            <TableCell>Class Teacher</TableCell>
                                                            <TableCell>Stream</TableCell>
                                                            <TableCell>School</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {filteredClasses.length > 0 ? (
                                                            filteredClasses.map((classItem, index) => (
                                                                <TableRow key={classItem._id} className="overflow-y-scroll max-h-[300px]">
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>{classItem.className}</TableCell>
                                                                    <TableCell>{classItem.classSection || '-'}</TableCell>
                                                                    <TableCell>
                                                                        {classItem.classTeacher
                                                                            ? `${classItem.classTeacher.name.firstName} ${classItem.classTeacher.name.middleName || ''} ${classItem.classTeacher.name.lastName}`
                                                                            : '-'}
                                                                    </TableCell>
                                                                    <TableCell>{classItem.classStream || '-'}</TableCell>
                                                                    <TableCell>{classItem.school?.schoolName || '-'}</TableCell>
                                                                    <TableCell>
                                                                        <IconButton color="primary" onClick={() => handleEditClick(classItem)}>
                                                                            <Edit />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            color="secondary"
                                                                            onClick={() => handleDeleteClick(classItem._id)}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>

                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={7} align="center">
                                                                    No classes match the search criteria.
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </div>
                                    ) : (
                                        <p className="text-gray-600">No classes available.</p>
                                    )}
                                </div>
                            </div>

                        </section>
                    </main>
                    {/* Edit Dialog */}
                    <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                        <DialogTitle>Edit Class Category</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Class Category"
                                variant="outlined"
                                fullWidth
                                value={editClass?.category || ''}
                                onChange={(e) =>
                                    setEditClass((prev) => ({ ...prev, category: e.target.value }))
                                }
                                sx={{ marginTop: '1rem' }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                            <Button onClick={handleEditSave} color="primary">Save</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Fragment >

        </div >
    )
}

export default AdminClassStream
