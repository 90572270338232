import React, { useEffect, useState } from 'react';
import { myAllStudentList } from '../../../Services/ParentApi';
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { useSelector } from "react-redux";
import { formatName } from '../../../utils/utilityFunction';

const MyStudents = ({ onStudentSelect }) => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState('');
    const [error, setError] = useState(null);
    const { token } = useSelector((state) => state.auth);
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await myAllStudentList(token);
                if (response?.status === 200) {
                    setStudents(response.data.students);
                }
            } catch (err) {
                setError('Failed to load student list.');
            }
        };

        fetchStudents();
    }, [token]);

    const handleChange = (event) => {
        setSelectedStudent(event.target.value);
        console.log("event.target.value", event.target.value);
        onStudentSelect(event.target.value);
        if (onStudentSelect) {
            onStudentSelect(event.target.value);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <FormControl fullWidth>
            <Select
                labelId="student-select-label"
                value={selectedStudent}
                onChange={handleChange}
                displayEmpty
                sx={{
                    height: "48px", 
                    borderRadius: "8px", 
                    border: "2px solid #d1d5db", // Fix the border color and style
                    
                }}
            >
                <MenuItem value="" disabled>
                    Choose a student
                </MenuItem>
                {students.map((student) => (
                    <MenuItem key={student._id} value={student._id}>
                        {formatName(student)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MyStudents;
