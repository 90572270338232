import api from "./apiUtility";


export const myAllStudentList = async (token) => {
    try {
        const response = await api.get(`/parent/student-list`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const MyStudentAttendance = async (studentId, month, year) => {
    try {
        const response = await api.get(`/parent/student-attendance-list/${studentId}?month=${month}&year=${year}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const MyStudentAttendanceSummary = async (studentId, month, year) => {
    try {
        const response = await api.get(`/parent/student-attendance-summary/${studentId}?month=${month}&year=${year}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const MyStudentTransport = async (studentId) => {
    try {
        const response = await api.get(`/parent/get-transport-route-details-by-student/${studentId}`);
        return response;
    } catch (error) {
        throw error;
    }
} 

export const EventsList = async (className) => {
    try {
        const response = await api.get(`/parent/upcomming-events?studentClass=${className}`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const getmyProfile = async () => {
    try {
        const response = await api.get("/parent/my-profile");
        return response;
    } catch (error) {
        throw error;
    }
}
// 67518288171155b7ade4885b
export const getStudentAnalytics= async (studentId) => {
    try {
        const response = await api.get(`/parent/my-student-analytics/${studentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getChildrenResult = async (studentId) => {
    try {
        const response = await api.get(`/parent/my-child-results/${studentId}`)
        return response;
    } catch (error) {
        throw error;
    }
}

export const getChildrenResultOverview = async (resultId) => {
    try {
        const response = await api.get(`/parent/my-child-result-overview/${resultId}`)
        return response;
    } catch (error) {
        throw error;
    }
}


export const parentPushNotification = async () => {
    try {
        const response = await api.get(`/parent/my-notifications`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const ParentnotificationDetails = async (NotificationId) => {
    try {
        const response = await api.get(`/parent/push-notification-details/${NotificationId}`);
        return response;
    } catch (error) {
        throw error;
    }
}


// /parent/all-up-comming-holiday-list?studentClass=8

export const ParentUpcomingHoliday = async (studentClass) => {
    try {
        const response = await api.get(`/parent/all-up-comming-holiday-list?studentClass=${studentClass}`);
        return response;
    } catch (error) {
        throw error;
    }
}


// 

export const ParentFeeNotification = async (studentId) => {
    try {
        const response = await api.get(`/parent/fee-reminder-notification/${studentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}



export const ParentChildFeesHistoryAPi = async (studentId) => {
    try {
        const response = await api.get(`/parent/student-fee-history/${studentId}`)
        return response;
    } catch (error) {
        throw error
    }
}


export const ParentChildReportAPi = async (studentId, academicYear) => {
    try {
        const response = await api.get(`/parent/my-child-reports/${studentId}?academicYear=${academicYear}`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const childTimeTable = async (studentId) => {
    try {
        const response = await api.get(`/parent/time-table/${studentId}`);
        return response;
    } catch (error) {
        throw error;

    }
}

export const ChildLeaveList = async (studentId, status) => {
    try {
        const response = await api.get(`/parent/leave-applications-by-status?studentId=${studentId}&status=${status}`);
        return response;
    } catch (error) {
        throw error;

    }
}

export const ApplychildLeave = async (studentId) => {
    try {
        const response = await api.get(`/parent/time-table/${studentId}`);
        return response;
    } catch (error) {
        throw error;

    }
}
export const ApplychildforLeave = async (payload) => {
    try {
        const response = await api.post('/parent/apply-for-student-leave', payload)
        return response
    } catch (error) {
        throw error
    }
}