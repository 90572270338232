import React, { useEffect, useState } from 'react';
import { ParentnotificationDetails } from '../../../Services/ParentApi';
import { useParams } from 'react-router-dom';
import NotificationDetailComp from '../../Common/NotificationDetailComp';

const ParentNotificationDetails = () => {
    const { notificationId } = useParams(); // Get notificationId from the route parameter
    const [notificationData, setNotificationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    console.log(notificationId);
    useEffect(() => {
        // Fetch notification details
        const fetchNotificationDetails = async () => {
            try {
                const response = await ParentnotificationDetails(notificationId); // Pass notificationId in the request body
                if (response?.data?.data) {
                    setNotificationData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching notification details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotificationDetails();
    }, [notificationId]);

    if (isLoading) {
        return <p className="text-center text-gray-500">Loading notification details...</p>;
    }

    if (!notificationData) {
        return <p className="text-center text-gray-500">Notification not found.</p>;
    }

    return (
        <NotificationDetailComp notificationData={notificationData} />
    );
};

export default ParentNotificationDetails;
