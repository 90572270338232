import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import { Link } from "react-router-dom";
import { getMySubjectNameList } from "../../../Services/StudentApis";
import { useSearchParams } from "react-router-dom";
import { getAllClassCategoryList, getAllSectionList } from "../../../Services/TeachersApis";
const MYStudentSubjectsList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [mySubjectNameList, setMySubjectNameList] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedClassName, setSelectedClassName] = useState("");
    const [selectedSection, setSelectedSection] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMySubjectNameList(selectedClassName, selectedSection);
                // Assuming response?.data?.subjects contains the list of subjects
                setMySubjectNameList(response?.data || []);
            } catch (error) {
                console.error("Error fetching subject list:", error);
            }
        };
        fetchData();
    }, [searchParams]);


    useEffect(() => {
        const fetchSectionAndClassCategoryData = async () => {
            try {
                const categoriesResponse = await getAllClassCategoryList();
                const subjectResponse = await getAllSectionList();

                if (categoriesResponse.status === 200 && subjectResponse.status === 200) {
                    setCategoryData(categoriesResponse?.data?.classCategories || []);
                    setSubjectData(subjectResponse?.data?.sections || []);
                } else {
                    setCategoryData([]);
                    setSubjectData([]);
                }
            } catch (error) {
                console.error(error);
                setCategoryData([]);
                setSubjectData([]);
            }
        };

        fetchSectionAndClassCategoryData();
    }, []);

    const handleClassNameChange = (event) => {
        const className = event.target.value;
        setSelectedClassName(className);
        setSearchParams({ className, section: selectedSection });
    };

    // Handle section change
    const handleSectionChange = (event) => {
        const section = event.target.value;
        setSelectedSection(section);
        setSearchParams({ className: selectedClassName, section });
    };
    return (
        <section>
            <Headingtitle title="Selected Subjects " />
            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[80vh]">

                <section className="grid grid-cols-12 gap-6 p-4">
                    {/* Class Name Dropdown */}
                    <div className="col-span-6">

                        <select
                            id="className"
                            value={selectedClassName}
                            onChange={handleClassNameChange}
                            className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                        >
                            <option value="">-- Select Class --</option>
                            {categoryData.map((category) => (
                                <option key={category._id} value={category.className}>
                                    {category.className}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Section Dropdown */}
                    <div className="col-span-6">

                        <select
                            id="section"
                            value={selectedSection}
                            onChange={handleSectionChange}
                            className="w-full h-12 border border-gray-300 rounded-lg px-4 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 shadow-sm"
                        >
                            <option value="">-- Select Section --</option>
                            {subjectData.map((section) => (
                                <option key={section._id} value={section.classSection}>
                                    {section.classSection}
                                </option>
                            ))}
                        </select>
                    </div>
                </section>
                <table className="min-w-full bg-white rounded-lg border-collapse">
                    <thead className="rounded-lg">
                        <tr className="bg-bg_blue-25 text-[1.1rem] text-left text-text_blue-500 rounded-t-lg">
                            <th className="p-4 border-b-2">No</th>
                            <th className="p-4 border-b-2">Subject Name</th>
                            <th className="p-4 border-b-2">Code</th>
                            <th className="p-4 border-b-2">Type</th>
                            <th className="p-4 border-b-2">Teacher</th>
                        </tr>
                    </thead>
                    <tbody className="border-b-[1px] border-border-50 text-text_Black-75 font-poppins">
                        {mySubjectNameList?.length > 0 ? (
                            mySubjectNameList.map((subject, index) => (
                                <tr key={subject._id} className="font-semibold text-text_Black-75">
                                    <td className="p-4 border-r border-l">{index + 1}</td>
                                    <td className="p-4 border-r">
                                        <Link to={`/student/dashboard/detail/subject/${subject._id}`}>
                                            <span className="bg-green-100 text-text_Black-75 text-center py-1 px-4 rounded-full inline-block">
                                                {subject.subjectName || "N/A"}
                                            </span>
                                        </Link>
                                    </td>
                                    <td className="p-4 border-r">{subject.code || "N/A"}</td>
                                    <td className="p-4 border-r">{subject.type || "N/A"}</td>
                                    <td className="p-4 border-r">{subject.teacher || "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="p-4 text-center text-gray-500">
                                    No subjects found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table> </div>
        </section>
    );
};

export default MYStudentSubjectsList;
