import React from 'react';
import StudentAttendanceListComp from '../../Common/StudentAttendanceListComp';
import {myAllAttendanceList} from '../../../Services/StudentApis'

const StudentAttendanceList = () => {

  return (
    <StudentAttendanceListComp
      fetchAttendance={myAllAttendanceList}
      headingTitle="Student Attendance List"
      isParentSection={false}
    />
  );
};

export default StudentAttendanceList;
