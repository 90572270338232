import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import toast from 'react-hot-toast';
import { createNewRoute } from "../../../Services/AdminApis";

const CreateNewRoute = () => {
    const [routeData, setRouteData] = useState({
        vehicleNumber: "",
        driverName: "",
        emergencyContactNumber: "",
        currentLocation: {
            type: "Point",
            coordinates: [0, 0], // [longitude, latitude], initialized as numbers
        },
        routeNumber: "",
        routePoints: [
            {
                locationName: "",
                location: {
                    type: "Point",
                    coordinates: [0, 0], // [longitude, latitude], initialized as numbers
                },
                pickUpTime: "",
                dropOffTime: "",
            },
        ],
    });

    // Handle changes for basic fields
    const handleFieldChange = (field, value) => {
        setRouteData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    // Helper function to convert DMS to decimal degrees
    const convertToDecimal = (degrees, minutes, seconds) => {
        return degrees + minutes / 60 + seconds / 3600;
    };

    const handleLocationChange = (index, value) => {
        const updatedCoordinates = [...routeData.currentLocation.coordinates];
    
        if (value === "") {
            // Allow empty string for blank fields
            updatedCoordinates[index] = "";
        } else {
            // Parse valid numbers only
            const newValue = parseFloat(value);
            updatedCoordinates[index] = !isNaN(newValue) ? newValue : updatedCoordinates[index];
        }
    
        setRouteData((prev) => ({
            ...prev,
            currentLocation: {
                ...prev.currentLocation,
                coordinates: updatedCoordinates,
            },
        }));
    };
    

    const handleRoutePointChange = (index, field, value) => {
        const updatedRoutePoints = [...routeData.routePoints];
    
        if (field === "coordinates") {
            // Ensure coordinates are set properly even if one field is empty
            const updatedCoordinates = value.map((v) => (v === "" ? "" : parseFloat(v))); // Keep empty string if the input is cleared
            updatedRoutePoints[index].location.coordinates = updatedCoordinates;
        } else {
            updatedRoutePoints[index][field] = value;
        }
    
        setRouteData((prev) => ({
            ...prev,
            routePoints: updatedRoutePoints,
        }));
    };
    

    // Add a new route point
    const addRoutePoint = () => {
        setRouteData((prev) => ({
            ...prev,
            routePoints: [
                ...prev.routePoints,
                {
                    locationName: "",
                    location: {
                        type: "Point",
                        coordinates: [0, 0], // Initialize coordinates as numbers
                    },
                    pickUpTime: "",
                    dropOffTime: "",
                },
            ],
        }));
    };

    // Remove a route point
    const removeRoutePoint = (index) => {
        const updatedRoutePoints = routeData.routePoints.filter((_, i) => i !== index);
        setRouteData((prev) => ({
            ...prev,
            routePoints: updatedRoutePoints,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createNewRoute(routeData);
            if (response.status === 201 || response.status === 200) {
                toast.success("Successfully Created Route");
                resetForm();
            } else {
                toast.error("Error While Creating Route");
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error);
        }

        console.log("Route Data Submitted:", routeData);
    };

    const resetForm = () => {
        setRouteData({
            vehicleNumber: "",
            driverName: "",
            emergencyContactNumber: "",
            currentLocation: {
                type: "Point",
                coordinates: [0, 0], // [longitude, latitude], initialized as numbers
            },
            routeNumber: "",
            routePoints: [
                {
                    locationName: "",
                    location: {
                        type: "Point",
                        coordinates: [0, 0], // [longitude, latitude], initialized as numbers
                    },
                    pickUpTime: "",
                    dropOffTime: "",
                },
            ],
        })
    }

    return (
        <div className="bg-gray-100 p-5 rounded-lg font-poppins">
            <h1 className="text-xl font-bold mb-5">Create New Route</h1>
            <form onSubmit={handleSubmit} className="space-y-10">
                {/* Vehicle Details */}
                <div className="bg-white p-5 rounded-lg shadow">
                    <h2 className="text-lg font-semibold mb-4">Vehicle Details</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="font-medium">Vehicle Number</label>
                            <input
                                type="text"
                                value={routeData.vehicleNumber}
                                onChange={(e) => handleFieldChange("vehicleNumber", e.target.value)}
                                placeholder="Enter Vehicle Number"
                                className="border border-gray-300 rounded-lg p-2 w-full"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-medium">Driver Name</label>
                            <input
                                type="text"
                                value={routeData.driverName}
                                onChange={(e) => handleFieldChange("driverName", e.target.value)}
                                placeholder="Enter Driver Name"
                                className="border border-gray-300 rounded-lg p-2 w-full"
                            />
                        </div>

                        <div className="h-[80px] flex flex-col col-span-2">
                            <label className="font-medium">Emergency Contact Number</label>
                            <PhoneInput
                                country="in"
                                value={routeData.emergencyContactNumber}
                                onChange={(value) => handleFieldChange("emergencyContactNumber", value)}
                                containerClass="w-full"
                                inputStyle={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    height: "40px",
                                    border: "2px solid #EBEDF2",
                                }}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="font-medium">Route Number</label>
                            <input
                                type="text"
                                value={routeData.routeNumber}
                                onChange={(e) => handleFieldChange("routeNumber", e.target.value)}
                                placeholder="Enter Route Number"
                                className="border border-gray-300 rounded-lg p-2 w-full"
                            />
                        </div>
                    </div>
                </div>

                {/* Current Location */}
                <div className="bg-white p-5 rounded-lg shadow">
                    <h2 className="text-lg font-semibold mb-4">Current Location</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="font-medium">Longitude</label>
                            <input
                                type="number"
                                value={routeData.currentLocation.coordinates[0]}
                                onChange={(e) => handleLocationChange(0, e.target.value)}
                                placeholder="Enter Longitude"
                                className="border border-gray-300 rounded-lg p-2 w-full"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-medium">Latitude</label>
                            <input
                                type="number"
                                value={routeData.currentLocation.coordinates[1]}
                                onChange={(e) => handleLocationChange(1, e.target.value)}
                                placeholder="Enter Latitude"
                                className="border border-gray-300 rounded-lg p-2 w-full"
                            />
                        </div>
                    </div>
                </div>

                {/* Route Points */}
                <div className="bg-white p-5 rounded-lg shadow">
                    <h2 className="text-lg font-semibold mb-4">Route Points</h2>
                    {routeData.routePoints.map((point, index) => (
                        <div key={index} className="border border-gray-300 p-4 rounded-lg mb-4">
                            <div className="flex justify-between items-center mb-2">
                                <h3 className="font-medium">Route Point {index + 1}</h3>
                                <button
                                    type="button"
                                    onClick={() => removeRoutePoint(index)}
                                    className="text-red-500 text-sm"
                                >
                                    Remove
                                </button>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col">
                                    <label className="font-medium">Location Name</label>
                                    <input
                                        type="text"
                                        value={point.locationName}
                                        onChange={(e) =>
                                            handleRoutePointChange(index, "locationName", e.target.value)
                                        }
                                        placeholder="Enter Location Name"
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-medium">Longitude</label>
                                    <input
                                        type="number"
                                        value={point.location.coordinates[0]}
                                        onChange={(e) =>
                                            handleRoutePointChange(index, "coordinates", [
                                                e.target.value,
                                                point.location.coordinates[1],
                                            ])
                                        }
                                        
                                        placeholder="Enter Longitude"
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-medium">Latitude</label>
                                    <input
                                        type="number"
                                        value={point.location.coordinates[1]}
                                        onChange={(e) =>
                                            handleRoutePointChange(index, "coordinates", [
                                                point.location.coordinates[0],
                                                e.target.value,
                                            ])
                                        }
                                        placeholder="Enter Latitude"
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    />
                                </div>
                                <div className="flex flex-col col-span-2">
                                    <label className="font-medium">Pick Up Time</label>
                                    <input
                                        type="time"
                                        value={point.pickUpTime}
                                        onChange={(e) =>
                                            handleRoutePointChange(index, "pickUpTime", e.target.value)
                                        }
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    />
                                </div>
                                <div className="flex flex-col col-span-2">
                                    <label className="font-medium">Drop Off Time</label>
                                    <input
                                        type="time"
                                        value={point.dropOffTime}
                                        onChange={(e) =>
                                            handleRoutePointChange(index, "dropOffTime", e.target.value)
                                        }
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addRoutePoint}
                        className="text-blue-500 text-sm"
                    >
                        Add Route Point
                    </button>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center w-full">
                    <button
                        type="submit"
                        className="bg-blue-500 w-full text-white px-6 py-2 rounded-lg shadow-md"
                    >
                        Create Route
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateNewRoute;
