import React, { useEffect, useState, useTransition } from 'react';
import { myPushNotification } from '../../../Services/StudentApis';
import Headingtitle from '../../Common/Headingtitle';
import { useNavigate } from 'react-router-dom';
import NotificationComp from '../../Common/NotificationComp';

const Notification = () => {
    const navigate=useNavigate();
    const [notificationList, setNotificationList] = useState([]);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            myPushNotification()
                .then((response) => {
                    if (response?.data) {
                        setNotificationList(response.data?.data);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching notifications:', error);
                });
        });
    }, []);

    const handleNavigate = (id) => {
        navigate(`notification-detail/${id}`);
      };
      

    return (
       <NotificationComp isPending={isPending} notificationList={notificationList} handleNavigate={handleNavigate}/>


    );
};

export default Notification;
