import React from 'react';
import StudentAttendanceListComp from '../../Common/StudentAttendanceListComp';
import { MyStudentAttendance } from '../../../Services/ParentApi';

const MyStudentAttendanceList = () => {
    return (
        <div>
            <StudentAttendanceListComp
                fetchAttendance={MyStudentAttendance}
                headingTitle="My Student Attendance List"
                isParentSection={true}
            />
        </div>
    )
}

export default MyStudentAttendanceList


