import React, { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import Avtar from "../../../Assests/Logos/avtar.png"
import Headingtitle from "../../Common/Headingtitle";
import { getTeacherHoliday } from "../../../Services/TeachersApis";

const HolidayList = () => {
    const [holidayList, setHolidayList] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchHolidayResponse = async () => {
            try {
                const response = await getTeacherHoliday();
                setHolidayList(response?.data?.holidays || []);

            } catch (error) {
                setHolidayList([]);
                console.error("error", error)
            }
        }
        fetchHolidayResponse();
    }, [])
    return (
        <div>
            <div className="font-poppins">
                <Headingtitle title="Leaves" />
                <main className="bg-white shadow-md rounded-md p-5  ">
                    <p className="font-semibold pb-2 text-[1rem] "> Leaves List</p>
                    <div>
                        {loading ? (
                            <p className="text-gray-500">Loading...</p>
                        ) : holidayList && holidayList.length > 0 ? (
                            <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                                <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                                    <tr>
                                        <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                        <th className="px-6 py-4 text-left text-[16px] font-bold">Holiday Name</th>
                                        <th className="px-6 py-4 text-left text-[16px] font-bold">Applicable Classes</th>
                                        <th className="px-6 py-4 text-left text-[16px] font-bold">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {holidayList.map((record, index) => (
                                        <tr
                                            key={record._id}
                                            className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                                } hover:bg-indigo-100 transition-all`}
                                        >
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {new Date(record.date).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.name}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.applicableClasses.includes("All")
                                                    ? "All Classes"
                                                    : record.applicableClasses.join(", ")}
                                            </td>
                                            <td className="px-6 py-4 text-gray-700 text-[15px]">
                                                {record.description}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="text-gray-500 mt-6">No Attendance Records Found</p>
                        )}
                    </div>

                </main>
            </div>
        </div>
    )
}

export default HolidayList