import React, { useEffect, useState } from "react";
import Headingtitle from "../../Common/Headingtitle";
import avtar from "../../../Assests/Logos/avtar.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyStudentTransport } from '../../../Services/ParentApi';
import MyStudents from './MyStudentList';

const driverData = [
    {
        id: 1,
        name: "Narendra",
        email: "Watashino@gmail.com",
        contact: "9999999",
        address: "Noida, Sector – 1 Uttar Pradesh 2001100",
        busNo: 22
    },
    {
        id: 2,
        name: "Ankita",
        email: "ankita@gmail.com",
        contact: "8888888",
        address: "Delhi, Sector – 18 Delhi 110000",
        busNo: 23
    },
    {
        id: 3,
        name: "Rahul",
        email: "rahul@example.com",
        contact: "7777777",
        address: "Gurgaon, Sector – 45 Haryana 122018",
        busNo: 24
    },
    // Add more driver data objects here
];

const TransPortVehicletList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [transportDetails, setTransportDetails] = useState()
    const navigate = useNavigate();
    const handleViewBusRoute = (driver) => {
        localStorage.setItem('selectedDriver', JSON.stringify(driver));
        navigate("/parent/dashboard/detail/transportRoute")
    };

    const studentId = searchParams.get('studentId');

    const updateQueryParams = (updatedParams) => {
        setSearchParams((prev) => {
            const newParams = { ...Object.fromEntries(prev.entries()), ...updatedParams };
            return new URLSearchParams(newParams);
        });
    };

    const fetchAttendanceData = async (studentId) => {
        try {
            setIsLoading(true);
            if (!studentId) {
                return;
            }
            const response = await MyStudentTransport(studentId)
            setTransportDetails(response?.data?.routeDetails?.vehicle);
        } catch (error) {
            console.error('Error fetching attendance:', error);
            setTransportDetails();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAttendanceData(studentId)
    }, [studentId])

    const handleStudentSelect = (id) => {
        updateQueryParams({ studentId: id });
    };
    return (
        <section>
            <Headingtitle title="Transport Vehicle List" />
            <MyStudents onStudentSelect={handleStudentSelect} />

            <div className="overflow-x-auto p-3 text-[0.77rem] relative min-h-[78vh]">
                {isLoading ? (
                    <p className="text-center text-gray-500">Loading...</p>
                ) :
                    <>
                        {transportDetails ?
                            <section
                                className="p-4 flex flex-col bg-white text-[0.86rem] rounded-lg border-[1px] border-border-100 mb-4"
                            >
                                <div className="flex justify-between items-start p-5 ">
                                    {/* Driver Info */}
                                    <div className="flex gap-4 items-start">
                                        {/* Profile Image */}
                                        <img
                                            src={avtar}
                                            alt="Driver"
                                            className="w-[60px] h-[60px] rounded-full object-cover"
                                        />
                                        {/* Driver Details */}
                                        <div className="flex flex-col text-[0.88rem]">
                                            <div className="flex flex-col space-y-2">
                                                <p>Vehicle Number – <span className="font-semibold">{transportDetails?.vehicleNumber}</span></p>
                                                <p>Route Number – <span className="font-semibold">{transportDetails?.routeNumber}</span></p>
                                                <p>Driver name – <span className="font-semibold">{transportDetails?.driverName}</span></p>
                                                {/* <p>Driver Email – <span className="font-semibold">{driver.email}</span></p>
                                                <p>Driver Contact no – <span className="font-semibold">{driver.contact}</span></p>
                                                <p>Driver Address – <span className="font-semibold">{driver.address}</span></p> */}
                                                <p>Emergency Contact – <span className="font-semibold">{transportDetails?.emergencyContactNumber}</span></p>
                                            </div>
                                            <div className="flex flex-col space-y-4 relative my-4">
                                                {transportDetails?.routePoints &&
                                                    transportDetails?.routePoints.map((item, index) => (
                                                        <div key={index} className="flex items-center relative">
                                                            <div
                                                                className={`relative z-10 w-full pl-8 py-4 rounded-md ${index === 0
                                                                    ? 'bg-blue-100 shadow-md'
                                                                    : 'bg-white'
                                                                    }`}
                                                            >
                                                                {index === 0 ? (
                                                                    <>
                                                                        <p className="text-lg font-bold text-blue-600">
                                                                            School Name –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.studentName}
                                                                            </span>
                                                                        </p>
                                                                        <p className="text-gray-700">
                                                                            Address –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.locationName}
                                                                            </span>
                                                                        </p>
                                                                        <p className="text-gray-700">
                                                                            Morning Departure Time –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.pickUpTime}
                                                                            </span>
                                                                        </p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="flex items-center w-full my-2">
                                                                             <p className="h-[50px] border-2 border-[rgb(9,44,71)]"></p>
                                                                        </div>

                                                                        <p className="text-lg font-bold text-green-600">
                                                                            Student Name –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.studentName}
                                                                            </span>
                                                                        </p>
                                                                        <p className="text-gray-700">
                                                                            Address –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.locationName}
                                                                            </span>
                                                                        </p>
                                                                        <p className="text-gray-700">
                                                                            Morning Departure Time –{' '}
                                                                            <span className="font-semibold">
                                                                                {item?.pickUpTime}
                                                                            </span>
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>



                                            <div className="mt-4 flex">
                                                <button
                                                    // onClick={() => handleViewBusRoute(driver)}
                                                    className="px-4 py-2 bg-text_blue-500 text-white font-semibold rounded-md">
                                                    View Bus Route
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Bus Info */}
                                    <div className="text-right">
                                        <p className="font-semibold">Route No.: {transportDetails.routeNumber}</p>
                                    </div>
                                </div>
                            </section>
                            : <p>No Data Available</p>
                        }
                    </>
                }
            </div>
        </section>
    );
};

export default TransPortVehicletList;
