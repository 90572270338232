import React, { useEffect, useState } from 'react';
import { getExamTimetables, GetClassCategoryList } from '../../../Services/AdminApis';
import Headingtitle from '../../Common/Headingtitle';
import { TextField, Button, MenuItem } from '@mui/material';

const ExamTimetableList = () => {
    const [filters, setFilters] = useState({
        className: '',
        examName: '',
        startDate: '',
        endDate: '',
    });
    const [examTimetables, setExamTimetables] = useState([]);
    const [classList, setClassList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const fetchExamTimetables = async (filters) => {
        setLoading(true);
        try {
            const response = await getExamTimetables(filters);
            setExamTimetables(response?.data?.examTimetables || []);
        } catch (error) {
            console.error('Error fetching exam timetables:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchExamTimetables(filters);
    }, []);

    const handleSearch = () => {
        let searchPayload={...filters}
        if (filters.className === "All") {
         searchPayload={...filters,className:""}
        }
        fetchExamTimetables(searchPayload);
    };

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await GetClassCategoryList();
                setClassList(response?.data?.classCategories || []);
                console.log("response?.data?.classCategories", response?.data?.classCategories)
            } catch (error) {
                console.error("Error fetching class list:", error);
            }
        };
        fetchClassList();
    }, []);
    return (
        <div className="font-poppins">
            <Headingtitle title="Exam Timetables" />
            <main className="bg-white shadow-md rounded-md p-5">
                <p className="font-semibold pb-2 text-[1rem]">Exam Timetable List</p>
                <div className="flex gap-4 mb-4">
                    <TextField
                        label="Class Name"
                        name="className"
                        value={filters.className}
                        onChange={handleFilterChange}
                        select
                        fullWidth
                    >
                        <MenuItem value="All">All</MenuItem>
                        {classList.map((item) => (
                            <MenuItem key={item._id} value={item.className}>{item.className}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Exam Name"
                        name="examName"
                        value={filters.examName}
                        onChange={handleFilterChange}
                        fullWidth
                    />
                    <TextField
                        label="Start Date"
                        name="startDate"
                        type="date"
                        value={filters.startDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <TextField
                        label="End Date"
                        name="endDate"
                        type="date"
                        value={filters.endDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        Search
                    </Button>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <table className="min-w-full border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Class Name
                                </th>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Exam Name
                                </th>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Subject
                                </th>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Exam Date
                                </th>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Time
                                </th>
                                <th className="border border-gray-300 px-4 py-3 text-left text-[1rem] font-semibold">
                                    Notes
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {examTimetables.map((exam) => (
                                <tr key={exam._id} className="hover:bg-indigo-100 transition-all">
                                    <td className="border border-gray-300 px-4 py-2">{exam.className}</td>
                                    <td className="border border-gray-300 px-4 py-2">{exam.examName}</td>
                                    <td className="border border-gray-300 px-4 py-2">{exam.subject}</td>
                                    <td className="border border-gray-300 px-4 py-2">
                                        {new Date(exam.examDate).toLocaleDateString()}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">
                                        {exam.startTime} - {exam.endTime}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">{exam.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </main>
        </div>
    );
};

export default ExamTimetableList;
