import React, { useState, useEffect } from 'react';
import Headingtitle from '../../Common/Headingtitle'; // Ensure this component exists
import {
    getAllSectionList,
    getAllClassList,
    getAllClassCategoryList,
    getStudentListofClassById
} from '../../../Services/TeachersApis';

import ResultStudentCardList from '../../Common/StudentCard';
const ResultStudentList = () => {
    const [schoolId, setSchoolId] = useState('');
    const [sectionList, setSectionList] = useState([]);
    const [classCategoryList, setClassCategoryList] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subjectList, setSubjectList] = useState([]);
    const [error, setError] = useState(null);

    // Fetch initial data for class categories and sections
    const fetchMyClassData = async () => {
        try {
            const sectionResponse = await getAllSectionList();
            const myClassResponse = await getAllClassList();
            const classCategoryResponse = await getAllClassCategoryList();

            if (sectionResponse.status === 200 && classCategoryResponse.status === 200 && myClassResponse.status === 200) {
                setSectionList(sectionResponse?.data?.sections || []);
                setSchoolId(myClassResponse?.data?.classesDetails?.school);
                setClassCategoryList(classCategoryResponse?.data?.classCategories || []);
            }
        } catch (error) {
            setError('Failed to fetch class or section data.');
            console.error(error);
        }
    };

    // Fetch subject list based on selected class and section
    useEffect(() => {
        const fetchData = async () => {
            if (selectedCategory && selectedSection) {
                try {
                    const response = await getStudentListofClassById( selectedCategory, selectedSection);
                    if (response.status === 200) {
                        setSubjectList(response.data || []);
                    }
                } catch (error) {
                    setError('Error fetching subject list.');
                    console.error(error);
                }
            }
        };

        fetchData();
    }, [schoolId, selectedCategory, selectedSection]);

    // Initial fetch of class and section data
    useEffect(() => {
        fetchMyClassData();
    }, []);

    return (
        <div className="font-poppins">
            <Headingtitle title="Students" />
            <main className="bg-white rounded-md p-6 text-gray-800 relative min-h-[80vh] shadow-lg">
                <p className="font-semibold text-[1.25rem] pb-4 border-b border-gray-300">
                   Create Student Result
                </p>

                {/* Error Message */}
                {error && <p className="text-red-500">{error}</p>}

                {/* Dropdowns for Class and Section */}
                <div className="grid w-full grid-cols-12 gap-5 grid-rows-2 text-[0.85rem] mb-6">
                    {/* Class Category Dropdown */}
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Class Name</option>
                        {classCategoryList.length > 0 &&
                            classCategoryList.map((data) => (
                                <option key={data._id} value={data.className}>
                                    {data.className}
                                </option>
                            ))}
                    </select>

                    {/* Section Dropdown */}
                    <select
                        value={selectedSection}
                        onChange={(e) => setSelectedSection(e.target.value)}
                        className="col-span-6 border rounded-md p-3 bg-gray-50 shadow-sm "
                    >
                        <option value="">Select Section</option>
                        {sectionList.length > 0 &&
                            sectionList.map((data) => (
                                <option key={data._id} value={data.classSection}>
                                    {data.classSection}
                                </option>
                            ))}
                    </select>
                </div>

                {/* Subject List */}
                <div>
                    {subjectList.length > 0 ? (
                        <ResultStudentCardList subjectList={subjectList} />
                    ) : (
                        <p>No subjects available for the selected class and section.</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default ResultStudentList;
