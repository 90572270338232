import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AttendendancePage.css';
import Headingtitle from '../../Common/Headingtitle';
import { useSearchParams } from 'react-router-dom';
import { myAttendanceSummary } from '../../../Services/StudentApis';
import toast from 'react-hot-toast';

const AcademicCalender = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [totalPresent, setTotalPresent] = useState(0); // Stores total present days
    const [totalAbsent, setTotalAbsent] = useState(0);  // Stores total absent days
    const [searchParams, setSearchParams] = useSearchParams();

    // Update query parameters in the URL
    const updateQueryParams = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is 0-based, so add 1
        setSearchParams({ year, month });
    };

    // Handle manual date changes
    const handleDateChange = (date) => {
        setSelectedDate(date);
        updateQueryParams(date);
    };

    // Handle previous month navigation
    const handlePrevMonth = () => {
        const prevMonth = new Date(selectedDate);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setSelectedDate(prevMonth);
        updateQueryParams(prevMonth);
    };

    // Handle next month navigation
    const handleNextMonth = () => {
        const nextMonth = new Date(selectedDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setSelectedDate(nextMonth);
        updateQueryParams(nextMonth);
    };

    // Fetch attendance data based on query parameters
    useEffect(() => {
        const year = searchParams.get('year');
        const month = searchParams.get('month');
        if (year && month) {
            const fetchAttendanceSummary = async () => {
                try {
                    const response = await myAttendanceSummary(year, month);
                    if (response?.status === 200 || response?.status === 201) {
                        const { totalPresent, totalAbsent } = response.data?.attendanceSummary[0] || {};
                        console.log(totalPresent,totalAbsent);

                        setTotalPresent(totalPresent || 0); // Default to 0 if data is missing
                        setTotalAbsent(totalAbsent || 0);  // Default to 0 if data is missing
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.message || 'Failed to fetch attendance data.');
                }
            };
            fetchAttendanceSummary();
        }
    }, [searchParams]);

    return (
        <div className="font-poppins">
            <Headingtitle title="Academic" />
            <main className="bg-white relative w-full rounded-md p-5 min-h-[80vh]">
                <div className="p-5 rounded-lg w-[100%] h-[80vh] overflow-y-scroll">
                    <div className="flex justify-between items-center w-[100%] p-5 mx-auto">
                        {/* Calendar Section */}
                        <div className="w-[60%] h-[420px] mx-auto flex justify-center items-center">
                            <div className="flex flex-col w-[60%] h-[60%] justify-center items-center">
                                <div className="flex text-center space-x-3">
                                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
                                        <p key={index} className="text-[10px] font-semibold text-text_gray-100">
                                            {day.toUpperCase()}
                                        </p>
                                    ))}
                                </div>
                                {/* DatePicker */}
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    inline
                                    className="text-center custom-datepicker"
                                    renderCustomHeader={() => null} // Removes month/year selector
                                    showPopperArrow={false}
                                    dateFormat="dd"
                                />
                            </div>
                        </div>

                        {/* Attendance Summary */}
                        <div className="w-[40%] h-[300px] flex flex-col space-y-32 py-5 items-center">
                            {/* Month and Year Navigation */}
                            <div className="flex items-center w-[355px] space-x-2 border-[1px] border-border-100 rounded-lg px-3 py-2">
                                <button
                                    onClick={handlePrevMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowLeft />
                                </button>
                                <div className="text-[0.88rem] font-semibold w-[280px] text-center">
                                    {selectedDate.toLocaleString('default', { month: 'long' })}{' '}
                                    {selectedDate.getFullYear()}
                                </div>
                                <button
                                    onClick={handleNextMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowRight />
                                </button>
                            </div>

                            {/* Present and Absent Cards */}
                            <div className="flex space-x-10">
                                {/* Total Present */}
                                <div className="bg-green-500 flex flex-col items-center justify-center text-white p-5 w-[120px] aspect-square rounded-md text-center">
                                    <div>Total Present</div>
                                    <div className="text-[0.77rem] rounded-full font-semibold bg-white p-3 w-fit text-black">
                                        {totalPresent}
                                    </div>
                                </div>
                                {/* Total Absent */}
                                <div className="bg-red-500 flex flex-col items-center justify-center text-white p-5 rounded-md w-[120px] aspect-square">
                                    <div>Total Absent</div>
                                    <div className="text-[0.77rem] rounded-full font-semibold bg-white p-3 w-fit text-black">
                                        {totalAbsent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AcademicCalender;
