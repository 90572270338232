import React, { useTransition, useEffect, useState } from 'react';
import { myUpcomingExam } from '../../../Services/StudentApis';

const StudentExamTimeTable = () => {
    const [isPending, startTransition] = useTransition();
    const [examList, setExamList] = useState([]);

    useEffect(() => {
        startTransition(() => {
            myUpcomingExam()
                .then((response) => {
                    setExamList(response?.data?.exams || []);
                })
                .catch((error) => {
                    console.error("Error Fetching Data", error);
                });
        });
    }, []);

    return (
        <div className="font-poppins">
            <h2 className="text-2xl font-bold mb-4">Upcoming Exams</h2>

            {isPending ? (
                <p>Loading exams...</p> // Show loading message while data is fetching
            ) : examList.length > 0 ? (
                <div className='max-w-full overflow-x-scroll '>

                    <table className="min-w-full w-[1530px] border-collapse bg-white rounded-lg overflow-hidden shadow-md mt-6">
                        <thead className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <tr>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Class</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Section</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Exam Name</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Subject</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Date</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Time</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Duration</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Total Marks</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Passing Marks</th>
                                <th className="px-6 py-4 text-left text-[16px] font-bold">Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examList.map((exam, index) => (
                                <tr
                                    key={exam._id}
                                    className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                                        } hover:bg-indigo-100 transition-all`}
                                >
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.className}</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {exam.section.join(", ")}
                                    </td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.examName}</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.subject}</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {new Date(exam.examDate).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">
                                        {exam.startTime} - {exam.endTime}
                                    </td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.duration} mins</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.totalMarks}</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.passingMarks}</td>
                                    <td className="px-6 py-4 text-gray-700 text-[15px]">{exam.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-gray-500 mt-6">No Upcoming Exams Found</p> // Show message if no exams are found
            )}
        </div>
    );
};

export default StudentExamTimeTable;
