import React from 'react'
import EventAndNotice from '../../Common/EventAndNotice'

const EventAndAnnouncement = () => {
  return (
   <EventAndNotice/>
  )
}

export default EventAndAnnouncement
