import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Headingtitle from '../../Common/Headingtitle';
import { getAlltranportRoute } from '../../../Services/AdminApis';

const columns = [
  { field: 'id', headerName: 'No.', width: 70 },
  { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
  { field: 'driverName', headerName: 'Driver Name', width: 150 },
  { field: 'routeNumber', headerName: 'Route Number', width: 150 },
  { field: 'emergencyContactNumber', headerName: 'Emergency Contact', width: 180 },
  {
    field: 'coordinates',
    headerName: 'Location Coordinates',
    width: 250,
    renderCell: (params) => {
      const { coordinates } = params.row.currentLocation;
      return `${coordinates[0]}, ${coordinates[1]}`;
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    renderCell: () => (
      <IconButton color="primary">
        <EditIcon />
      </IconButton>
    ),
  },
];

const TransportRouteList = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch routes data from the API
  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await getAlltranportRoute();
        const routeData = response.data.routes;

        // Format API data for the DataGrid
        const formattedRows = routeData.map((route, index) => ({
          id: index + 1,
          vehicleNumber: route.vehicle.vehicleNumber,
          driverName: route.vehicle.driverName,
          routeNumber: route.vehicle.routeNumber,
          emergencyContactNumber: route.vehicle.emergencyContactNumber,
          currentLocation: route.vehicle.currentLocation, // Coordinates should be part of this object
        }));

        setRoutes(formattedRows);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching routes:', error);
        setLoading(false);
      }
    };

    fetchRoutes();
  }, []);

  return (
    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
      <Headingtitle title="Transport Routes" />
      <DataGrid
        style={{ backgroundColor: 'white' }}
        rows={routes}
        columns={columns}
        loading={loading}
        pageSize={10}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
      />
    </div>
  );
};

export default TransportRouteList;
