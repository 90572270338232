import React, { useState, useEffect, useTransition } from 'react';
import { myFeeNotification } from '../../../Services/StudentApis';
import FeesTableComponent from '../../Common/FeesTableComponent';

const MyFees = () => {
    const [isPending, startTransition] = useTransition();
    const [notifications, setNotifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        startTransition(() => {
            myFeeNotification()
                .then((response) => {
                    setNotifications(response?.data?.notifications || []);
                })
                .catch((error) => {
                    console.error("Error fetching notifications:", error);
                });
        });
    }, []);

    const handleViewClick = (notification) => {
        setSelectedNotification(notification);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedNotification(null);
    };

    return (
       <FeesTableComponent 
       isPending={isPending}
        handleCloseModal={handleCloseModal} 
       notifications={notifications}
        isModalOpen={isModalOpen}
        selectedNotification={selectedNotification}
        handleViewClick={handleViewClick}
        />    );
};

export default MyFees;
