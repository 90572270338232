import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Headingtitle from './Headingtitle';
import toast from 'react-hot-toast';
import MyStudents from '../Core/ParentDashboard/MyStudentList';
 
const AttendanceComponent = ({
    title,
    fetchAttendanceData,
    showStudentSelector,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);

    const year = parseInt(searchParams.get('year')) || new Date().getFullYear();
    const month = parseInt(searchParams.get('month')) || new Date().getMonth() + 1;
    const studentId = searchParams.get('studentId');

    const updateQueryParams = (updatedParams) => {
        setSearchParams((prev) => {
            const newParams = { ...Object.fromEntries(prev.entries()), ...updatedParams };
            return new URLSearchParams(newParams);
        });
    };

    const updateAttendance = async () => {
        try {
            if (showStudentSelector && !studentId) {
                return;
            }
    
            const response = showStudentSelector 
                ? await fetchAttendanceData(studentId, month, year) 
                : await fetchAttendanceData(month, year);
    
            if (response?.status === 200 || response?.status === 201) {
                const summary = response.data.attendanceSummary[0] || {};
                setTotalPresent(summary.totalPresent || 0);
                setTotalAbsent(summary.totalAbsent || 0);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Failed to fetch attendance data');
        }
    };
    

    const handleDateChange = (date) => {
        const newYear = date.getFullYear();
        const newMonth = date.getMonth() + 1;
        updateQueryParams({ year: newYear, month: newMonth });
    };

    const handlePrevMonth = () => {
        const prevDate = new Date(year, month - 2);
        handleDateChange(prevDate);
    };

    const handleNextMonth = () => {
        const nextDate = new Date(year, month);
        handleDateChange(nextDate);
    };

    const handleStudentSelect = (id) => {
        updateQueryParams({ studentId: id });
    };

    useEffect(() => {
        updateAttendance();
    }, [studentId, year, month]);

    return (
        <div className="font-poppins">
            <Headingtitle title={title} />
            {showStudentSelector && <MyStudents onStudentSelect={handleStudentSelect} />}
            <h3>{(showStudentSelector && !studentId) && 'Please select a student to view attendance.'}</h3>
            <main className="bg-white relative w-full rounded-md p-5 min-h-[600px]">
                <div className="p-5 rounded-lg w-[100%] h-[500px]">
                    <div className="flex justify-between items-center w-[100%] p-5 mx-auto">
                        {/* Calendar Section */}
                        <div className="w-[60%] h-[420px] mx-auto flex justify-center items-center">
                            <div className="flex flex-col w-[60%] h-[60%] justify-center items-center">
                                <DatePicker
                                    selected={new Date(year, month - 1)}
                                    onChange={handleDateChange}
                                    inline
                                    className="text-center custom-datepicker"
                                    showPopperArrow={false}
                                />
                            </div>
                        </div>
                        {/* Navigation and Summary Section */}
                        <div className="w-[40%] h-[300px] flex flex-col space-y-32 py-5 items-center">
                            <div className="flex items-center w-[355px] space-x-2 border-[1px] border-border-100 rounded-lg px-3 py-2">
                                <button
                                    onClick={handlePrevMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowLeft />
                                </button>
                                <div className="text-[0.88rem] font-semibold w-[280px] text-center">
                                    {new Date(year, month - 1).toLocaleString('default', {
                                        month: 'long',
                                    })}{' '}
                                    {year}
                                </div>
                                <button
                                    onClick={handleNextMonth}
                                    className="bg-text_blue-500 text-white p-2 rounded-md"
                                >
                                    <FaArrowRight />
                                </button>
                            </div>
                            <div className="flex space-x-10">
                                <div className="bg-green-500 flex flex-col items-center text-white p-5 w-[120px] aspect-square rounded-md">
                                    <div>Total Present</div>
                                    <div className="text-[0.77rem] font-semibold bg-white p-3 w-fit text-black rounded-full">
                                        {totalPresent}
                                    </div>
                                </div>
                                <div className="bg-red-500 flex flex-col items-center text-white p-5 w-[120px] aspect-square rounded-md">
                                    <div>Total Absent</div>
                                    <div className="text-[0.77rem] font-semibold bg-white p-3 w-fit text-black rounded-full">
                                        {totalAbsent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AttendanceComponent;
